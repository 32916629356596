export function TravelSvgWithBackground(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 886 491"
      preserveAspectRatio="xMidYMid slice"
      {...props}
    >
      <title>{'navigator'}</title>
      <circle cx={232.396} cy={103.246} r={50.763} fill="#ff6584" />
      <path
        fill="#3f3d56"
        d="M391.582 259.678H142.556a5.08 5.08 0 0 1-.931-.073L259.403 55.596a8.246 8.246 0 0 1 14.355 0l79.044 136.906 3.787 6.55Z"
      />
      <path
        d="M391.582 259.678h-85.024l41.738-60.626 3.004-4.367 1.502-2.183 3.787 6.55 34.993 60.626z"
        opacity={0.2}
      />
      <path
        fill="#3f3d56"
        d="M530.238 259.678H314.851l41.738-60.626 3.003-4.367 54.388-79.007c3.566-5.178 12.144-5.5 16.336-.976a9.83 9.83 0 0 1 .783.976Z"
      />
      <circle cx={593.463} cy={210.82} r={15.296} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M594.938 256.894h-3.133l1.428-48.378 1.705 48.378z"
      />
      <path
        fill="#3f3d56"
        d="m593.509 217.685 3.364-4.654-3.41 5.806-.368-.645.414-.507zM593.141 222.384l-3.364-4.653 3.41 5.805.368-.645-.414-.507z"
      />
      <circle cx={733.78} cy={210.82} r={15.296} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M735.254 256.894h-3.133l1.429-48.378 1.704 48.378z"
      />
      <path
        fill="#3f3d56"
        d="m733.826 217.685 3.364-4.654-3.41 5.806-.369-.645.415-.507zM733.458 222.384l-3.364-4.653 3.41 5.805.368-.645-.414-.507z"
      />
      <circle cx={637.775} cy={188.846} r={22.592} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M639.952 256.894h-4.627l2.109-71.45 2.518 71.45z"
      />
      <path
        fill="#3f3d56"
        d="m637.843 198.985 4.967-6.872-5.035 8.574-.545-.953.613-.749zM637.298 205.926l-4.967-6.872 5.035 8.573.545-.952-.613-.749z"
      />
      <circle cx={692.868} cy={188.846} r={22.592} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M695.046 256.894h-4.627l2.109-71.45 2.518 71.45z"
      />
      <path
        fill="#3f3d56"
        d="m692.936 198.985 4.968-6.872-5.036 8.574-.544-.953.612-.749zM692.392 205.926l-4.968-6.872 5.036 8.573.544-.952-.612-.749z"
      />
      <circle cx={549.969} cy={188.846} r={22.592} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M552.146 256.894h-4.627l2.11-71.45 2.517 71.45z"
      />
      <path
        fill="#3f3d56"
        d="m550.037 198.985 4.967-6.872-5.035 8.574-.544-.953.612-.749zM549.493 205.926l-4.968-6.872 5.036 8.573.544-.952-.612-.749z"
      />
      <path
        fill="#e6e6e6"
        d="M817.25 491.631H79.984a70.734 70.734 0 1 1 0-141.468h735.633a27.205 27.205 0 0 0 0-54.41H55.5v-43.53h760.118a70.734 70.734 0 1 1 0 141.469H79.984a27.205 27.205 0 1 0 0 54.41H817.25Z"
      />
      <path
        fill="#FFE66D"
        d="M82.704 272.9h33.735v2.176H82.704zM178.467 272.9h33.735v2.176h-33.735zM274.23 272.9h33.735v2.176H274.23zM369.993 272.9h33.735v2.176h-33.735zM465.756 272.9h33.735v2.176h-33.735zM561.519 272.9h33.735v2.176h-33.735zM657.282 272.9h33.735v2.176h-33.735zM753.045 272.9h33.735v2.176h-33.735zM82.704 370.839h33.735v2.176H82.704zM178.467 370.839h33.735v2.176h-33.735zM274.23 370.839h33.735v2.176H274.23zM369.993 370.839h33.735v2.176h-33.735zM465.756 370.839h33.735v2.176h-33.735zM561.519 370.839h33.735v2.176h-33.735zM657.282 370.839h33.735v2.176h-33.735zM753.045 370.839h33.735v2.176h-33.735zM82.704 468.779h33.735v2.176H82.704zM178.467 468.779h33.735v2.176h-33.735zM274.23 468.779h33.735v2.176H274.23zM369.993 468.779h33.735v2.176h-33.735zM465.756 468.779h33.735v2.176h-33.735zM561.519 468.779h33.735v2.176h-33.735zM657.282 468.779h33.735v2.176h-33.735zM753.045 468.779h33.735v2.176h-33.735z"
      />
      <path
        fill="none"
        d="M575.186 94.695a28.992 28.992 0 0 0-6.045-5.703h12.624a21.114 21.114 0 0 0-6.58 5.703ZM533.919 88.992h2.176c-.467.328-.936.655-1.383 1.01-.255-.345-.527-.675-.793-1.01Z"
      />
      <path
        fill="#e6e6e6"
        d="M591.868 86.374a20.833 20.833 0 0 1 10.129 2.618h-20.232a20.742 20.742 0 0 1 10.103-2.618ZM552.607 83.757a28.623 28.623 0 0 1 16.534 5.235h-33.046a28.616 28.616 0 0 1 16.512-5.235ZM330.212 50.623A53.656 53.656 0 0 1 435.486 39.31c.65-.023 1.3-.049 1.955-.049a53.67 53.67 0 0 1 51.482 38.538 37.92 37.92 0 0 1 44.996 11.193H366.547a36.293 36.293 0 0 1-36.373-37.578q.016-.395.038-.791Z"
      />
      <path
        fill="none"
        d="M620.763 133.87a28.992 28.992 0 0 1 6.045-5.703h-12.624a21.114 21.114 0 0 1 6.579 5.704ZM662.03 128.167h-2.177c.468.329.937.656 1.384 1.01.254-.344.527-.674.793-1.01Z"
      />
      <path
        fill="#e6e6e6"
        d="M604.08 125.55a20.833 20.833 0 0 0-10.128 2.617h20.232a20.742 20.742 0 0 0-10.103-2.617ZM643.342 122.932a28.623 28.623 0 0 0-16.534 5.235h33.045a28.616 28.616 0 0 0-16.511-5.235ZM865.737 89.799a53.656 53.656 0 0 0-105.275-11.313c-.65-.023-1.299-.05-1.955-.05a53.67 53.67 0 0 0-51.482 38.539 37.92 37.92 0 0 0-44.995 11.192h167.372a36.293 36.293 0 0 0 36.373-37.577q-.016-.395-.038-.791Z"
      />
      <path
        fill="#3f3d56"
        d="m773.604 461.223-19.453-.01.002-3.088 19.453.01z"
      />
      <path
        fill="#6c63ff"
        d="m613.507 458.822 4.014.928 152.533.082 1.372-3.196a32.257 32.257 0 0 0 2.371-17.442c-.657-4.414-2.303-8.8-6.042-10.704l-6.769-45.084-84.389-.151-32.65 26.334s-15.4-.277-23.768 10.14a24.45 24.45 0 0 0-5.114 13.465l-.33 5.42Z"
      />
      <circle cx={647.009} cy={458.067} r={18.835} fill="#3f3d56" />
      <circle cx={647.009} cy={458.067} r={9.913} fill="#ccc" />
      <circle cx={739.95} cy={458.117} r={18.835} fill="#3f3d56" />
      <circle cx={739.95} cy={458.117} r={9.913} fill="#ccc" />
      <path
        fill="#FFE66D"
        d="m656.92 406.511 45.563.019h5.562l9.39.007v-.685l.007-8.258.007-9.272h-6.248l-5.562-.006-9.852-.007h-5.562l-11.374-.007-21.931 18.209zM724.849 406.544l24.08.013.006-4.725v-8.258l.007-5.232h-3.526l-5.562-.007-14.998-.006-.007 18.215z"
      />
      <path
        fill="#3f3d56"
        d="m717.426 425.993-3.088-.002.003-5.867 3.088.002zM669.266 411.764l-.002 3.088-5.867-.004.002-3.088z"
      />
      <path
        fill="#3f3d56"
        d="M667.104 413.12h-.34a6.453 6.453 0 0 1-6.45-6.456 6.453 6.453 0 0 1 6.457-6.45h.34l-.007 12.907ZM614.735 438.613a9.975 9.975 0 0 0 5.444-18.885 24.45 24.45 0 0 0-5.114 13.466Z"
      />
      <path
        fill="#f2f2f2"
        d="m705.639 388.316 11.796 17.536.007-8.258-6.241-9.272-5.562-.006zM739.854 388.335l9.081 13.497v-8.258l-3.519-5.232-5.562-.007zM690.225 388.309l12.258 18.221h5.562l-12.258-18.221h-5.562z"
      />
      <circle cx={50.058} cy={260.93} r={50.058} fill="#3f3d56" />
      <path
        fill="#6c63ff"
        d="m50.266 276-25.05-25.05a4.353 4.353 0 0 1 6.156-6.157L49.85 263.27l58.964-67.24a4.353 4.353 0 0 1 6.545 5.74Z"
      />
    </svg>
  );
}

export function TravelSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 886 491"
      {...props}
    >
      <title>{'navigator'}</title>
      <circle cx={232.396} cy={103.246} r={50.763} fill="#ff6584" />
      <path
        fill="#3f3d56"
        d="M391.582 259.678H142.556a5.08 5.08 0 0 1-.931-.073L259.403 55.596a8.246 8.246 0 0 1 14.355 0l79.044 136.906 3.787 6.55Z"
      />
      <path
        d="M391.582 259.678h-85.024l41.738-60.626 3.004-4.367 1.502-2.183 3.787 6.55 34.993 60.626z"
        opacity={0.2}
      />
      <path
        fill="#3f3d56"
        d="M530.238 259.678H314.851l41.738-60.626 3.003-4.367 54.388-79.007c3.566-5.178 12.144-5.5 16.336-.976a9.83 9.83 0 0 1 .783.976Z"
      />
      <circle cx={593.463} cy={210.82} r={15.296} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M594.938 256.894h-3.133l1.428-48.378 1.705 48.378z"
      />
      <path
        fill="#3f3d56"
        d="m593.509 217.685 3.364-4.654-3.41 5.806-.368-.645.414-.507zM593.141 222.384l-3.364-4.653 3.41 5.805.368-.645-.414-.507z"
      />
      <circle cx={733.78} cy={210.82} r={15.296} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M735.254 256.894h-3.133l1.429-48.378 1.704 48.378z"
      />
      <path
        fill="#3f3d56"
        d="m733.826 217.685 3.364-4.654-3.41 5.806-.369-.645.415-.507zM733.458 222.384l-3.364-4.653 3.41 5.805.368-.645-.414-.507z"
      />
      <circle cx={637.775} cy={188.846} r={22.592} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M639.952 256.894h-4.627l2.109-71.45 2.518 71.45z"
      />
      <path
        fill="#3f3d56"
        d="m637.843 198.985 4.967-6.872-5.035 8.574-.545-.953.613-.749zM637.298 205.926l-4.967-6.872 5.035 8.573.545-.952-.613-.749z"
      />
      <circle cx={692.868} cy={188.846} r={22.592} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M695.046 256.894h-4.627l2.109-71.45 2.518 71.45z"
      />
      <path
        fill="#3f3d56"
        d="m692.936 198.985 4.968-6.872-5.036 8.574-.544-.953.612-.749zM692.392 205.926l-4.968-6.872 5.036 8.573.544-.952-.612-.749z"
      />
      <circle cx={549.969} cy={188.846} r={22.592} fill="#6c63ff" />
      <path
        fill="#3f3d56"
        d="M552.146 256.894h-4.627l2.11-71.45 2.517 71.45z"
      />
      <path
        fill="#3f3d56"
        d="m550.037 198.985 4.967-6.872-5.035 8.574-.544-.953.612-.749zM549.493 205.926l-4.968-6.872 5.036 8.573.544-.952-.612-.749z"
      />
      <path
        fill="#e6e6e6"
        d="M817.25 491.631H79.984a70.734 70.734 0 1 1 0-141.468h735.633a27.205 27.205 0 0 0 0-54.41H55.5v-43.53h760.118a70.734 70.734 0 1 1 0 141.469H79.984a27.205 27.205 0 1 0 0 54.41H817.25Z"
      />
      <path
        fill="#FFE66D"
        d="M82.704 272.9h33.735v2.176H82.704zM178.467 272.9h33.735v2.176h-33.735zM274.23 272.9h33.735v2.176H274.23zM369.993 272.9h33.735v2.176h-33.735zM465.756 272.9h33.735v2.176h-33.735zM561.519 272.9h33.735v2.176h-33.735zM657.282 272.9h33.735v2.176h-33.735zM753.045 272.9h33.735v2.176h-33.735zM82.704 370.839h33.735v2.176H82.704zM178.467 370.839h33.735v2.176h-33.735zM274.23 370.839h33.735v2.176H274.23zM369.993 370.839h33.735v2.176h-33.735zM465.756 370.839h33.735v2.176h-33.735zM561.519 370.839h33.735v2.176h-33.735zM657.282 370.839h33.735v2.176h-33.735zM753.045 370.839h33.735v2.176h-33.735zM82.704 468.779h33.735v2.176H82.704zM178.467 468.779h33.735v2.176h-33.735zM274.23 468.779h33.735v2.176H274.23zM369.993 468.779h33.735v2.176h-33.735zM465.756 468.779h33.735v2.176h-33.735zM561.519 468.779h33.735v2.176h-33.735zM657.282 468.779h33.735v2.176h-33.735zM753.045 468.779h33.735v2.176h-33.735z"
      />
      <path
        fill="none"
        d="M575.186 94.695a28.992 28.992 0 0 0-6.045-5.703h12.624a21.114 21.114 0 0 0-6.58 5.703ZM533.919 88.992h2.176c-.467.328-.936.655-1.383 1.01-.255-.345-.527-.675-.793-1.01Z"
      />
      <path
        fill="#e6e6e6"
        d="M591.868 86.374a20.833 20.833 0 0 1 10.129 2.618h-20.232a20.742 20.742 0 0 1 10.103-2.618ZM552.607 83.757a28.623 28.623 0 0 1 16.534 5.235h-33.046a28.616 28.616 0 0 1 16.512-5.235ZM330.212 50.623A53.656 53.656 0 0 1 435.486 39.31c.65-.023 1.3-.049 1.955-.049a53.67 53.67 0 0 1 51.482 38.538 37.92 37.92 0 0 1 44.996 11.193H366.547a36.293 36.293 0 0 1-36.373-37.578q.016-.395.038-.791Z"
      />
      <path
        fill="none"
        d="M620.763 133.87a28.992 28.992 0 0 1 6.045-5.703h-12.624a21.114 21.114 0 0 1 6.579 5.704ZM662.03 128.167h-2.177c.468.329.937.656 1.384 1.01.254-.344.527-.674.793-1.01Z"
      />
      <path
        fill="#e6e6e6"
        d="M604.08 125.55a20.833 20.833 0 0 0-10.128 2.617h20.232a20.742 20.742 0 0 0-10.103-2.617ZM643.342 122.932a28.623 28.623 0 0 0-16.534 5.235h33.045a28.616 28.616 0 0 0-16.511-5.235ZM865.737 89.799a53.656 53.656 0 0 0-105.275-11.313c-.65-.023-1.299-.05-1.955-.05a53.67 53.67 0 0 0-51.482 38.539 37.92 37.92 0 0 0-44.995 11.192h167.372a36.293 36.293 0 0 0 36.373-37.577q-.016-.395-.038-.791Z"
      />
      <path
        fill="#3f3d56"
        d="m773.604 461.223-19.453-.01.002-3.088 19.453.01z"
      />
      <path
        fill="#6c63ff"
        d="m613.507 458.822 4.014.928 152.533.082 1.372-3.196a32.257 32.257 0 0 0 2.371-17.442c-.657-4.414-2.303-8.8-6.042-10.704l-6.769-45.084-84.389-.151-32.65 26.334s-15.4-.277-23.768 10.14a24.45 24.45 0 0 0-5.114 13.465l-.33 5.42Z"
      />
      <circle cx={647.009} cy={458.067} r={18.835} fill="#3f3d56" />
      <circle cx={647.009} cy={458.067} r={9.913} fill="#ccc" />
      <circle cx={739.95} cy={458.117} r={18.835} fill="#3f3d56" />
      <circle cx={739.95} cy={458.117} r={9.913} fill="#ccc" />
      <path
        fill="#FFE66D"
        d="m656.92 406.511 45.563.019h5.562l9.39.007v-.685l.007-8.258.007-9.272h-6.248l-5.562-.006-9.852-.007h-5.562l-11.374-.007-21.931 18.209zM724.849 406.544l24.08.013.006-4.725v-8.258l.007-5.232h-3.526l-5.562-.007-14.998-.006-.007 18.215z"
      />
      <path
        fill="#3f3d56"
        d="m717.426 425.993-3.088-.002.003-5.867 3.088.002zM669.266 411.764l-.002 3.088-5.867-.004.002-3.088z"
      />
      <path
        fill="#3f3d56"
        d="M667.104 413.12h-.34a6.453 6.453 0 0 1-6.45-6.456 6.453 6.453 0 0 1 6.457-6.45h.34l-.007 12.907ZM614.735 438.613a9.975 9.975 0 0 0 5.444-18.885 24.45 24.45 0 0 0-5.114 13.466Z"
      />
      <path
        fill="#f2f2f2"
        d="m705.639 388.316 11.796 17.536.007-8.258-6.241-9.272-5.562-.006zM739.854 388.335l9.081 13.497v-8.258l-3.519-5.232-5.562-.007zM690.225 388.309l12.258 18.221h5.562l-12.258-18.221h-5.562z"
      />
      <circle cx={50.058} cy={260.93} r={50.058} fill="#3f3d56" />
      <path
        fill="#6c63ff"
        d="m50.266 276-25.05-25.05a4.353 4.353 0 0 1 6.156-6.157L49.85 263.27l58.964-67.24a4.353 4.353 0 0 1 6.545 5.74Z"
      />
    </svg>
  );
}

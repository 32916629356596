import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import {
  setAddStepModalOpen,
  stepsSelector,
} from '../../../../store/StepSlice';
import { tripsSelector } from '../../../../store/TripSlice';
import { PrimaryButton } from '../../../../theme-components/Buttons';
import { VerticalFlex } from '../../../helpers/flex';
import UploadImage from '../../../Upload';
import Space from '../../../../theme-components/Spacing';

export const AddPhotosAndAdditionalInfoToStep: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  const { stepId } = useSelector(stepsSelector);
  const { trip } = useSelector(tripsSelector);

  return (
    <>
      <UploadImage tripId={trip ? trip.id : ''} stepId={stepId} main={true} />
      <Space size="md" />
      <PrimaryButton
        onClick={() => {
          gtag('event', 'finish-adding-images');
          dispatch(setAddStepModalOpen(false));
          handleCloseModal();
        }}
      >
        Done
      </PrimaryButton>
    </>
  );
};

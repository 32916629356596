import { Box, Divider } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  accommodationsSelector,
  setAccommodationDescriptionAdded,
  setAccommodationDescription,
} from '../../../../store/AccommodationSlice';
import {
  setAddStepModalOpen,
  setStepDayId,
  stepsSelector,
  setStepId,
  setStepDescriptionAdded,
  setStepDescription,
} from '../../../../store/StepSlice';
import Space from '../../../../theme-components/Spacing';
import TextDescription, {
  TitleH3,
} from '../../../../theme-components/Typography';
import { CenteredModal, ModalPaper } from '../../../styling/modal';

import { AddAccommodationInfo } from './AddAccommodationInfo';
import { AddPhotosToAccommodation } from './AddAccommodationPhotos';
import { AddStep } from './AddStep';
import { AddStepInfo } from './AddStepInfo';
import { AddPhotosAndAdditionalInfoToStep } from './AddStepPhotos';
import ModalHeader from '../../../../theme-components/ModalComponents';

export const AddStepToDay: React.FC<{
  dayId: string;
  tripType: string;
  category:
    | 'CATEGORY_ACCOMMODATION'
    | 'CATEGORY_TRANSPORT'
    | 'CATEGORY_PLACE'
    | 'CATEGORY_ACTIVITY'
    | 'CATEGORY_FOOD_DRINK';
  handleCloseModal: () => void;
}> = ({ dayId, tripType, category, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [isManual, setIsManual] = useState(false);

  const { stepId, stepDescriptionAdded, stepDayId } =
    useSelector(stepsSelector);

  const { accommodationId, accommodationDescriptionAdded } = useSelector(
    accommodationsSelector,
  );

  // Handle side effects when modal is opened or closed
  // useEffect(() => {
  //   if (!stepAddStepModalOpen) {
  //     dispatch(resetAccommodation());
  //     dispatch(resetStep());
  //   }
  // }, [stepAddStepModalOpen, dispatch]);

  // const handleOpenModal = () => {
  //   gtag('event', 'add-new-step-button');
  //   setIsManual(false);
  //   dispatch(setStepDayId(dayId));
  //   dispatch(setAddStepModalOpen(true));
  // };

  const closeModal = () => {
    gtag('event', 'close-add-new-step-button');
    setIsManual(false);
    dispatch(setAddStepModalOpen(false));
    dispatch(setStepDescriptionAdded(false));
    dispatch(setAccommodationDescriptionAdded(false));
    dispatch(setStepDescription(''));
    dispatch(setAccommodationDescription(''));
    dispatch(setStepDayId(''));
    dispatch(setStepId(''));
    handleCloseModal();
  };

  const handleManualSwitch = () => {
    gtag('event', 'add-manually');
    setIsManual(!isManual);
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={dayId === stepDayId}
      onClose={closeModal}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <ModalPaper>
        {stepId === '' && (
          <>
            {category === 'CATEGORY_ACCOMMODATION' && (
              <ModalHeader title={isManual ? '' : 'Add from Booking.com'} />
            )}
            {category !== 'CATEGORY_ACCOMMODATION' && (
              <ModalHeader title={isManual ? '' : 'Add from Google Maps'} />
            )}

            {category === 'CATEGORY_PLACE' && (
              <>
                <ModalHeader
                  title={isManual ? 'Add place' : ''}
                  description={
                    isManual ? (
                      'Enter the name of the place you are visiting'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link from{' '}
                        <strong>Google Maps</strong> and paste it below to add
                        this spot to your itinerary!
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_ACCOMMODATION' && (
              <>
                <ModalHeader
                  title={isManual ? 'Where are you staying?' : ''}
                  description={
                    isManual ? (
                      'Enter the name of your accommodation.'
                    ) : (
                      <>
                        Easily copy your hotel's link from{' '}
                        <strong>Booking.com</strong> or{' '}
                        <strong>Google Maps</strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_FOOD_DRINK' && (
              <>
                {/* {isManual && <TitleH3>Add Food or Drink</TitleH3>}
                <Space size="md" />
                <TextDescription size="md">
                  {isManual
                    ? 'Enter the name of the place you are visiting'
                    : 'Copy any place URL from Google Maps and paste below to add to your itinerary.'}
                </TextDescription> */}
                <ModalHeader
                  title={isManual ? 'Add Food or Drink' : ''}
                  description={
                    isManual ? (
                      'Enter the name of the place you are visiting'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link from{' '}
                        <strong>Google Maps</strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_TRANSPORT' && (
              <>
                {/* {isManual && <TitleH3>Add Transport</TitleH3>}
                <Space size="md" />
                <TextDescription size="md">
                  {isManual
                    ? 'Enter a title like "Drive 45 minutes"'
                    : 'Copy any place URL from Google Maps and paste below to add to your itinerary.'}
                </TextDescription> */}
                <ModalHeader
                  title={isManual ? 'Add Transport' : ''}
                  description={
                    isManual ? (
                      'Enter a title like "Drive 45 minutes"'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link{' '}
                        <strong>e.g. bus stop, train station</strong> from{' '}
                        <strong>Google Maps </strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_ACTIVITY' && (
              <>
                {/* {isManual && <TitleH3>Add Activity</TitleH3>}
                <Space size="md" />
                <TextDescription size="md">
                  {isManual
                    ? 'Enter the activity name'
                    : 'Copy the activity’s Google Maps URL and paste below to add to your itinerary.'}
                </TextDescription> */}
                <ModalHeader
                  title={isManual ? 'Add Activity' : ''}
                  description={
                    isManual ? (
                      'Enter the activity name'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link from{' '}
                        <strong>Google Maps</strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}

            {/* Input Field & Add Button */}
            {/* <Space size="sm" /> */}
            <Box width="100%" sx={{ position: 'relative' }}>
              <AddStep
                dayId={dayId}
                isManual={isManual}
                tripType={tripType}
                handleCloseModal={closeModal}
                stepType={category}
                handleManualSwitch={handleManualSwitch}
              />
            </Box>
          </>
        )}

        {stepId !== '' && (
          <Box>
            {category !== 'CATEGORY_ACCOMMODATION' &&
              category !== 'CATEGORY_TRANSPORT' && (
                <Box>
                  {!stepDescriptionAdded && (
                    <Box>
                      {/* <TitleH3>Add more details</TitleH3>
                      <Space size="md" />
                      <TextDescription size="md" sx={{ textAlign: 'center' }}>
                        Share a brief overview of what travelers can expect to
                        see or experience here
                      </TextDescription>
                      <Space size="sm" /> */}
                      <ModalHeader
                        title="Add more details"
                        description="Share a quick preview of what travelers can expect to see or experience here"
                      />
                      <AddStepInfo handleCloseModal={closeModal} />
                    </Box>
                  )}
                  {stepDescriptionAdded && (
                    <Box>
                      {/* <TitleH3>Add photos</TitleH3>
                      <Space size="sm" />
                      <Divider sx={{ width: '80%', mx: 'auto' }} />
                      <Space size="sm" />
                      <TextDescription size="md" sx={{ textAlign: 'center' }}>
                        Add photos to help visualize what this experience is all
                        about.
                      </TextDescription>
                      <Space size="sm" /> */}
                      <ModalHeader
                        title="Add photos"
                        description="Add photos to showcase what this is all about"
                      />
                      <AddPhotosAndAdditionalInfoToStep
                        handleCloseModal={closeModal}
                      />
                    </Box>
                  )}
                </Box>
              )}
            {category === 'CATEGORY_TRANSPORT' && (
              <Box>
                {!stepDescriptionAdded && (
                  <Box>
                    {/* <TitleH3>Add description</TitleH3>
                    <Space size="md" />
                    <TextDescription size="md">
                      Describe steps you need to take to get to destination.
                      This is useful if you need to use public transport or add
                      specific details about transport e.g. which taxi company
                      to use, what bus number to take ...
                    </TextDescription> */}
                    <ModalHeader
                      title="Add description"
                      description={
                        <>
                          Describe how to reach the destination, including any{' '}
                          <strong>
                            transport details like bus numbers, taxi companies,
                            or public transit directions
                          </strong>
                        </>
                      }
                    />
                    <AddStepInfo handleCloseModal={closeModal} />
                  </Box>
                )}
                {stepDescriptionAdded && (
                  <Box>
                    {/* <TitleH3>Add photos</TitleH3>
                    <Space size="sm" />
                    <Divider sx={{ width: '80%', mx: 'auto' }} />
                    <Space size="sm" />
                    <TextDescription size="md">
                      Add photo so it is easier to navigate.
                    </TextDescription> */}
                    <ModalHeader
                      title="Add photos"
                      description="Add photos to help with navigation"
                    />
                    {/* <Space size="sm" /> */}
                    <AddPhotosAndAdditionalInfoToStep
                      handleCloseModal={closeModal}
                    />
                  </Box>
                )}
              </Box>
            )}
            {accommodationId && category === 'CATEGORY_ACCOMMODATION' && (
              <Box>
                {!accommodationDescriptionAdded && (
                  <Box>
                    <ModalHeader
                      title="More info about your stay"
                      description={
                        <>
                          Add details about your stay, like{' '}
                          <strong>
                            booking reference, check-in and check-out times
                          </strong>
                          , or any <strong>key info</strong> you'll need on the
                          go
                        </>
                      }
                    />
                    <AddAccommodationInfo handleCloseModal={closeModal} />
                  </Box>
                )}

                {accommodationDescriptionAdded && (
                  <Box>
                    <ModalHeader
                      title="Add photos"
                      description="Add photo to this stay so it is easier to navigate"
                    />
                    <AddPhotosToAccommodation handleCloseModal={closeModal} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </ModalPaper>
    </CenteredModal>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Box } from '@mui/material';
import React, { useEffect } from 'react';

import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';
import { CenteredModal, ModalPaper } from '../styling/modal';

import { useAuth } from './firebase';
import SignUp from './SignUp';

interface SignUpModalProps {
  open: boolean;
  onClose: () => void; // Callback to close the modal
}

const SignUpModal: React.FC<SignUpModalProps> = ({ open, onClose }) => {
  const { user } = useAuth();

  // Close the modal if the user is authenticated
  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  return (
    <UnauthorizedApolloProvider>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <Box p={2} width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={0}
            >
              <Box></Box>
              <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Modal Content */}
            <Box padding={2} mt={-7}>
              <SignUp />
            </Box>
          </Box>
        </ModalPaper>
      </CenteredModal>
    </UnauthorizedApolloProvider>
  );
};

export default SignUpModal;

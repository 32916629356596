import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../generated/user_graphql';

export interface UserState {
  myUser: User | null;
}

const initialState: UserState = {
  myUser: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.myUser = payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const userSliceReducer = userSlice.reducer;

export const userSelector = (state: { user: UserState }) => state.user;

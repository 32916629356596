import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import { Box, Link, Typography, Stack, styled, Divider } from '@mui/material';
import React, { ReactNode } from 'react';

import { Day } from '../../generated/public_graphql';
import { TitleH3 } from '../../theme-components/Typography';
// eslint-disable-next-line import/no-named-as-default
import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';

import { PublicPlaceCard } from './PublicPlaceCard';
import { TransportStepDetails } from './TransportStepDetails';
import DayFullness from './DayFullnessIndicator';
import { AddAccomodationDirections } from './AddAccomodationDirections';
import { TripUser } from './TripItinerary';
import { openLinkInBrowserNewTab } from '../../utils/helpers';

export function TripDay(props: {
  day: Day;
  tripType: string;
  tripUser: TripUser;
  date?: string;
  selectedStepId?;
  stepRefs?;
}) {
  return (
    <>
      {/* // <Box
    //   width="100%"
    //   sx={theme => ({
    //     marginTop: 1,
    //     [theme.breakpoints.up('sm')]: {
    //       borderRadius: '20px',
    //       border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
    //       padding: 1,
    //       marginBottom: 1,
    //       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    //     },
    //   })}
    // > */}
      {(props.tripType || props.tripType !== 'guide') && (
        // <Box
        //   sx={theme => ({
        //     paddingLeft: 1.5,
        //     paddingRight: 1,
        //     paddingBottom: 1,
        //     [theme.breakpoints.down('sm')]: {
        //       borderRadius: '20px',
        //       border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
        //       padding: 1,
        //       paddingLeft: 1.5,
        //       paddingRight: 1.5,
        //       marginBottom: 1,
        //       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        //     },
        //   })}
        // >
        <Stack
          direction="column"
          width="100%"
          sx={{
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '20px',
            border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
            padding: 1,
            marginTop: 1.5,
            paddingLeft: 1.5,
            marginBottom: 1.5,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
          }}
        >
          <Stack
            direction="row"
            alignItems="left"
            sx={theme => ({
              width: '100%',
            })}
          >
            <Box
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                // marginRight: 1,
                [theme.breakpoints.down('sm')]: {
                  // marginRight: 0.5,
                },
              })}
            >
              {props.tripType && props.tripType === 'guide' ? (
                <>
                  <StarsTwoToneIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      // marginLeft: 1,
                      marginRight: 1,
                      // color: theme.palette.icon.main,
                      color: '#F39C6A',
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      },
                    })}
                  />

                  <TitleH3 color="#001B30">Guide</TitleH3>
                </>
              ) : (
                <>
                  <TodayTwoToneIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 1,
                      // marginLeft: 1,
                      // color: theme.palette.icon.main,
                      color: '#F39C6A',
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      },
                    })}
                  />

                  <TitleH3 color="#001B30">Day {props.day.dayNumber}</TitleH3>
                </>
              )}
            </Box>
          </Stack>
          {props.day && props.day.cities && props.day.cities.length > 0 && (
            <>
              <Stack
                direction="row"
                alignItems="left"
                // spacing={0.5}
                sx={theme => ({
                  marginTop: 0.5,
                  marginBottom: 1,
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    // paddingLeft: 1,
                    // marginLeft: 2,
                    [theme.breakpoints.down('sm')]: {
                      // paddingLeft: 1,
                      // marginLeft: 0.5,
                    },
                  })}
                >
                  <MapsHomeWorkTwoToneIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 1,
                      // marginLeft: 1,
                      // color: theme.palette.icon.main,
                      // color: '#FFF5EE',
                      color: '#F39C6A',
                      [theme.breakpoints.down('sm')]: {
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                </Box>
                <Typography
                  sx={theme => ({
                    alignSelf: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    fontSize: 18,
                    // color: '#4E4B66',
                    color: '#003366',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    },
                  })}
                >
                  {props.day.cities.map((city, i) => (
                    <React.Fragment key={i}>
                      {city && (
                        <>
                          {city.cityName}
                          {i !== props.day.cities!.length - 1 && <>{', '}</>}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </Typography>
              </Stack>
              {/* <Box
                sx={theme => ({
                  paddingRight: 1,
                  marginBottom: 1,
                  [theme.breakpoints.down('sm')]: {
                    // paddingRight: 0,
                    marginBottom: 0,
                  },
                })}
              > */}

              {/* </Box> */}
            </>
          )}
          <DayFullness
            dayId={props.day.id}
            numberOfStepsInDay={props.day.steps?.length ?? 0}
            tripType={props.tripType ? props.tripType : 'itinerary'}
          />
          {/* </Box> */}
        </Stack>
      )}
      <AddAccomodationDirections
        dayId={props.day.id}
        isFirstDirection={true}
        isLastDirection={false}
        tripUser={props.tripUser}
        tripType={props.tripType ? props.tripType : 'itinerary'}
      />
      <Box width="100%">
        {props.day &&
          props.day.steps &&
          props.day.steps.length > 0 &&
          props.day.steps.map((step, i) => {
            return (
              <Box
                key={step!.id}
                // ref={el => (props.stepRefs.current[step!.id] = el)} // Assign refs using step IDs
                ref={el => {
                  if (props && props.stepRefs && props.stepRefs.current) {
                    props.stepRefs.current[step!.id] = el; // Safely assign the ref
                  }
                }}
                className={`itinerary-step ${props.selectedStepId === step!.id ? 'selected' : ''}`}
              >
                {step && (
                  <PublicPlaceCard
                    step={step}
                    dayId={props.day.id}
                    selected={props.selectedStepId === step!.id ? true : false}
                    tripType={props.tripType}
                  />
                )}

                <>
                  {props.day.steps && props.day.steps[i + 1] && (
                    <UnauthorizedApolloProvider>
                      <TransportStepDetails
                        currentStep={step}
                        nextStep={props.day.steps[i + 1]}
                        tripType={props.tripType}
                        dayId={props.day.id}
                      />
                    </UnauthorizedApolloProvider>
                  )}
                </>
              </Box>
            );
          })}
      </Box>
      <AddAccomodationDirections
        dayId={props.day.id}
        isFirstDirection={false}
        isLastDirection={true}
        tripUser={props.tripUser}
        tripType={props.tripType ? props.tripType : 'itinerary'}
      />
    </>
    // </Box>
  );
}

export function StepDescription(props: { children: ReactNode }) {
  return <StepDescriptionBox>{props.children}</StepDescriptionBox>;
}

const StepDescriptionBox = styled(Box)(({ theme }) => ({
  paddingLeft: 0,
  width: '100%',
  fontWeight: 500,
  fontSize: 18,
  color: '#14142B',
  marginBottom: 15,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    marginBottom: 5,
    paddingLeft: 30,
  },
}));

export function ExternalLinkRegular(props: { url: string; copy: string }) {
  return (
    <Link
      onClick={() => {
        openLinkInBrowserNewTab(props.url);
        gtag('event', 'public-ext-link-maps-url');
      }}
      sx={theme => ({
        fontWeight: 500, // Make font weight less bold
        fontSize: 14, // Smaller default font size
        alignSelf: 'center',
        color: '#8A8D9C', // Slightly lighter color for less prominence
        textDecoration: 'none', // No underline by default
        '&:hover': {
          textDecoration: 'none', // Underline on hover for subtle emphasis
          color: '#6E7191', // Darker on hover for better visibility
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 12, // Even smaller on smaller screens
        },
      })}
    >
      {props.copy}
    </Link>
  );
}

import { useMutation } from '@apollo/client';
import { Box, Chip, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector, setTripName, setTrip } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import {
  ModalDescription,
  ModalTitle,
  ModalPaper,
  CenteredModal,
} from '../../styling/modal';
import { Maybe, Tag } from '../../../generated/user_graphql';
import { MUTATION_ADD_TAG, MUTATION_DELETE_TAG } from '../../gql-user/tags';
import { setDays } from '../../../store/DaySlice';
import ModalHeader from '../../../theme-components/ModalComponents';

export default function EditTagsModal(props: {
  tags: Maybe<Maybe<Tag>[]> | undefined;
  placeTags?: Maybe<Maybe<Tag>[]> | undefined;
  tripId?: string;
  stepId?: string;
  dayId?: string;
  placeId?: string;
  handleCloseModal: () => void;
}) {
  // const [open, setOpen] = React.useState(false);
  const [tagName, setTagName] = useState('');
  const dispatch = useDispatch();

  const [addTag, { loading }] = useMutation(MUTATION_ADD_TAG, {
    variables: {
      name: tagName,
      trip_id: props.tripId,
      day_id: props.dayId,
      step_id: props.stepId,
      place_id: props.placeId,
    },
  });

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    props.handleCloseModal();
    // setOpen(false);
  };

  return (
    <>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={true}
        onClose={() => handleClose()}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader title="Edit trip tags" />
          <Tags
            tags={props.tags}
            placeTags={props.placeTags}
            tripId={props.tripId}
            dayId={props.dayId}
            stepId={props.stepId}
            placeId={props.placeId}
          />
          <TextField
            id="standard-basic"
            label="Trip name"
            name="name"
            value={tagName}
            fullWidth={true}
            variant="outlined"
            onChange={e => setTagName(e.target.value.replace(/ /g, '_'))} // Replace spaces with underscores
            sx={{ marginTop: 1 }}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              mt={2}
            >
              <Box>
                <SecondaryButton
                  disabled={loading}
                  onClick={async () => {
                    gtag('event', 'publish-trip-close');
                    handleClose();
                  }}
                >
                  Close
                </SecondaryButton>
                <PrimaryButton
                  disabled={loading}
                  onClick={async () => {
                    gtag('event', 'add-tag');
                    try {
                      const response = await addTag();

                      if (
                        response &&
                        response.data &&
                        response.data.upsertTag
                      ) {
                        dispatch(setTrip(response.data.upsertTag));
                        if (
                          response.data.upsertTag.itinerary &&
                          response.data.upsertTag.itinerary.days &&
                          response.data.upsertTag.itinerary.days.length > 0
                        ) {
                          dispatch(
                            setDays(response.data.upsertTag.itinerary.days),
                          );
                        }
                      }

                      // handleClose();
                    } catch (e) {
                      gtag('event', 'error-edit-trip-name');
                      dispatch(setErrorTitle('Error saving trip name'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                >
                  {loading ? <Loader /> : 'Save tag'}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </ModalPaper>
      </CenteredModal>
    </>
  );
}

function Tags(props: {
  tags: Maybe<Maybe<Tag>[]> | undefined;
  placeTags?: Maybe<Maybe<Tag>[]> | undefined;
  tripId?: string;
  stepId?: string;
  dayId?: string;
  placeId?: string;
}) {
  const dispatch = useDispatch();
  const [deleteTag, { loading: deleteLoading }] =
    useMutation(MUTATION_DELETE_TAG);

  return (
    <>
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {props.tags &&
          props.tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag!.name}
              onDelete={async () => {
                try {
                  const response = await deleteTag({
                    variables: {
                      id: tag?.id, // Use the ID of the specific tag here
                      trip_id: props.tripId,
                      day_id: props.dayId,
                      step_id: props.stepId,
                    },
                  });

                  if (response && response.data && response.data.deleteTag) {
                    dispatch(setTrip(response.data.deleteTag));
                    if (
                      response.data.deleteTag.itinerary &&
                      response.data.deleteTag.itinerary.days &&
                      response.data.deleteTag.itinerary.days.length > 0
                    ) {
                      dispatch(setDays(response.data.deleteTag.itinerary.days));
                    }
                  }

                  // handleClose();
                } catch (e) {
                  gtag('event', 'error-edit-trip-name');
                  dispatch(setErrorTitle('Error saving trip name'));
                  dispatch(setErrorModalOpen(true));
                }
              }}
              color="primary"
              variant="outlined"
              sx={{ margin: '4px' }} // Adds spacing between chips
            />
          ))}
      </Stack>
      {props.placeTags && props.placeTags.length > 0 && (
        <>
          Place tags
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {props.placeTags &&
              props.placeTags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag!.name}
                  onDelete={async () => {
                    try {
                      const response = await deleteTag({
                        variables: {
                          id: tag?.id, // Use the ID of the specific tag here
                          trip_id: props.tripId,
                          day_id: props.dayId,
                          step_id: props.stepId,
                        },
                      });

                      if (
                        response &&
                        response.data &&
                        response.data.deleteTag
                      ) {
                        dispatch(setTrip(response.data.deleteTag));
                        if (
                          response.data.deleteTag.itinerary &&
                          response.data.deleteTag.itinerary.days &&
                          response.data.deleteTag.itinerary.days.length > 0
                        ) {
                          dispatch(
                            setDays(response.data.deleteTag.itinerary.days),
                          );
                        }
                      }

                      // handleClose();
                    } catch (e) {
                      gtag('event', 'error-edit-trip-name');
                      dispatch(setErrorTitle('Error saving trip name'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                  color="primary"
                  variant="outlined"
                  sx={{ margin: '4px' }} // Adds spacing between chips
                />
              ))}
          </Stack>
        </>
      )}
    </>
  );
}

import * as React from 'react';

const DownloadTheApp = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width={437.37}
    // height={777.687}
    width="1em"
    height="1em"
    viewBox="0 0 437.37 777.687"
    data-name="Layer 1"
    {...props}
  >
    <path
      fill="#3f3d56"
      d="M339.69 729.778H107.604a64.402 64.402 0 0 1-64.402-64.402V64.402A64.475 64.475 0 0 1 107.603 0H339.69a64.475 64.475 0 0 1 64.402 64.402v108.545h3.999v79.976h-4v412.453a64.402 64.402 0 0 1-64.4 64.402ZM107.604 2a62.472 62.472 0 0 0-62.402 62.402v600.974a62.401 62.401 0 0 0 62.402 62.402H339.69a62.402 62.402 0 0 0 62.402-62.402V250.923h3.999v-75.976h-4V64.402A62.472 62.472 0 0 0 339.69 2Z"
    />
    <path
      fill="#ccc"
      d="M342.247 713.284h-233.2a48.406 48.406 0 0 1-48.35-48.35V64.845a48.406 48.406 0 0 1 48.35-48.35h29.777l-.56 1.377a21.507 21.507 0 0 0 19.904 29.613h132.959a21.507 21.507 0 0 0 19.904-29.613l-.56-1.377h31.776a48.406 48.406 0 0 1 48.35 48.35v600.09a48.405 48.405 0 0 1-48.35 48.35Zm-233.2-694.79a46.404 46.404 0 0 0-46.35 46.35v600.09a46.404 46.404 0 0 0 46.35 46.35h233.2a46.403 46.403 0 0 0 46.35-46.35V64.845a46.404 46.404 0 0 0-46.35-46.35h-28.852a23.508 23.508 0 0 1-22.267 30.99H158.167a23.508 23.508 0 0 1-22.268-30.99Z"
    />
    <path
      fill="#fff"
      d="M416.247 390.766H21.123A21.147 21.147 0 0 1 0 369.643V242.905a21.147 21.147 0 0 1 21.123-21.123h395.124a21.147 21.147 0 0 1 21.123 21.123v126.738a21.147 21.147 0 0 1-21.123 21.123Z"
    />
    <path
      fill="#3f3d56"
      d="M416.247 390.766H21.123A21.147 21.147 0 0 1 0 369.643V242.905a21.147 21.147 0 0 1 21.123-21.123h395.124a21.147 21.147 0 0 1 21.123 21.123v126.738a21.147 21.147 0 0 1-21.123 21.123ZM21.123 224.267a18.66 18.66 0 0 0-18.638 18.638v126.738a18.66 18.66 0 0 0 18.638 18.638h395.124a18.66 18.66 0 0 0 18.638-18.638V242.905a18.66 18.66 0 0 0-18.638-18.638Z"
    />
    <path
      fill="#6c63ff"
      d="M270.5 299.44H132.579a9.94 9.94 0 0 1 0-19.88h137.92a9.94 9.94 0 0 1 0 19.88ZM398.48 332.989H132.58a9.94 9.94 0 0 1 0-19.88H398.48a9.94 9.94 0 0 1 0 19.88Z"
    />
    <circle cx={66.226} cy={306.274} r={37.276} fill="#6c63ff" />
    <path
      fill="#fff"
      d="M398.465 373.37h-34.79a9.94 9.94 0 1 1 0-19.88h34.79a9.94 9.94 0 1 1 0 19.88Z"
    />
    <path
      fill="#ccc"
      d="M398.465 373.37h-34.79a9.94 9.94 0 1 1 0-19.88h34.79a9.94 9.94 0 1 1 0 19.88ZM338.616 189.289H118.754A11.767 11.767 0 0 1 107 177.535v-70.522a11.767 11.767 0 0 1 11.754-11.753h219.862a11.767 11.767 0 0 1 11.754 11.753v70.522a11.767 11.767 0 0 1-11.754 11.754ZM118.754 96.642a10.383 10.383 0 0 0-10.371 10.371v70.522a10.383 10.383 0 0 0 10.37 10.371h219.863a10.383 10.383 0 0 0 10.371-10.37v-70.523a10.383 10.383 0 0 0-10.37-10.37Z"
    />
    <path
      fill="#e6e6e6"
      d="M257.517 138.472h-76.745a5.531 5.531 0 0 1 0-11.063h76.745a5.531 5.531 0 0 1 0 11.063ZM328.73 157.14H180.772a5.531 5.531 0 0 1 0-11.063H328.73a5.531 5.531 0 1 1 0 11.062Z"
    />
    <circle cx={143.851} cy={142.274} r={20.742} fill="#e6e6e6" />
    <path
      fill="#fff"
      d="M328.722 179.61h-19.359a5.531 5.531 0 1 1 0-11.063h19.359a5.531 5.531 0 0 1 0 11.063Z"
    />
    <path
      fill="#ccc"
      d="M328.722 179.61h-19.359a5.531 5.531 0 1 1 0-11.063h19.359a5.531 5.531 0 0 1 0 11.063ZM338.616 515.289H118.754A11.767 11.767 0 0 1 107 503.535v-70.522a11.767 11.767 0 0 1 11.754-11.753h219.862a11.767 11.767 0 0 1 11.754 11.753v70.522a11.767 11.767 0 0 1-11.754 11.754Zm-219.862-92.647a10.383 10.383 0 0 0-10.371 10.371v70.522a10.383 10.383 0 0 0 10.37 10.371h219.863a10.383 10.383 0 0 0 10.371-10.37v-70.523a10.383 10.383 0 0 0-10.37-10.37Z"
    />
    <path
      fill="#e6e6e6"
      d="M257.517 464.472h-76.745a5.531 5.531 0 0 1 0-11.063h76.745a5.531 5.531 0 0 1 0 11.063ZM328.73 483.14H180.772a5.531 5.531 0 0 1 0-11.063H328.73a5.531 5.531 0 1 1 0 11.062Z"
    />
    <circle cx={143.851} cy={468.274} r={20.742} fill="#e6e6e6" />
    <path
      fill="#fff"
      d="M328.722 505.61h-19.359a5.531 5.531 0 1 1 0-11.063h19.359a5.531 5.531 0 0 1 0 11.063Z"
    />
    <path
      fill="#ccc"
      d="M328.722 505.61h-19.359a5.531 5.531 0 1 1 0-11.063h19.359a5.531 5.531 0 0 1 0 11.063ZM338.616 646.289H118.754A11.767 11.767 0 0 1 107 634.535v-70.522a11.767 11.767 0 0 1 11.754-11.753h219.862a11.767 11.767 0 0 1 11.754 11.753v70.522a11.767 11.767 0 0 1-11.754 11.754Zm-219.862-92.647a10.383 10.383 0 0 0-10.371 10.371v70.522a10.383 10.383 0 0 0 10.37 10.371h219.863a10.383 10.383 0 0 0 10.371-10.37v-70.523a10.383 10.383 0 0 0-10.37-10.37Z"
    />
    <path
      fill="#e6e6e6"
      d="M257.517 595.472h-76.745a5.531 5.531 0 0 1 0-11.063h76.745a5.531 5.531 0 0 1 0 11.063ZM328.73 614.14H180.772a5.531 5.531 0 0 1 0-11.063H328.73a5.531 5.531 0 1 1 0 11.062Z"
    />
    <circle cx={143.851} cy={599.274} r={20.742} fill="#e6e6e6" />
    <path
      fill="#fff"
      d="M328.722 636.61h-19.359a5.531 5.531 0 1 1 0-11.063h19.359a5.531 5.531 0 0 1 0 11.063Z"
    />
    <path
      fill="#ccc"
      d="M328.722 636.61h-19.359a5.531 5.531 0 1 1 0-11.063h19.359a5.531 5.531 0 0 1 0 11.063Z"
    />
    <path
      fill="#ffb8b8"
      d="M201.657 765.803h12.26l5.832-47.288-18.094.001.002 47.287z"
    />
    <path
      fill="#2f2e41"
      d="M198.53 761.8H222.674a15.386 15.386 0 0 1 15.387 15.386v.5l-39.53.001Z"
    />
    <path
      fill="#ffb8b8"
      d="M152.657 765.803h12.26l5.832-47.288-18.094.001.002 47.287z"
    />
    <path
      fill="#2f2e41"
      d="M149.53 761.8H173.674a15.386 15.386 0 0 1 15.387 15.386v.5l-39.53.001Z"
    />
    <circle cx={204.104} cy={413.861} r={24.561} fill="#ffb8b8" />
    <path
      fill="#2f2e41"
      d="M220.62 755.597h-22.75a4.5 4.5 0 0 1-4.5-4.555l1.322-108.03a1.5 1.5 0 0 0-2.989-.201l-13.24 108.83a4.504 4.504 0 0 1-4.468 3.956h-22.969a4.5 4.5 0 0 1-4.486-4.85l15.167-194.076.413-.045 72.628-7.843-9.633 202.528a4.495 4.495 0 0 1-4.495 4.286Z"
    />
    <path
      fill="#6c63ff"
      d="M162.591 568.585a4.496 4.496 0 0 1-4.484-4.358c-.396-12.271-1.076-54.732 6.822-85.905a40.924 40.924 0 0 1 37.793-30.84 41.18 41.18 0 0 1 40.559 26.024c13.275 33.703 25.818 75.246 10.18 81.762-20.494 8.54-74.523 12.346-90.594 13.309q-.138.008-.276.008Z"
    />
    <path
      fill="#ffb8b8"
      d="M256.463 389.037a10.525 10.525 0 0 0 1.246 1.093l-2.579 49.527-10.724 5.49 6.17 17.268 19.93-7.875a8 8 0 0 0 5.026-8.18l-5.22-56.18a10.497 10.497 0 1 0-13.85-1.143Z"
    />
    <path
      fill="#6c63ff"
      d="m255.334 441.5 7.544 21.439a4.5 4.5 0 0 1-3.39 5.912l-22.245 4.307a12.497 12.497 0 0 1-8.227-23.601l19.974-10.543a4.5 4.5 0 0 1 6.344 2.486Z"
    />
    <path
      fill="#ffb8b8"
      d="M150.076 389.037a10.526 10.526 0 0 1-1.246 1.093l2.578 49.527 10.724 5.49-6.17 17.268-19.93-7.875a8 8 0 0 1-5.026-8.18l5.221-56.18a10.497 10.497 0 1 1 13.849-1.143Z"
    />
    <path
      fill="#6c63ff"
      d="M153.808 438.804a4.495 4.495 0 0 1 3.741.21l19.974 10.543a12.497 12.497 0 0 1-8.228 23.601l-22.245-4.307a4.5 4.5 0 0 1-3.39-5.912l7.545-21.439a4.495 4.495 0 0 1 2.603-2.696Z"
    />
    <path
      fill="#2f2e41"
      d="M213.643 418.048a16.143 16.143 0 0 1 9.04-15.168c7.467-3.854 12.157-12.075 10.102-19.582-2.631-9.609-15.71-15.84-26.306-12.535a48.565 48.565 0 0 0-8.26 3.84l-6.924 3.71a71.727 71.727 0 0 0-8.534 5.038 28.315 28.315 0 0 0-11.27 26.53c1.364 9.77 7.76 18.728 16.518 24.861a27.937 27.937 0 0 0 11.319 5.032c4.197.692 8.87-.095 11.939-2.705 4.796-4.08 4.3-10.911 2.75-16.617a12.09 12.09 0 0 1-.374-2.404Z"
    />
  </svg>
);
export default DownloadTheApp;

import { gql } from 'graphql-tag';

export const QUERY_GET_COUNTRIES_WITH_PUBLISHED_TRIPS = gql`
  query countriesWithPublishedTripsList {
    countriesWithPublishedTripsList {
      id
      name
    }
  }
`;

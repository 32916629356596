import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useLazyQuery,
} from '@apollo/client';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FullScreenBusAnimation } from '../../components/animations/BusAnimation';
import { QUERY_GET_TRIP } from '../../components/gql-public/tripQuery';
import { RouteContent, RoutePage, RouteTitle } from '../../components/route';
import { TripDetails } from '../../components/trip-details/TripDetails';
import { TripHeaderImages } from '../../components/trip-details/TripHeader';
import { TripItinerary } from '../../components/trip-details/TripItinerary';
import { TripMap } from '../../components/trip-details/TripMap';
import { User } from '../../generated/user_graphql';
import { setPublicTrip } from '../../store/PublicTripSlice';
import { setUserTrips } from '../../store/TripSlice';
import { setUser } from '../../store/UserSlice';
import { useAuth } from '../auth/firebase';
import { QUERY_GET_USER } from '../gql-user/user';
import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../gql-user/userTripsQuery';

import { MapDrawer } from './MapDrawer';

export default function PublicTrip() {
  gtag('event', 'public-trip-page');
  const { idToken, isAuthenticated } = useAuth();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [selectedStepId, setSelectedStepId] = useState(null);
  const stepRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollPositionRef = useRef<number>(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Get user trips so they can add easily to them
  const [client, setClient] = useState<ApolloClient<any>>(
    new ApolloClient({
      link: new HttpLink({
        uri: process.env.REACT_APP_CONFIG_URL_LOCKED,
      }),
      cache: new InMemoryCache(),
    }),
  );

  const [getUserTrips, { data: userTripsData, loading: loadingUserTrips }] =
    useLazyQuery(QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY, {
      client,
    });

  const [getUser, { data: userData, loading: loadingUser }] = useLazyQuery<{
    getUser: User;
  }>(QUERY_GET_USER, {
    client,
  });

  useEffect(() => {
    if (isAuthenticated && idToken) {
      const httpLink = new HttpLink({
        uri: process.env.REACT_APP_CONFIG_URL_LOCKED,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      const newClient = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
      });

      setClient(newClient);
    }
  }, [isAuthenticated, idToken]);

  useEffect(() => {
    if (isAuthenticated && idToken) {
      const fetchUserTrips = async () => {
        await getUserTrips();
        await getUser();
      };

      fetchUserTrips();
    }
  }, [isAuthenticated, idToken]);

  useEffect(() => {
    if (
      userTripsData &&
      userTripsData.userTripsWithDaysOnly &&
      userTripsData.userTripsWithDaysOnly.length > 0
    ) {
      dispatch(setUserTrips(userTripsData.userTripsWithDaysOnly));
    }
    if (userData && userData.getUser) {
      dispatch(setUser(userData.getUser));
    }
  }, [userTripsData, dispatch, userData]); // dependencies for useEffect

  // Get trip data
  const [getTrip, { data, error, loading }] = useLazyQuery(QUERY_GET_TRIP, {
    variables: { trip_id: id },
  });

  useEffect(() => {
    if (id) {
      getTrip();
    }
  }, [id]);

  useEffect(() => {
    if (data && data.trip) {
      dispatch(setPublicTrip(data.trip));
    }
  }, [data, dispatch]); // dependencies for useEffect

  const scrollToStep = (stepId: string) => {
    // Check if the reference exists for this stepId
    if (stepId) {
      const stepRef = stepRefs.current[stepId];
      if (stepRef) {
        // Check if stepRef is not null
        stepRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  useEffect(() => {
    // Restore the scroll position if it's stored
    if (scrollPositionRef.current && selectedStepId === null) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [selectedStepId]);

  const handleMarkerClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCloseClick = () => {
    scrollPositionRef.current = window.scrollY;
    setSelectedStepId(null);
  };

  if (loading || loadingUserTrips || loadingUser) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if ((!id || error || !data) && !loading) {
    return (
      <>
        <RoutePage>
          <RouteTitle>Trip</RouteTitle>
          <RouteContent>
            Trip not found, contact support help@rooutie.com
          </RouteContent>
        </RoutePage>
      </>
    );
  }

  return (
    <>
      <MapDrawer
        tripType="public"
        onMarkerClick={handleMarkerClick}
        onCloseClick={handleCloseClick}
      />
      <TripHeaderImages tripType="public" />
      <Stack
        direction="row"
        alignItems="left"
        sx={{
          width: '100%',
        }}
      >
        {!isSmallScreen && (
          <Box
            sx={theme => ({
              display: 'block',
              width: '50%',
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
          >
            <TripMap
              tripType="public"
              onMarkerClick={handleMarkerClick}
              onCloseClick={handleCloseClick}
            />
          </Box>
        )}
        <Box
          sx={theme => ({
            flex: '1 0 0',
            marginLeft: 0,
            width: '50%',
            [theme.breakpoints.down('sm')]: {
              marginLeft: 0,
              width: '100%',
            },
          })}
        >
          <Stack
            direction="column"
            alignItems="top"
            sx={theme => ({
              width: '100%',
              marginLeft: 1,
              [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
              },
            })}
          >
            <TripDetails tripUser="public" />
            <TripItinerary
              tripUser="public"
              selectedStepId={selectedStepId}
              stepRefs={stepRefs}
            />
          </Stack>
        </Box>
      </Stack>
    </>
  );
}

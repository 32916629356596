import { useAuth } from './firebase';
import SignUp from './SignUp';

function LoginButton() {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? <SignUp /> : null;
}

export default LoginButton;

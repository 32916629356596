import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector, setTripDescription } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import MyEditor from '../../MyEditor';
import {
  CenteredModal,
  ModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../styling/modal';

import 'react-quill/dist/quill.snow.css';
import Space from '../../../theme-components/Spacing';
import TextDescription, { TitleH3 } from '../../../theme-components/Typography';
import { Stack } from '@mui/system';
import ModalHeader from '../../../theme-components/ModalComponents';

export default function EditTripDescriptionModal(props: {
  description?: string;
  tripType?: string;
  editTripId: string;
  // tripActions: boolean;
  handleCloseModal?: () => void;
}) {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { tripDescription } = useSelector(tripsSelector);

  const [editTrip, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: { description: tripDescription, trip_id: props.editTripId },
  });

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    // setOpen(false);
    if (props.handleCloseModal !== undefined) {
      props.handleCloseModal();
    }
  };

  const handleEditorChange = (content: string) => {
    dispatch(setTripDescription(content));
  };

  return (
    <>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader
            title={props.description ? 'Edit description' : 'Add description'}
            description={
              props.tripType && props.tripType === 'guide' ? (
                <>
                  Describe the guide—whether it's stunning destinations, local
                  cultures, or hidden gems!
                </>
              ) : (
                <>
                  <>
                    Describe the adventure—whether it's{' '}
                    <strong>
                      stunning destinations, local cultures, or hidden gems
                    </strong>
                    , capture what makes this trip{' '}
                    <strong>unforgettable!</strong>
                  </>
                </>
              )
            }
          />

          <Space size="md" />
          <MyEditor value={tripDescription} onChange={handleEditorChange} />
          <Space size="md" />
          <PrimaryButton
            onClick={async () => {
              gtag('event', 'edit-trip-description-save');
              try {
                await editTrip();
                handleClose();
              } catch (e) {
                gtag('event', 'error-edit-trip-description-save');
                dispatch(setErrorTitle('Error saving description'));
                dispatch(setErrorModalOpen(true));
              }
            }}
          >
            {loading ? <Loader /> : 'Save description'}
          </PrimaryButton>
          <Space size="md" />
          <SecondaryButton
            disabled={loading}
            onClick={async () => {
              gtag('event', 'publish-trip-close');
              // setOpen(false);
              handleClose();
            }}
          >
            Close
          </SecondaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
}

import { gql } from 'graphql-tag';

export const QUERY_GET_COUNTRY = gql`
  query country($country_id: String!) {
    country(country_id: $country_id) {
      id
      name
      description
      url
      iso2Code
      iso3Code
      seasons {
        id
        name
        minTemperature
        maxTemperature
        startMonth
        endMonth
        description
        countryRegionName
      }
      recommendations {
        id
        name
        description
        type
        importance
        url
      }
      region {
        id
        name
      }
      subRegion {
        id
        name
      }
    }
  }
`;

import { gql } from 'graphql-tag';

export const QUERY_GET_USER_SIDEBAR_COUNTRIES = gql`
  query tripCountriesInfo($trip_id: String!) {
    userTrip(trip_id: $trip_id) {
      id
      published
      tripType
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
        seasons {
          id
          name
          minTemperature
          maxTemperature
          startMonth
          endMonth
          description
          countryRegionName
        }
        recommendations {
          id
          name
          description
          type
          importance
          url
        }
        region {
          id
          name
        }
        subRegion {
          id
          name
        }
      }
    }
  }
`;

import { useMutation } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  ProminentButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { FullScreenBusAnimation } from '../../animations/BusAnimation';
import { MUTATION_CREATE_USER_TRIP_WITH_DAY } from '../../gql-user/createUserTripMutation';
import { VerticalFlex } from '../../helpers/flex';
import { TravelSvgWithBackground } from '../../illustrations/Travel';
import {
  ModalDescription,
  ModalTitle,
  ModalPaper,
  CenteredModal,
} from '../../styling/modal';

import { CustomFormControl } from './Styles';
import ModalHeader from '../../../theme-components/ModalComponents';
import TextDescription from '../../../theme-components/Typography';
import { textAlign } from '@mui/system';
import { ShareInstructionModal } from './add-step/AddStep';
import Space from '../../../theme-components/Spacing';

export default function CreateTripModal(props: { type?: string }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  // const [countryId, setCountryId] = useState('');
  const [placeUrl, setPlaceUrl] = useState('');
  const [openInfo, setOpenInfo] = useState(false);
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
      'https://www.booking.com',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [addTrip, { loading, data }] = useMutation(
    MUTATION_CREATE_USER_TRIP_WITH_DAY,
    {
      variables: {
        name: name,
        place_url: placeUrl,
        trip_type: props.type && props.type,
      },
    },
  );

  // const [
  //   getCountries,
  //   { data: countries, loading: countriesLoading, error: countriesError },
  // ] = useLazyQuery(QUERY_GET_COUNTRIES);

  if (data && data.createUserTripWithDay.id) {
    navigate('/create-route/' + data.createUserTripWithDay.id);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ProminentButton
        onClick={() => {
          gtag('event', 'create-new-trip-button');
          handleOpen();
          // getCountries();
        }}
      >
        {props.type && props.type === 'guide' ? (
          <>{'Make new guide'}</>
        ) : (
          <>{'Make new trip'}</>
        )}
      </ProminentButton>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <>
          {/* {countriesLoading && <Loader />} */}
          {/* {open && countries && countries.countriesList && !countriesLoading ? ( */}
          <ModalPaper>
            <ModalHeader
              title={
                props.type && props.type === 'guide'
                  ? 'Create new guide'
                  : 'Create new trip'
              }
              description={
                props.type && props.type === 'guide' ? (
                  <>{'Pick the guide name to get started.'}</>
                ) : (
                  <>{'Pick the trip name to get started'}</>
                )
              }
            />
            <Stack direction="column" width="100%">
              <CustomFormControl>
                <TextField
                  id="standard-basic"
                  label={
                    props.type && props.type === 'guide'
                      ? 'Guide name'
                      : 'Trip name'
                  }
                  name="name"
                  fullWidth={true}
                  variant="outlined"
                  onChange={e => setName(e.target.value)}
                  sx={{ backgroundColor: '#fafafa' }}
                />
              </CustomFormControl>
              {/* <OrDivider /> */}
              <Space size="md" />
              <TextDescription size="md" sx={{ textAlign: 'left' }}>
                Optionally enter your <strong>Google Maps</strong> list of
                places link.
              </TextDescription>
              <Space size="sm" />
              <CustomFormControl>
                <TextField
                  label="Google Maps place shared URL"
                  fullWidth={true}
                  variant="outlined"
                  onChange={handleChange}
                  value={placeUrl}
                  error={!isValidUrl}
                  helperText={
                    !isValidUrl &&
                    'URL must start with https://maps.app.goo.gl/ or https://www.google.com/maps'
                  }
                  sx={{ backgroundColor: '#fafafa' }}
                />
              </CustomFormControl>
              <Space size="xs" />
              <Stack
                direction="row"
                spacing={0.5}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'left',
                  flexWrap: 'wrap',
                  display: 'inline',
                  width: '100%',
                }}
              >
                <Typography
                  onClick={handleOpenInfo}
                  sx={theme => ({
                    fontWeight: 500,
                    display: 'inline',
                    color: theme.palette.primary.main,
                  })}
                >
                  Need help?
                </Typography>
                <Typography sx={{ display: 'inline', fontWeight: 400 }}>
                  Learn how to add places form Google Maps
                </Typography>
              </Stack>
              <Space size="md" />
              <PrimaryButton
                disabled={loading || name === ''}
                onClick={async () => {
                  gtag('event', 'create-new-trip-save');
                  try {
                    await addTrip();
                    handleClose();
                  } catch (e) {
                    gtag('event', 'error-create-new-trip-save');
                    dispatch(setErrorTitle('Error adding location'));
                    dispatch(setErrorModalOpen(true));
                  }
                }}
              >
                {loading && <Loader />}
                {!loading && props.type && props.type === 'guide' ? (
                  <>{'Create new guide'}</>
                ) : (
                  <>{'Create new trip'}</>
                )}
              </PrimaryButton>
            </Stack>
          </ModalPaper>
        </>
      </CenteredModal>
      {loading && placeUrl !== '' && (
        <FullScreenBusAnimation copy="Generating your trip may take a while, depending on how many places are on your list. You don't need to wait - just come back later, and your trip will be ready for you." />
      )}
      <ShareInstructionModal
        open={openInfo}
        handleClose={handleCloseInfo}
        showBooking={false}
      />
    </Box>
  );
}

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { CountriesTrips } from '../components/country/CountryTrips';
import { QUERY_COUNTRIES_TRIPS } from '../components/gql-public/countriesTripsQuery';
import { HomeTrips } from '../components/home/HomeTrips';
import { RoutePage } from '../components/route';
import { isRunningStandalone } from '../utils/helpers';
import {
  GradientContainer,
  OuterContainer,
} from '../components/home/HomeHeader';

const gradients = [
  'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
  'linear-gradient(180deg, #F2DDF7 0%, #FFFFFF 100%)',
  'linear-gradient(180deg, #F9E7E1 0%, #FFFFFF 100%)',
  'linear-gradient(180deg, #D9F9FB 0%, #FFFFFF 100%)',
];

export default function ExploreRoute() {
  gtag('event', 'public-country-page');
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { loading, error, data } = useQuery(QUERY_COUNTRIES_TRIPS, {
    // fetchPolicy: 'network-only', // Ensures it always fetches fresh data
    // onError: err => {
    //   console.error('Query error:', err);
    // },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (error) {
    return <>Error loading trips</>;
  }

  return (
    <RoutePage>
      <OuterContainer>
        <HomeTrips />
        {data &&
          data.countriesTrips &&
          data.countriesTrips.map((countryTrip, index) => (
            <CountriesTrips
              key={index}
              countryTrips={countryTrip}
              gradient={gradients[index % gradients.length]}
            />
          ))}

        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))' }}
          />
        )}
      </OuterContainer>
    </RoutePage>
  );
}

import { useLazyQuery } from '@apollo/client';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, CardMedia, Stack } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../store/DaySlice';
import { setTrip } from '../store/TripSlice';

import { Loader } from './../theme-components/Loader';
import { useAuth } from './auth/firebase';
import { QUERY_USER_TRIP } from './gql-user/userTrip';

export default function UploadImage(props: {
  tripId: string;
  stepId?: string;
  accommodationId?: string;
  header?: boolean | null;
  main?: boolean | null;
  secondary?: boolean | null;
  tertiary?: boolean | null;
}) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { idToken } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;

  const handleChange = async (e: any) => {
    if (e.target.files.length) {
      const preview = URL.createObjectURL(e.target.files[0]);
      setPreview(preview);
      await handleUpload(e.target.files[0].type, preview);
    }
  };

  const [getUserTrip, { data }] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  useEffect(() => {
    if (data && data.userTrip) {
      dispatch(setTrip(data.userTrip));
    }
  }, [data, dispatch]);

  const formData = new FormData();

  const handleUpload = async (imageType: string, preview: string) => {
    setUploading(true);
    const blob = await fetch(preview).then(r => r.blob());

    const file = new File([blob], 'image', {
      type: imageType,
    });

    const imageFile = file;

    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      maxIteration: 8,
    };

    if (user != null) {
      try {
        const compressedFile = await imageCompression(imageFile, options);
        formData.append('image', compressedFile);

        if (!props.stepId && !props.accommodationId && props.tripId) {
          gtag('event', 'upload-in-add-flow-trip-image');
          formData.append('tripId', props.tripId);
        }
        if (props.stepId) {
          gtag('event', 'upload-in-add-flow-step-image');
          formData.append('stepId', props.stepId);
        }
        if (props.accommodationId) {
          gtag('event', 'upload-in-add-flow-accommodation-image');
          formData.append('accommodationId', props.accommodationId);
        }

        if (props.header) {
          gtag('event', 'upload-in-add-flow-header-image');
          formData.append('isHeader', 'true');
        } else if (props.main) {
          gtag('event', 'upload-in-add-flow-main-image');
          formData.append('isMain', 'true');
        } else if (props.secondary) {
          gtag('event', 'upload-in-add-flow-secondary-image');
          formData.append('isSecondary', 'true');
        } else if (props.tertiary) {
          gtag('event', 'upload-in-add-flow-tertiary-image');
          formData.append('isTertiary', 'true');
        }

        // const token = await getAccessTokenSilently();

        // const token = await getIdToken(user);
        await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
          credentials: 'same-origin',
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        // Get updated trip with pictures
        const { data } = await getUserTrip();
        if (data && data.userTrip) {
          dispatch(setTrip(data.userTrip));
          dispatch(setDays(data.userTrip.itinerary.days));
        }
        setUploading(false);
      } catch (error) {
        // TODO
        console.log(error);
      }
    }
  };

  return (
    <div>
      <label htmlFor="upload-button">
        <Box
          sx={theme => ({
            minWidth: 300,
            marginTop: 0,
            marginBottom: 1,
            marginLeft: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Center content horizontally
            justifyContent: 'center', // Center content vertically
            [theme.breakpoints.down(1100)]: {
              marginTop: 2,
              marginLeft: 0,
            },
          })}
        >
          {/* Placeholder with image */}
          <Box
            sx={theme => ({
              width: '100%',
              height: 320,
              position: 'relative',
              // borderRadius: 1,
              borderRadius: '10px',
              border: preview ? 0 : '1px dashed #ccc', // Border style
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f4f4f4', // Placeholder background
              [theme.breakpoints.down('sm')]: {
                height: 230,
              },
            })}
          >
            {preview ? (
              <CardMedia
                sx={{
                  borderRadius: '10px',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Ensure image covers the area
                }}
                image={preview}
                // alt="Uploaded Preview"
              />
            ) : (
              <Stack direction="column" alignItems="center" spacing={1}>
                <CloudUploadIcon sx={{ fontSize: 40, color: '#bbb' }} />
                {/* Icon for upload */}
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: '#888',
                    textAlign: 'center',
                    padding: '10px 20px',
                    borderRadius: 5,
                    backgroundColor: '#e8e8e8',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  }}
                >
                  Click here to choose image
                </Box>
              </Stack>
            )}
          </Box>

          {/* Change image button (visible after image upload) */}
          {preview && (
            <Box sx={{ marginTop: 1 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CloudUploadIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'primary.main',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Change image
                </Box>
              </Stack>
            </Box>
          )}
        </Box>

        {/* {preview ? (
          <Box>
            <Box
              sx={theme => ({
                minWidth: 300,
                marginTop: 0,
                marginBottom: 1,
                marginLeft: 1,
                display: 'flex',
                flexDirection: 'row',
                [theme.breakpoints.down(1100)]: {
                  marginTop: 2,
                  marginLeft: 0,
                },
              })}
            >
              <Box sx={{ marginRight: 0.1, height: '100%', width: '100%' }}>
                <CardMedia
                  sx={theme => ({
                    borderRadius: 0.5,
                    width: '100%',
                    height: '100%',
                    minHeight: 220,
                    // [theme.breakpoints.down(1100)]: {
                    //   minHeight: 230,
                    // },
                  })}
                  image={preview}
                />
              </Box>
            </Box>
            <Stack direction="row">
              <UploadIcon />
              <Box
                sx={theme => ({
                  alignSelf: 'center',
                  fontWeight: 500,
                  fontSize: 16,
                  color: theme.palette.primary.main,
                })}
              >
                Change image
              </Box>
            </Stack>
          </Box>
        ) : (
          <Box>
            <Stack direction="row">
              <UploadIcon />
              <Box
                sx={theme => ({
                  alignSelf: 'center',
                  fontWeight: 500,
                  fontSize: 16,
                  color: theme.palette.primary.main,
                })}
              >
                Click here to choose image
              </Box>
            </Stack>
          </Box>
        )} */}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      {uploading ? <Loader /> : null}
    </div>
  );
}

// export function ManageImages(props: {
//   tripId: string;
//   images: Maybe<Maybe<Image>[]> | undefined;
// }) {
//   // const classes = useStyles();
//   const dispatch = useDispatch();
//   const { idToken } = useAuth();
//   const [deleteImage] = useMutation(MUTATION_DELETE_USER_IMAGE);
//   // const { trip } = useSelector(tripsSelector);
//   const [getUserTrip, { data: userTripData }] = useLazyQuery(QUERY_USER_TRIP, {
//     fetchPolicy: 'no-cache',
//     variables: { trip_id: props.tripId },
//   });
//   const auth = getAuth();
//   const user = auth.currentUser;

//   const [mainImagePreview, setMainImagePreview] = useState('');
//   const [headerImagePreview, setHeaderImagePreview] = useState('');
//   const [secondaryImagePreview, setSecondaryImagePreview] = useState('');
//   const [tertiaryImagePreview, setTertiaryImagePreview] = useState('');

//   const [mainImageLoading, setMainImageLoading] = useState(false);
//   const [headerImageLoading, setHeaderImageLoading] = useState(false);
//   const [secondaryImageLoading, setSecondaryImageLoading] = useState(false);
//   const [tertiaryImageLoading, setTertiaryImageLoading] = useState(false);

//   const [mainImageDeleting, setMainImageDeleting] = useState(false);
//   const [headerImageDeleting, setHeaderImageDeleting] = useState(false);
//   const [secondaryImageDeleting, setSecondaryImageDeleting] = useState(false);
//   const [tertiaryImageDeleting, setTertiaryImageDeleting] = useState(false);

//   useEffect(() => {
//     if (userTripData && userTripData.userTrip) {
//       dispatch(setTrip(userTripData.userTrip));
//     }
//   }, [userTripData, dispatch]);

//   const formData = new FormData();

//   const addImage = async (
//     e: any,
//     main: boolean,
//     header: boolean,
//     secondary: boolean,
//     tertiary: boolean,
//   ) => {
//     if (e.target.files.length) {
//       gtag('event', 'upload-trip-image');
//       const preview = URL.createObjectURL(e.target.files[0]);
//       if (main) {
//         setMainImagePreview(preview);
//         setHeaderImagePreview('');
//         setSecondaryImagePreview('');
//         setTertiaryImagePreview('');
//         setMainImageLoading(true);
//         setHeaderImageLoading(false);
//         setSecondaryImageLoading(false);
//         setTertiaryImageLoading(false);
//       }
//       if (header) {
//         setMainImagePreview('');
//         setHeaderImagePreview(preview);
//         setSecondaryImagePreview('');
//         setTertiaryImagePreview('');
//         setMainImageLoading(false);
//         setHeaderImageLoading(true);
//         setSecondaryImageLoading(false);
//         setTertiaryImageLoading(false);
//       }
//       if (secondary) {
//         setMainImagePreview('');
//         setHeaderImagePreview('');
//         setSecondaryImagePreview(preview);
//         setTertiaryImagePreview('');
//         setMainImageLoading(false);
//         setHeaderImageLoading(false);
//         setSecondaryImageLoading(true);
//         setTertiaryImageLoading(false);
//       }
//       if (tertiary) {
//         setMainImagePreview('');
//         setHeaderImagePreview('');
//         setSecondaryImagePreview('');
//         setTertiaryImagePreview(preview);
//         setMainImageLoading(false);
//         setHeaderImageLoading(false);
//         setSecondaryImageLoading(false);
//         setTertiaryImageLoading(true);
//       }
//       await handleUpload(
//         e.target.files[0].type,
//         preview,
//         main,
//         header,
//         secondary,
//         tertiary,
//       );
//     }
//   };

//   const handleDelete = async (
//     imageId: string,
//     main: boolean,
//     header: boolean,
//     secondary: boolean,
//     tertiary: boolean,
//   ) => {
//     if (main) {
//       setMainImageDeleting(true);
//       setHeaderImageDeleting(false);
//       setSecondaryImageDeleting(false);
//       setTertiaryImageDeleting(false);
//     }
//     if (header) {
//       setMainImageDeleting(false);
//       setHeaderImageDeleting(true);
//       setSecondaryImageDeleting(false);
//       setTertiaryImageDeleting(false);
//     }
//     if (secondary) {
//       setMainImageDeleting(false);
//       setHeaderImageDeleting(false);
//       setSecondaryImageDeleting(true);
//       setTertiaryImageDeleting(false);
//     }
//     if (tertiary) {
//       setMainImageDeleting(false);
//       setHeaderImageDeleting(false);
//       setSecondaryImageDeleting(false);
//       setTertiaryImageDeleting(true);
//     }
//     if (imageId) {
//       await deleteImage({
//         variables: {
//           id: imageId,
//         },
//       });
//       await getUserTrip();
//       setMainImageDeleting(false);
//       setHeaderImageDeleting(false);
//       setSecondaryImageDeleting(false);
//       setTertiaryImageDeleting(false);
//     }
//   };

//   const handleUpload = async (
//     imageType: string,
//     preview: string,
//     main: boolean,
//     header: boolean,
//     secondary: boolean,
//     tertiary: boolean,
//   ) => {
//     const blob = await fetch(preview).then(r => r.blob());

//     const file = new File([blob], 'image', {
//       type: imageType,
//     });

//     const imageFile = file;
//     const options = {
//       maxSizeMB: 2,
//       maxWidthOrHeight: 1920,
//       useWebWorker: true,
//       maxIteration: 8,
//     };

//     if (user != null) {
//       try {
//         const compressedFile = await imageCompression(imageFile, options);

//         if (header) {
//           formData.append('isHeader', 'true');
//           formData.append('isSecondary', 'false');
//           formData.append('isTertiary', 'false');
//           formData.append('isMain', 'false');
//         }
//         if (main) {
//           formData.append('isHeader', 'false');
//           formData.append('isSecondary', 'false');
//           formData.append('isTertiary', 'false');
//           formData.append('isMain', 'true');
//         }
//         if (secondary) {
//           formData.append('isHeader', 'false');
//           formData.append('isSecondary', 'true');
//           formData.append('isTertiary', 'false');
//           formData.append('isMain', 'false');
//         }
//         if (tertiary) {
//           formData.append('isHeader', 'false');
//           formData.append('isSecondary', 'false');
//           formData.append('isTertiary', 'true');
//           formData.append('isMain', 'false');
//         }
//         formData.append('image', compressedFile);
//         formData.append('tripId', props.tripId);

//         // const token = await getIdToken(user);
//         // const token = await getAccessTokenSilently();
//         await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
//           credentials: 'same-origin',
//           method: 'POST',
//           body: formData,
//           headers: {
//             Authorization: `Bearer ${idToken}`,
//           },
//         });

//         await getUserTrip();
//         setMainImagePreview('');
//         setHeaderImagePreview('');
//         setSecondaryImagePreview('');
//         setTertiaryImagePreview('');
//         setMainImageLoading(false);
//         setHeaderImageLoading(false);
//         setSecondaryImageLoading(false);
//         setTertiaryImageLoading(false);
//       } catch (error) {
//         // TODO
//         console.log(error);
//       }
//     }
//   };

//   let mainImage1: Image | null = null;
//   let secondaryImage1: Image | null = null;
//   let tertiaryImage1: Image | null = null;
//   let headerImage1: Image | null = null;

//   props.images &&
//     props.images.forEach(i => {
//       if (i?.main) {
//         mainImage1 = i;
//       }
//       if (i?.secondary) {
//         secondaryImage1 = i;
//       }
//       if (i?.header) {
//         headerImage1 = i;
//       }
//       if (i?.tertiary) {
//         tertiaryImage1 = i;
//       }
//     });
//   return (
//     <Box>
//       <Box mb={2}>
//         <div>
//           <Stack direction="row">
//             {!mainImage1 ? (
//               <div>
//                 <label htmlFor="upload-image">
//                   <Box>
//                     <Box
//                       sx={{
//                         position: 'relative',
//                         width: '100%',
//                         height: '100%',
//                         marginRight: 1,
//                         marginBottom: 1,
//                       }}
//                     >
//                       {mainImagePreview !== '' ? (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image={mainImagePreview}
//                         />
//                       ) : (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image="/assets/image/placeholder-svg.svg"
//                         />
//                       )}
//                       {!mainImageLoading ? (
//                         <Box
//                           sx={theme => ({
//                             position: 'absolute',
//                             // width: '100%',
//                             bottom: 35,
//                             right: 45,
//                             paddingLeft: 2,
//                             paddingRight: 2,
//                             paddingTop: 1,
//                             paddingBottom: 1,
//                             // width: 39,
//                             // height: 39,
//                             backgroundColor: 'rgba(0, 0, 0, 0.6)',
//                             borderRadius: 20,
//                             zIndex: 2,
//                             [theme.breakpoints.down('md')]: {
//                               bottom: 30,
//                               right: 20,
//                             },
//                           })}
//                         >
//                           <HorizontalFlex>
//                             <CloudUploadTwoToneIcon
//                               sx={{ marginRight: 1, color: 'white' }}
//                             />
//                             <Box color="white">Add image</Box>
//                           </HorizontalFlex>
//                         </Box>
//                       ) : (
//                         <Box
//                           sx={{
//                             position: 'absolute',
//                             bottom: 30,
//                             right: 90,
//                             padding: 1,
//                             borderRadius: 20,
//                             zIndex: 2,
//                           }}
//                         >
//                           <Loader />
//                         </Box>
//                       )}
//                     </Box>
//                   </Box>
//                 </label>
//                 <input
//                   type="file"
//                   id="upload-image"
//                   style={{ display: 'none' }}
//                   onChange={e => {
//                     addImage(e, true, false, false, false);
//                   }}
//                 />
//               </div>
//             ) : (
//               <>
//                 {props.images &&
//                   props.images.length > 0 &&
//                   props.images.map((img, index) => (
//                     <Box>
//                       {img!.main && (
//                         <React.Fragment key={0}>
//                           <Box
//                             sx={{
//                               position: 'relative',
//                               width: '100%',
//                               height: '100%',
//                               marginRight: 1,
//                               marginBottom: 1,
//                             }}
//                           >
//                             <CardMedia
//                               sx={theme => ({
//                                 borderRadius: 0.5,
//                                 width: 215,
//                                 height: 120,
//                                 [theme.breakpoints.down('sm')]: {
//                                   width: 160,
//                                 },
//                               })}
//                               image={img!.path!}
//                             />
//                             <Box
//                               sx={{
//                                 position: 'absolute',
//                                 bottom: 12,
//                                 right: 12,
//                                 width: 39,
//                                 height: 39,
//                                 backgroundColor: 'rgba(255, 255, 255, 0.9)',
//                                 borderRadius: 20,
//                                 zIndex: 2,
//                               }}
//                             >
//                               <IconButton
//                                 aria-label="delete"
//                                 sx={{
//                                   position: 'absolute',
//                                   bottom: 4,
//                                   right: 4,
//                                   zIndex: 3,
//                                   color: '#FFFFFF',
//                                   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                                   '&:hover': {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//                                   },
//                                 }}
//                                 disabled={mainImageDeleting}
//                                 onClick={() => {
//                                   handleDelete(
//                                     img!.id!,
//                                     true,
//                                     false,
//                                     false,
//                                     false,
//                                   );
//                                 }}
//                               >
//                                 {mainImageDeleting ? (
//                                   <CircularProgress
//                                     size={15}
//                                     // sx={{ color: '#FFFFFF' }}
//                                   />
//                                 ) : (
//                                   <DeleteIcon
//                                     sx={{
//                                       height: 15,
//                                       width: 15,
//                                     }}
//                                   />
//                                 )}
//                               </IconButton>
//                             </Box>
//                           </Box>
//                         </React.Fragment>
//                       )}
//                     </Box>
//                   ))}
//               </>
//             )}
//             {!headerImage1 ? (
//               <div>
//                 <label htmlFor="upload-image">
//                   <Box>
//                     <Box
//                       sx={{
//                         position: 'relative',
//                         width: '100%',
//                         height: '100%',
//                         marginRight: 1,
//                         marginBottom: 1,
//                       }}
//                     >
//                       {headerImagePreview !== '' ? (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image={headerImagePreview}
//                         />
//                       ) : (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image="/assets/image/placeholder-svg.svg"
//                         />
//                       )}
//                       {!headerImageLoading ? (
//                         <Box
//                           sx={theme => ({
//                             position: 'absolute',
//                             // width: '100%',
//                             bottom: 35,
//                             right: 45,
//                             paddingLeft: 2,
//                             paddingRight: 2,
//                             paddingTop: 1,
//                             paddingBottom: 1,
//                             // width: 39,
//                             // height: 39,
//                             backgroundColor: 'rgba(0, 0, 0, 0.6)',
//                             borderRadius: 20,
//                             zIndex: 2,
//                             [theme.breakpoints.down('md')]: {
//                               bottom: 30,
//                               right: 20,
//                             },
//                           })}
//                         >
//                           <HorizontalFlex>
//                             <CloudUploadTwoToneIcon
//                               sx={{ marginRight: 1, color: 'white' }}
//                             />
//                             <Box color="white">Add image</Box>
//                           </HorizontalFlex>
//                         </Box>
//                       ) : (
//                         <Box
//                           sx={{
//                             position: 'absolute',
//                             bottom: 30,
//                             right: 90,
//                             padding: 1,
//                             borderRadius: 20,
//                             zIndex: 2,
//                           }}
//                         >
//                           <Loader />
//                         </Box>
//                       )}
//                     </Box>
//                   </Box>
//                 </label>
//                 <input
//                   type="file"
//                   id="upload-image"
//                   style={{ display: 'none' }}
//                   onChange={e => {
//                     addImage(e, false, true, false, false);
//                   }}
//                 />
//               </div>
//             ) : (
//               <>
//                 {props.images &&
//                   props.images.length > 0 &&
//                   props.images.map((img, index) => (
//                     <Box>
//                       {img!.header && (
//                         <React.Fragment key={1}>
//                           <Box
//                             sx={{
//                               position: 'relative',
//                               width: '100%',
//                               height: '100%',
//                               marginRight: 1,
//                               marginBottom: 1,
//                             }}
//                           >
//                             <CardMedia
//                               sx={theme => ({
//                                 borderRadius: 0.5,
//                                 width: 215,
//                                 height: 120,
//                                 [theme.breakpoints.down('sm')]: {
//                                   width: 160,
//                                 },
//                               })}
//                               image={img!.path!}
//                             />
//                             <Box
//                               sx={{
//                                 position: 'absolute',
//                                 bottom: 12,
//                                 right: 12,
//                                 width: 39,
//                                 height: 39,
//                                 backgroundColor: 'rgba(255, 255, 255, 0.9)',
//                                 borderRadius: 20,
//                                 zIndex: 2,
//                               }}
//                             >
//                               <IconButton
//                                 aria-label="delete"
//                                 sx={{
//                                   position: 'absolute',
//                                   bottom: 4,
//                                   right: 4,
//                                   zIndex: 3,
//                                   color: '#FFFFFF',
//                                   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                                   '&:hover': {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//                                   },
//                                 }}
//                                 disabled={headerImageDeleting}
//                                 onClick={() => {
//                                   handleDelete(
//                                     img!.id!,
//                                     false,
//                                     true,
//                                     false,
//                                     false,
//                                   );
//                                 }}
//                               >
//                                 {headerImageDeleting ? (
//                                   <CircularProgress
//                                     size={15}
//                                     // sx={{ color: '#FFFFFF' }}
//                                   />
//                                 ) : (
//                                   <DeleteIcon
//                                     sx={{
//                                       height: 15,
//                                       width: 15,
//                                     }}
//                                   />
//                                 )}
//                               </IconButton>
//                             </Box>
//                           </Box>
//                         </React.Fragment>
//                       )}
//                     </Box>
//                   ))}
//               </>
//             )}
//           </Stack>
//           <Stack direction="row">
//             {!secondaryImage1 ? (
//               <div>
//                 <label htmlFor="upload-image">
//                   <Box>
//                     <Box
//                       sx={{
//                         position: 'relative',
//                         width: '100%',
//                         height: '100%',
//                         marginRight: 1,
//                         marginBottom: 1,
//                       }}
//                     >
//                       {secondaryImagePreview !== '' ? (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image={secondaryImagePreview}
//                         />
//                       ) : (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image="/assets/image/placeholder-svg.svg"
//                         />
//                       )}
//                       {!secondaryImageLoading ? (
//                         <Box
//                           sx={theme => ({
//                             position: 'absolute',
//                             // width: '100%',
//                             bottom: 35,
//                             right: 45,
//                             paddingLeft: 2,
//                             paddingRight: 2,
//                             paddingTop: 1,
//                             paddingBottom: 1,
//                             // width: 39,
//                             // height: 39,
//                             backgroundColor: 'rgba(0, 0, 0, 0.6)',
//                             borderRadius: 20,
//                             zIndex: 2,
//                             [theme.breakpoints.down('md')]: {
//                               bottom: 30,
//                               right: 20,
//                             },
//                           })}
//                         >
//                           <HorizontalFlex>
//                             <CloudUploadTwoToneIcon
//                               sx={{ marginRight: 1, color: 'white' }}
//                             />
//                             <Box color="white">Add image</Box>
//                           </HorizontalFlex>
//                         </Box>
//                       ) : (
//                         <Box
//                           sx={{
//                             position: 'absolute',
//                             bottom: 30,
//                             right: 90,
//                             padding: 1,
//                             borderRadius: 20,
//                             zIndex: 2,
//                           }}
//                         >
//                           <Loader />
//                         </Box>
//                       )}
//                     </Box>
//                   </Box>
//                 </label>
//                 <input
//                   type="file"
//                   id="upload-image"
//                   style={{ display: 'none' }}
//                   onChange={e => {
//                     addImage(e, false, false, true, false);
//                   }}
//                 />
//               </div>
//             ) : (
//               <>
//                 {props.images &&
//                   props.images.length > 0 &&
//                   props.images.map((img, index) => (
//                     <Box>
//                       {img!.secondary && (
//                         <React.Fragment key={2}>
//                           <Box
//                             sx={{
//                               position: 'relative',
//                               width: '100%',
//                               height: '100%',
//                               marginRight: 1,
//                               marginBottom: 1,
//                             }}
//                           >
//                             <CardMedia
//                               sx={theme => ({
//                                 borderRadius: 0.5,
//                                 width: 215,
//                                 height: 120,
//                                 [theme.breakpoints.down('sm')]: {
//                                   width: 160,
//                                 },
//                               })}
//                               image={img!.path!}
//                             />
//                             <Box
//                               sx={{
//                                 position: 'absolute',
//                                 bottom: 12,
//                                 right: 12,
//                                 width: 39,
//                                 height: 39,
//                                 backgroundColor: 'rgba(255, 255, 255, 0.9)',
//                                 borderRadius: 20,
//                                 zIndex: 2,
//                               }}
//                             >
//                               <IconButton
//                                 aria-label="delete"
//                                 sx={{
//                                   position: 'absolute',
//                                   bottom: 4,
//                                   right: 4,
//                                   zIndex: 3,
//                                   color: '#FFFFFF',
//                                   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                                   '&:hover': {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//                                   },
//                                 }}
//                                 disabled={secondaryImageDeleting}
//                                 onClick={() => {
//                                   handleDelete(
//                                     img!.id!,
//                                     false,
//                                     false,
//                                     true,
//                                     false,
//                                   );
//                                 }}
//                               >
//                                 {secondaryImageDeleting ? (
//                                   <CircularProgress
//                                     size={15}
//                                     // sx={{ color: '#FFFFFF' }}
//                                   />
//                                 ) : (
//                                   <DeleteIcon
//                                     sx={{
//                                       height: 15,
//                                       width: 15,
//                                     }}
//                                   />
//                                 )}
//                               </IconButton>
//                             </Box>
//                           </Box>
//                         </React.Fragment>
//                       )}
//                     </Box>
//                   ))}
//               </>
//             )}
//             {!tertiaryImage1 ? (
//               <div>
//                 <label htmlFor="upload-image">
//                   <Box>
//                     <Box
//                       sx={{
//                         position: 'relative',
//                         width: '100%',
//                         height: '100%',
//                         marginRight: 1,
//                         marginBottom: 1,
//                       }}
//                     >
//                       {tertiaryImagePreview !== '' ? (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image={tertiaryImagePreview}
//                         />
//                       ) : (
//                         <CardMedia
//                           sx={theme => ({
//                             borderRadius: 0.5,
//                             width: 215,
//                             height: 120,
//                             [theme.breakpoints.down('sm')]: {
//                               width: 160,
//                             },
//                           })}
//                           image="/assets/image/placeholder-svg.svg"
//                         />
//                       )}
//                       {!tertiaryImageLoading ? (
//                         <Box
//                           sx={theme => ({
//                             position: 'absolute',
//                             // width: '100%',
//                             bottom: 35,
//                             right: 45,
//                             paddingLeft: 2,
//                             paddingRight: 2,
//                             paddingTop: 1,
//                             paddingBottom: 1,
//                             // width: 39,
//                             // height: 39,
//                             backgroundColor: 'rgba(0, 0, 0, 0.6)',
//                             borderRadius: 20,
//                             zIndex: 2,
//                             [theme.breakpoints.down('md')]: {
//                               bottom: 30,
//                               right: 20,
//                             },
//                           })}
//                         >
//                           <HorizontalFlex>
//                             <CloudUploadTwoToneIcon
//                               sx={{ marginRight: 1, color: 'white' }}
//                             />
//                             <Box color="white">Add image</Box>
//                           </HorizontalFlex>
//                         </Box>
//                       ) : (
//                         <Box
//                           sx={{
//                             position: 'absolute',
//                             bottom: 30,
//                             right: 90,
//                             padding: 1,
//                             borderRadius: 20,
//                             zIndex: 2,
//                           }}
//                         >
//                           <Loader />
//                         </Box>
//                       )}
//                     </Box>
//                   </Box>
//                 </label>
//                 <input
//                   type="file"
//                   id="upload-image"
//                   style={{ display: 'none' }}
//                   onChange={e => {
//                     addImage(e, false, false, false, true);
//                   }}
//                 />
//               </div>
//             ) : (
//               <>
//                 {props.images &&
//                   props.images.length > 0 &&
//                   props.images.map((img, index) => (
//                     <Box>
//                       {img!.tertiary && (
//                         <React.Fragment key={3}>
//                           <Box
//                             sx={{
//                               position: 'relative',
//                               width: '100%',
//                               height: '100%',
//                               marginRight: 1,
//                               marginBottom: 1,
//                             }}
//                           >
//                             <CardMedia
//                               sx={theme => ({
//                                 borderRadius: 0.5,
//                                 width: 215,
//                                 height: 120,
//                                 [theme.breakpoints.down('sm')]: {
//                                   width: 160,
//                                 },
//                               })}
//                               image={img!.path!}
//                             />
//                             <Box
//                               sx={{
//                                 position: 'absolute',
//                                 bottom: 12,
//                                 right: 12,
//                                 width: 39,
//                                 height: 39,
//                                 backgroundColor: 'rgba(255, 255, 255, 0.9)',
//                                 borderRadius: 20,
//                                 zIndex: 2,
//                               }}
//                             >
//                               <IconButton
//                                 aria-label="delete"
//                                 sx={{
//                                   position: 'absolute',
//                                   bottom: 4,
//                                   right: 4,
//                                   zIndex: 3,
//                                   color: '#FFFFFF',
//                                   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                                   '&:hover': {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//                                   },
//                                 }}
//                                 disabled={tertiaryImageDeleting}
//                                 onClick={() => {
//                                   handleDelete(
//                                     img!.id!,
//                                     false,
//                                     false,
//                                     false,
//                                     true,
//                                   );
//                                 }}
//                               >
//                                 {tertiaryImageDeleting ? (
//                                   <CircularProgress
//                                     size={15}
//                                     // sx={{ color: '#FFFFFF' }}
//                                   />
//                                 ) : (
//                                   <DeleteIcon
//                                     sx={{
//                                       height: 15,
//                                       width: 15,
//                                     }}
//                                   />
//                                 )}
//                               </IconButton>
//                             </Box>
//                           </Box>
//                         </React.Fragment>
//                       )}
//                     </Box>
//                   ))}
//               </>
//             )}
//           </Stack>
//         </div>
//       </Box>
//     </Box>
//   );
// }

import { gql } from 'graphql-tag';

export const QUERY_GET_USER_FAVOURITE_PLACES = gql`
  query userLikedPlaces {
    userLikedPlaces {
      id
      lat
      lng
      city
      country
      region
      countryCode
      externalPlaceId
      title
      category
      noOfReviews
      rating
      description
      quickFact
      phoneNumber
      website
      address
      aiDescription
      checkInTime
      checkOutTime
      placeType
      dataSource
      bookingLink
      openingHours {
        weekDay
        hours
      }
      images {
        title
        path
        contributorId
        imageUrl
      }
    }
  }
`;

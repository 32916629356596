// import {
//   ApolloClient,
//   ApolloProvider,
//   HttpLink,
//   InMemoryCache,
// } from '@apollo/client';
// import { ReactNode } from 'react';

// interface Props {
//   readonly children?: ReactNode;
// }

// // Export the unauthorized Apollo Client instance
// const unauthorizedClient = new ApolloClient({
//   link: new HttpLink({
//     uri: process.env.REACT_APP_CONFIG_URL, // Unauthorized API URL
//   }),
//   cache: new InMemoryCache(),
// });

// const UnauthorizedApolloProvider = ({ children }: Props) => {
//   return (
//     <ApolloProvider client={unauthorizedClient}>{children}</ApolloProvider>
//   );
// };

// export { unauthorizedClient }; // Export the client to use it manually
// export default UnauthorizedApolloProvider;
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from './store/ErrorSlice';

interface Props {
  readonly children?: ReactNode;
}

const unauthorizedClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_CONFIG_URL, // Unauthorized API URL
  }),
  cache: new InMemoryCache(),
});

const UnauthorizedApolloProvider = ({ children }: Props) => {
  const dispatch = useDispatch();

  // Handle GraphQL and Network errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors) {
    //   graphQLErrors.forEach(({ message, locations, path }) => {
    //     console.error(
    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //     );
    //     // Dispatch an action to open the error modal with the appropriate message
    //     dispatch(setErrorTitle('Something went wrong')); // Set error title in Redux
    //     dispatch(setErrorModalOpen(true)); // Show the error modal
    //   });
    // }

    if (networkError) {
      // Dispatch an action to open the error modal for network issues
      dispatch(setErrorTitle('No internet connection')); // Set error title in Redux
      dispatch(setErrorModalOpen(true)); // Show the error modal
    }
  });

  // Setup HTTP link for the API
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_CONFIG_URL, // Unauthorized API URL
  });

  // Combine the error link and http link
  const link = ApolloLink.from([errorLink, httpLink]);

  // Create the Apollo Client with error handling
  const unauthorizedClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={unauthorizedClient}>{children}</ApolloProvider>
  );
};

export { unauthorizedClient }; // Export the client to use it manually if needed
export default UnauthorizedApolloProvider;

import { useMutation } from '@apollo/client';
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import { signInWithCustomToken, getAuth } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';

import { ProminentButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import {
  MUTATION_SEND_OTP,
  MUTATION_VERIFY_OTP,
} from '../gql-public/authMutation';

const SignUp: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [showCodeField, setShowCodeField] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>('');
  const auth = getAuth();
  const emailRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showCodeField && codeRef.current) {
      codeRef.current.focus(); // Focus the TextField when needed
    }
  }, [showCodeField]);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus(); // Focus the TextField on component mount
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if using a form tag
      if (showCodeField) {
        handleVerifyOTP(); // Trigger verify function when Enter is pressed
      } else {
        handleSendOTP(); // Trigger send OTP function when Enter is pressed
      }
    }
  };

  const [sendOTP, { loading: sending }] = useMutation(MUTATION_SEND_OTP, {
    variables: { email: email },
  });

  const [verifyOTP, { loading: verifying }] = useMutation(MUTATION_VERIFY_OTP, {
    variables: { email: email, code: code },
  });

  const handleSendOTP = async () => {
    try {
      const result = await sendOTP();

      if (
        result &&
        result.data &&
        result.data.sendOtp &&
        result.data.sendOtp.success === true
      ) {
        setEmailSent(true);
        setShowCodeField(true);
        setErrorMessage('');
      } else {
        // Show error message for failed OTP sending
        setErrorMessage('Failed to send verification email.');
      }
    } catch (e) {
      setErrorMessage('Failed to send verification email.');
    }
  };

  const handleVerifyOTP = async () => {
    if (code && email) {
      try {
        const res = await verifyOTP();
        if (
          res &&
          res.data &&
          res.data.validateOtp &&
          res.data.validateOtp.token &&
          res.data.validateOtp.token !== ''
        ) {
          const token = res.data.validateOtp.token;
          await signInWithCustomToken(auth, token);
        } else {
          // Show error message for wrong code
          setCodeErrorMessage('Wrong code. Please resend.');
        }
      } catch (e) {
        setCodeErrorMessage('Wrong code. Please resend.');
      }
    }
  };

  const handleReset = () => {
    setShowCodeField(false);
    setEmailSent(false);
    setCode('');
    setErrorMessage('');
    setCodeErrorMessage('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Login
      </Typography>
      <Typography sx={{ marginBottom: 4 }}>
        Enter your email to login or sign up
      </Typography>

      {/* Email Input Field with Change Button */}
      <TextField
        label="Email"
        type="email"
        value={email}
        inputRef={emailRef}
        onKeyDown={handleKeyDown}
        onChange={e => setEmail(e.target.value)}
        sx={{ marginBottom: 1, width: '300px' }}
        InputProps={{
          endAdornment: emailSent && (
            <InputAdornment position="end">
              <Button sx={{ padding: 0 }} onClick={handleReset}>
                Change
              </Button>
            </InputAdornment>
          ),
        }}
        disabled={emailSent}
        error={!!errorMessage}
      />
      <FormHelperText error>{errorMessage}</FormHelperText>

      {/* Code Input Field */}
      {showCodeField && (
        <TextField
          label="Enter Code"
          type="text"
          value={code}
          inputRef={codeRef}
          onKeyDown={handleKeyDown}
          onChange={e => setCode(e.target.value)}
          sx={{ marginBottom: 1, width: '300px' }}
          InputProps={{
            endAdornment: !!codeErrorMessage && emailSent && (
              <InputAdornment position="end">
                <Button
                  sx={{ padding: 0 }}
                  onClick={() => {
                    handleSendOTP();
                    setCode('');
                    setCodeErrorMessage('');
                  }}
                >
                  Resent
                </Button>
              </InputAdornment>
            ),
          }}
          error={!!codeErrorMessage}
        />
      )}
      <FormHelperText error>{codeErrorMessage}</FormHelperText>

      {/* Login Button */}
      <ProminentButton
        variant="contained"
        onClick={showCodeField ? handleVerifyOTP : handleSendOTP}
        sx={{ width: '300px' }}
        disabled={sending || verifying}
      >
        {sending || verifying ? <Loader /> : 'Login'}
      </ProminentButton>
    </Box>
  );
};

export default SignUp;

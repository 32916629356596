import { useMutation } from '@apollo/client';
import { Box, Stack, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { PrimaryButton } from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPSERT_SOCIAL_LINK } from '../../gql-user/social';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
  ModalPaper,
} from '../../styling/modal';

import { StyledFormControl } from './Styles';
import Space from '../../../theme-components/Spacing';
import ModalHeader from '../../../theme-components/ModalComponents';

export const LinkSocialLinkToStepModal: React.FC<{
  stepId: string;
  tripId: string;
  handleCloseModal: () => void;
}> = ({ stepId, tripId, handleCloseModal }) => {
  const closeModal = () => {
    gtag('event', 'link-place-to-step-button');
    handleCloseModal();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ModalHeader
          title="Link social post"
          description={
            <>
              First select the social platform and then copy{' '}
              <strong>Instagram</strong>, <strong>YouTube</strong> or{' '}
              <strong>TikTok</strong> sharable link and paste bellow
            </>
          }
        />

        <LinkSocialLink
          stepId={stepId}
          tripId={tripId}
          handleCloseModal={closeModal}
        />
      </ModalPaper>
    </CenteredModal>
  );
};

const LinkSocialLink: React.FC<{
  stepId: string;
  tripId: string;
  handleCloseModal: () => void;
}> = ({ stepId, tripId, handleCloseModal }) => {
  const [linkUrl, setLinkUrl] = useState('');
  const dispatch = useDispatch();
  const [isValidUrl, setIsValidUrl] = useState(true);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  // Open the keyboard automatically
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus(); // Focus the TextField to open the keyboard
    }
  }, []);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://www.instagram.com/',
      'https://www.tiktok.com/',
      'https://youtu.be/',
      'https://vm.tiktok.com/',
      'https://www.youtube.com/',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setLinkUrl(newUrl);
    if (newUrl && newUrl.startsWith('https://www.instagram.com/')) {
      setSocialMedia('instagram');
    }
    if (
      newUrl &&
      (newUrl.startsWith('https://www.tiktok.com/') ||
        newUrl.startsWith('https://vm.tiktok.com/'))
    ) {
      setSocialMedia('tiktok');
    }
    if (
      newUrl &&
      (newUrl.startsWith('https://youtu.be/') ||
        newUrl.startsWith('https://www.youtube.com/'))
    ) {
      setSocialMedia('youtube');
    }

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [socialMedia, setSocialMedia] = useState('');

  const [saveSocialLink, { loading }] = useMutation(
    MUTATION_UPSERT_SOCIAL_LINK,
    {
      variables: {
        url_link: linkUrl,
        step_id: stepId,
        source_trip_id: tripId,
        social_type: socialMedia,
      },
    },
  );

  return (
    <>
      <StyledFormControl>
        <TextField
          inputRef={textFieldRef}
          label="Social post URL"
          fullWidth={true}
          variant="outlined"
          onChange={handleChange}
          helperText={
            !isValidUrl &&
            'This is not sharable URL form YouTube, TikTik or Instagram'
          }
          sx={{ backgroundColor: '#FAFAFA' }}
        />
        <Space size="md" />
        <PrimaryButton
          variant="contained"
          color="primary"
          disabled={
            linkUrl === '' || loading || !isValidUrl || socialMedia === ''
          }
          onClick={async () => {
            gtag('event', 'link-place-to-step');
            try {
              const res = await saveSocialLink();
              if (
                res &&
                res.data &&
                res.data.upsertSocialLink &&
                res.data.upsertSocialLink.itinerary &&
                res.data.upsertSocialLink.itinerary.days
              ) {
                gtag('event', 'place-linked');
                dispatch(setDays(res.data.upsertSocialLink.itinerary.days));
                handleCloseModal();
              }
            } catch (e) {
              gtag('event', 'error-link-place');
              dispatch(setErrorTitle('Error linking place'));
              dispatch(setErrorModalOpen(true));
            }
          }}
        >
          {loading ? <Loader /> : <>Link social post</>}
        </PrimaryButton>
      </StyledFormControl>
    </>
  );
};

import { Box, Stack } from '@mui/material';

import { Country } from '../../generated/public_graphql';
import { Body1 } from '../../theme-components/Typography';
import { HorizontalFlex, VerticalFlex } from '../helpers/flex';
import { RenderHtmlFromResponse } from '../RenderHtmlFromResponse';

import {
  SideBarHeader,
  SideBarIcon,
  SideBarLink,
  SideBarTitleDivider,
} from './TripSidebar';

interface Props {
  readonly countries: [Country];
}

export function TripCountries(props: Props) {
  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.countries[0];

  return (
    <VerticalFlex>
      <Stack direction="row">
        <SideBarIcon $iconType={country.iso2Code} />
        <SideBarHeader>{country.name}</SideBarHeader>
      </Stack>
      <SideBarTitleDivider />

      {country && country.region && (
        <HorizontalFlex>
          <Box
            sx={theme => ({
              marginBottom: 1,
              marginRight: 1,
              fontWeight: 600,
              fontSize: 18,
              color: 'black',
              [theme.breakpoints.down('sm')]: {
                marginBottom: 0.5,
              },
            })}
          >
            Located in:{' '}
          </Box>
          {country && country.subRegion && (
            <Box
              sx={theme => ({
                marginBottom: 1,
                marginRight: 1,
                fontWeight: 400,
                fontSize: 18,
                color: theme.palette.secondary.main,
                [theme.breakpoints.down('sm')]: {
                  marginBottom: 0.5,
                },
              })}
            >
              {country.subRegion.name},
            </Box>
          )}
          <Box
            sx={theme => ({
              marginBottom: 1,
              marginRight: 0.5,
              fontWeight: 400,
              fontSize: 18,
              color: theme.palette.secondary.main,
              [theme.breakpoints.down('sm')]: {
                marginBottom: 0.5,
              },
            })}
          >
            {country.region.name}
          </Box>
        </HorizontalFlex>
      )}
      {country.description && (
        <Body1>
          <RenderHtmlFromResponse content={country.description} />
        </Body1>
      )}
      <br />
      {country.url && (
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SideBarLink
            url={country.url}
            copy={'More info about ' + country.name}
          />
        </Box>
      )}
    </VerticalFlex>
  );
}

import { useLazyQuery } from '@apollo/client';
import { Box, Divider, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProminentButton } from '../../theme-components/Buttons';
import { Loader, LoaderCentred } from '../../theme-components/Loader';
import { QUERY_GET_COUNTRIES_WITH_PUBLISHED_TRIPS } from '../gql-user/getCountriesWithPublishedTripsQuery';
import { QUERY_GET_PUBLISHED_TRIPS_FOR_COUNTRY } from '../gql-user/getPublishedTripsByCountryQuery';
import { HorizontalFlex } from '../helpers/flex';
import {
  CenteredModal,
  ModalPaper,
  ModalDescription,
  ModalTitle,
} from '../styling/modal';
import TextDescription, { TitleH3 } from '../../theme-components/Typography';
import { Spa } from '@mui/icons-material';
import Space from '../../theme-components/Spacing';
import ModalHeader from '../../theme-components/ModalComponents';

export function TripsResults(props: { userTripId: string }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [countryId, setCountryId] = useState('');

  const [getCountries, { loading, error, data }] = useLazyQuery(
    QUERY_GET_COUNTRIES_WITH_PUBLISHED_TRIPS,
  );

  const [
    searchForTrips,
    { data: dataCountryTrips, loading: loadingCountryTrips },
  ] = useLazyQuery(QUERY_GET_PUBLISHED_TRIPS_FOR_COUNTRY);

  useEffect(() => {
    if (countryId !== '') {
      searchForTrips({
        variables: { country_id: countryId },
      });
    }
  }, [countryId, searchForTrips]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // error = undefined;
    setOpen(false);
  };

  return (
    <>
      <ProminentButton
        onClick={() => {
          handleOpen();
          getCountries();
        }}
      >
        Get inspired
      </ProminentButton>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
        sx={{
          position: 'relative',
          zIndex: 1000, // Ensures the modal itself is on top of the backdrop
        }}
      >
        <ModalPaper sx={{ position: 'relative' }}>
          <ModalHeader
            title="Search for trip"
            description={
              <>
                Search for the trip by <strong>country</strong> you are
                visiting. We are doing everything we can to add more trips and
                countries every day.
              </>
            }
          />

          {loading ? (
            <Loader />
          ) : (
            <Box width="100%">
              {error || !data ? (
                <Box>Error please close and try again</Box>
              ) : (
                <>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={data.countriesWithPublishedTripsList.map(
                      option => option.name,
                    )}
                    onInputChange={async (event, newInputValue) => {
                      data.countriesWithPublishedTripsList.forEach(country => {
                        if (newInputValue === country.name) {
                          setCountryId(country.id);
                          // searchForTrips({
                          //   variables: { country_id: country.id },
                          // });
                        }
                      });
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Search by country"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                  {loadingCountryTrips && <LoaderCentred />}
                  {dataCountryTrips &&
                    dataCountryTrips.searchTripsByCountry &&
                    dataCountryTrips.searchTripsByCountry.length > 0 && (
                      <Box
                        sx={theme => ({
                          marginTop: -0.5, // Ensure results are spaced below the input
                          padding: 2,
                          width: '100%',
                          backgroundColor: 'white',
                          boxShadow: 1,
                          maxHeight: '400px', // Optional: Max height with scroll if needed
                          overflowY: 'auto', // To make sure results are scrollable
                          position: 'relative', // Ensures it stays within modal
                          zIndex: 1100, // Ensures it sits above any potential backdrop or other elements
                        })}
                      >
                        {dataCountryTrips.searchTripsByCountry.map(
                          (trip, index) => (
                            <Fragment key={index + trip.id}>
                              {trip && props.userTripId !== trip.id && (
                                <>
                                  <Box
                                    onClick={() => {
                                      navigate(
                                        '/create-route/' +
                                          props.userTripId +
                                          '?selected-trip=' +
                                          trip.id,
                                      );
                                      handleClose();
                                    }}
                                  >
                                    <Box
                                      width="100%"
                                      sx={{ textAlign: 'left' }}
                                    >
                                      <ResultTitle>{trip.name}</ResultTitle>
                                    </Box>
                                    {trip.length ? (
                                      <Box>
                                        <HorizontalFlex>
                                          <ResultTripLength>
                                            {trip.length + ' days in'}
                                          </ResultTripLength>
                                          <Box justifyContent="flex-end">
                                            {trip &&
                                              trip.countries &&
                                              trip.countries.length > 0 && (
                                                <HorizontalFlex>
                                                  {trip.countries.map(
                                                    (country, index) => (
                                                      <Box key={index}>
                                                        {country && (
                                                          <ResultTripLength>
                                                            {country.name}
                                                            {trip.countries &&
                                                              index !==
                                                                trip.countries
                                                                  .length -
                                                                  1 && (
                                                                <>{','}</>
                                                              )}
                                                          </ResultTripLength>
                                                        )}
                                                      </Box>
                                                    ),
                                                  )}
                                                </HorizontalFlex>
                                              )}
                                          </Box>
                                        </HorizontalFlex>
                                      </Box>
                                    ) : null}
                                  </Box>
                                  <Divider
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                  />
                                </>
                              )}
                            </Fragment>
                          ),
                        )}
                      </Box>
                    )}
                </>
              )}
            </Box>
          )}
        </ModalPaper>
      </CenteredModal>
    </>
  );
}

function ResultTitle(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 500,
        fontSize: 16,
        marginTop: 0.5,
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      })}
    >
      {props.children}
    </Box>
  );
}
function ResultTripLength(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 400,
        fontSize: 14,
        marginRight: 0.5,

        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

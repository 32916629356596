import { useMutation } from '@apollo/client';
import { Box, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import {
  accommodationsSelector,
  setAccommodationDescription,
  setAccommodationDescriptionAdded,
  setAccommodationLatitude,
  setAccommodationLongitude,
  setAccommodationUrl,
} from '../../../../store/AccommodationSlice';
import { setDays } from '../../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../../store/ErrorSlice';
import { setAddStepModalOpen } from '../../../../store/StepSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../theme-components/Buttons';
import { Loader } from '../../../../theme-components/Loader';
import Space from '../../../../theme-components/Spacing';
import { MUTATION_UPDATE_ACCOMMODATION } from '../../../gql-user/updateAccommodation';
import MyEditor from '../../../MyEditor';
import { StyledFormControl } from '../Styles';

export const AddAccommodationInfo: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  const {
    accommodationId,
    accommodationDescription,
    accommodationUrl,
    accommodationLatitude,
    accommodationLongitude,
  } = useSelector(accommodationsSelector);

  const [updateAccommodation, { loading }] = useMutation(
    MUTATION_UPDATE_ACCOMMODATION,
    {
      variables: {
        accommodation_id: accommodationId,
        description: accommodationDescription,
        url: accommodationUrl,
        latitude: accommodationLatitude,
        longitude: accommodationLongitude,
      },
    },
  );

  const handleEditorChange = (content: string) => {
    dispatch(setAccommodationDescription(content));
  };

  return (
    <>
      <StyledFormControl>
        <MyEditor
          value={accommodationDescription}
          onChange={handleEditorChange}
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id="standard-basic"
          label="Any hotel link to book"
          name="step-location"
          multiline={true}
          fullWidth={true}
          variant="outlined"
          onChange={e => {
            dispatch(setAccommodationUrl(e.target.value));
          }}
          sx={{ backgroundColor: '#fafafa' }}
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id="standard-basic"
          label="Place location (paste coordinates)"
          name="step-location"
          multiline={true}
          fullWidth={true}
          variant="outlined"
          onChange={e => {
            const res = e.target.value.split(',', 2);
            const latitude = parseFloat(res[0]);
            const longitude = parseFloat(res[1]);
            if (latitude <= 90 && latitude >= -90) {
              dispatch(setAccommodationLatitude(latitude));
            } else {
              dispatch(setAccommodationLatitude(0));
            }
            if (longitude <= 180 && latitude >= -180) {
              dispatch(setAccommodationLongitude(longitude));
            } else {
              dispatch(setAccommodationLongitude(0));
            }
          }}
          sx={{ backgroundColor: '#fafafa' }}
        />
      </StyledFormControl>
      <Space size="md" />
      <PrimaryButton
        disabled={loading}
        onClick={async () => {
          gtag('event', 'add-accommodation-details-next');
          try {
            const res = await updateAccommodation();
            if (
              res &&
              res.data &&
              res.data.updateUserAccommodation &&
              res.data.updateUserAccommodation.days
            ) {
              dispatch(setDays(res.data.updateUserAccommodation.days));
              dispatch(setAccommodationDescriptionAdded(true));
            }
          } catch (e) {
            gtag('event', 'error-add-accommodation-details');
            dispatch(setErrorTitle('Error adding details'));
            dispatch(setErrorModalOpen(true));
            handleCloseModal();
          }
        }}
      >
        {loading ? <Loader /> : 'Add to itinerary'}
      </PrimaryButton>
      <Space size="md" />
      <SecondaryButton
        onClick={() => {
          gtag('event', 'add-accommodation-details-add-later');
          handleCloseModal();
          dispatch(setAddStepModalOpen(false));
          handleCloseModal();
        }}
      >
        Add later
      </SecondaryButton>
    </>
  );
};

import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { CookieConsent } from 'react-cookie-consent';
import { Route, Routes } from 'react-router-dom';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import ComponentsPreviewRoute from '../routes/ComponentsRoute';
import CountryRoute from '../routes/CountryRoute';
import CreateTripRoute from '../routes/CreateTripRoute';
import ExploreRoute from '../routes/ExploreRoute';
import GetTheApp from '../routes/GetTheApp';
import MyAccountRoute from '../routes/MyAccountRoute';
import MyTripsRoute from '../routes/MyTripsRoute';
import TripPreviewRoute from '../routes/TripPreviewRoute';
import TripRoute from '../routes/TripRoute';

import { FullScreenBusAnimation } from './animations/BusAnimation';
import AppBar from './AppBar';
import AppFooter from './AppFooter';
import UserPublicTrips from '../routes/UserPublicTripsRoute';
import MyFavouritesRoute from '../routes/MyFavouritesRoute';

const HomeRoute = lazy(() => import('../routes/HomeRoute'));
const NotFoundRoute = lazy(() => import('../routes/NotFoundRoute'));

export function App() {
  return (
    <>
      <Box sx={{ position: 'relative', minHeight: '100%' }}>
        <AppBar />
        <Suspense fallback={<FullScreenBusAnimation copy="Almost there!" />}>
          <Routes>
            <Route path="/" element={<HomeRoute />} />
            <Route
              path="/terms"
              element={
                <iframe
                  title="Terms and Conditions"
                  src="/terms.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route
              path="/privacy"
              element={
                <iframe
                  title="Privacy Policy"
                  src="/privacy.html"
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              }
            />
            <Route path="/explore" element={<ExploreRoute />} />
            <Route path="/trip/:id" element={<TripRoute />} />
            <Route
              path="/preview/:id"
              element={
                <AuthorizedApolloProvider>
                  <TripPreviewRoute />
                </AuthorizedApolloProvider>
              }
            />
            <Route
              path="/components-preview"
              element={<ComponentsPreviewRoute />}
            />
            <Route path="/my-trips" element={<MyTripsRoute />} />
            <Route path="/my-favourite" element={<MyFavouritesRoute />} />
            <Route path="/my-account" element={<MyAccountRoute />} />
            <Route path="/user-trips/:id" element={<UserPublicTrips />} />
            {/* <Route path="/finish-sign-up" element={<FinishSignUp />} /> */}
            <Route
              path="/create-route/:userTripId"
              element={<CreateTripRoute />}
            />
            <Route path="/country/:id" element={<CountryRoute />} />
            <Route path="/download" element={<GetTheApp />} />
            <Route path="*" element={<NotFoundRoute />} />
          </Routes>
        </Suspense>
      </Box>
      <CookieConsent
        location="bottom" // Can be 'top', 'bottom', 'none', etc.
        buttonText="I understand" // Text for the consent button
        cookieName="ga-cookie" // Unique name for the cookie
        style={{ background: '#2B373B' }} // Custom styling for the consent bar
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }} // Custom styling for the consent button
        expires={150} // Expiration of the cookie in days
        onAccept={() => {
          window['ga-disable-G-1BZRDDCMGF'] = false;
          gtag('config', 'G-1BZRDDCMGF');
        }}
      >
        Rooutie uses cookies only to enhance the user experience.
      </CookieConsent>
      <AppFooter />
    </>
  );
}

import { styled, Box } from '@mui/material';
import { ReactNode } from 'react';

// Define styled components
const HorizontalFlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
}));

const VerticalFlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

// Component usage
export function HorizontalFlex(props: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <HorizontalFlexContainer className={props.className}>
      {props.children}
    </HorizontalFlexContainer>
  );
}

export function VerticalFlex(props: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <VerticalFlexContainer className={props.className}>
      {props.children}
    </VerticalFlexContainer>
  );
}

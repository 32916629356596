import { useMutation } from '@apollo/client';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Trip } from '../../generated/user_graphql';
import { setTrip } from '../../store/TripSlice';
import { CardStar } from '../../theme-components/Lables';
import { Loader } from '../../theme-components/Loader';
import { MUTATION_SET_TRIP_MAIN_IMAGE } from '../gql-user/images';

export function SetMainImage({
  tripId,
  imageId,
  isMain,
  bottom = '0px',
  right = '0px',
}: {
  tripId: string;
  imageId: string;
  isMain: boolean;
  bottom?: string;
  right?: string;
}) {
  const dispatch = useDispatch();

  const handleSetMainImage = async () => {
    await setMainImage();
  };
  console.log('isMain', isMain);
  console.log('imageId', imageId);

  const [setMainImage, { loading, data }] = useMutation<{
    setMainTripImage: Trip;
  }>(MUTATION_SET_TRIP_MAIN_IMAGE, {
    variables: {
      trip_id: tripId,
      image_id: imageId,
    },
  });

  useEffect(() => {
    if (data && data.setMainTripImage) {
      dispatch(setTrip(data.setMainTripImage));
    }
  }, [data]);

  return (
    <Box>
      <Box
        onClick={event => {
          handleSetMainImage();
        }}
      >
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: bottom,
              right: right,
              width: 39,
              height: 39,
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              backdropFilter: 'blur(3px)',
              borderRadius: 20,
              zIndex: 2,
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Loader />
          </Box>
        ) : (
          <CardStar main={isMain} bottom={bottom} right={right} />
        )}
      </Box>
    </Box>
  );
}

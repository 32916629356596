import { Box, Stack, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import Lottie from 'react-lottie';

import * as bus from './bus-animation.json';

interface LottiePlayerProps {
  animationData: any;
  style?: CSSProperties;
}

export const BusAnimation: React.FC = () => {
  return (
    <Box
      sx={theme => ({
        width: '500px',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '300px',
          height: '500px',
        },
      })}
    >
      <LottiePlayer animationData={bus} />
    </Box>
  );
};

export default BusAnimation;

interface Props {
  readonly copy?: string;
}

export function FullScreenBusAnimation(props: Props) {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        backdropFilter: 'blur(8px)',
        width: '100vw',
        height: '100vh',
        zIndex: 20000,
      }}
    >
      <Stack
        direction="column"
        sx={theme => ({
          width: '500px',
          height: '500px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('sm')]: {
            width: '380px',
            height: '500px',
          },
        })}
      >
        <LottiePlayer animationData={bus} />

        <Typography
          variant="h6"
          sx={theme => ({
            fontWeight: 600,
            marginTop: -4,
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
              marginTop: 0,
            },
          })}
        >
          {props.copy}
        </Typography>
      </Stack>
    </Box>
  );
}

export function LottiePlayer(Props: LottiePlayerProps) {
  const { animationData, style } = Props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} style={style} />;
}

import { useMutation } from '@apollo/client';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import {
  daysSelector,
  setDayLoadingIndex,
  setDays,
  setStepLoadingIndex,
} from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector } from '../../../store/TripSlice';
import { MUTATION_MOVE_DAY } from '../../gql-user/moveDayMutation';
import { MUTATION_MOVE_STEP } from '../../gql-user/moveStepMutation';
import { HorizontalFlex } from '../../helpers/flex';
import { RouteContent, RoutePage, RouteTitle } from '../../route';
import { MapDrawer } from '../../trip-details/MapDrawer';
import { TripHeaderImages } from '../../trip-details/TripHeader';
import { TripMap } from '../../trip-details/TripMap';
import { CreateTripDetails } from '../edit-trip/TripDetails';
import AddTripImagesModal from '../manage-trip-modals/AddTripImages';

import {
  moveStepBetweenDays,
  reorderDays,
  reorderSteps,
} from './dragAndDropFunctions';
import { DraggableDroppableDays } from './DraggableDroppableDays';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    $isDraggingOver?: boolean;
    $isDragging?: boolean;
  }
}

export function DragAndDropUserItinerarySingleTrip() {
  const dispatch = useDispatch();
  const [moveDayInItinerary] = useMutation(MUTATION_MOVE_DAY);
  const [moveStep] = useMutation(MUTATION_MOVE_STEP);
  gtag('event', 'create-user-trip-single-page');
  const stepRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollPositionRef = useRef<number>(0);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { trip, tripName, tripDescription } = useSelector(tripsSelector);
  const { days } = useSelector(daysSelector);

  const scrollToStep = (stepId: string) => {
    // Check if the reference exists for this stepId
    if (stepId) {
      const stepRef = stepRefs.current[stepId];
      if (stepRef) {
        // Check if stepRef is not null
        stepRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  useEffect(() => {
    // Restore the scroll position if it's stored
    if (scrollPositionRef.current && selectedStepId === null) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [selectedStepId]);

  const handleMarkerClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCloseClick = () => {
    scrollPositionRef.current = window.scrollY;
    setSelectedStepId(null);
  };

  if (!trip || !days) {
    return (
      <RoutePage>
        <RouteTitle>Something went wrong</RouteTitle>
        <RouteContent>Please reload the page and try again.</RouteContent>
      </RoutePage>
    );
  }

  async function onDragEnd(result) {
    const { source, destination, draggableId } = result;

    if (!destination || !days) {
      return;
    }

    if (
      source.droppableId === 'itinerary' &&
      destination.droppableId === 'itinerary'
    ) {
      // ------------------------
      // Move day
      // ------------------------
      gtag('event', 'move-day-in-trip');

      const existingDays = reorderDays(days, source.index, destination.index);

      dispatch(setDayLoadingIndex(destination.index));
      dispatch(setDays(existingDays));

      try {
        const { data } = await moveDayInItinerary({
          variables: {
            day_id: draggableId.replace('_temp', ''),
            new_day_number: destination.index + 1,
          },
        });
        dispatch(setDays(data.moveDay.days));
        dispatch(setDayLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving day'));
        dispatch(setErrorModalOpen(true));
      }
    } else if (source.droppableId === destination.droppableId) {
      // ------------------------
      // Move step in day
      // ------------------------
      gtag('event', 'move-step-in-day');

      const { newDays, dayIndex } = reorderSteps(
        days,
        source.droppableId,
        source.index,
        destination.index,
      );

      dispatch(setDays(newDays));
      dispatch(setDayLoadingIndex(dayIndex));
      dispatch(setStepLoadingIndex(destination.index));

      try {
        const response = await moveStep({
          variables: {
            step_id: draggableId.replace('_temp', ''),
            new_day_id: destination.droppableId.replace('_temp', ''),
            original_day_id: source.droppableId.replace('_temp', ''),
            new_step_number: destination.index + 1,
          },
        });
        dispatch(setDays(response.data.moveStep.days));
        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving activity'));
        dispatch(setErrorModalOpen(true));
      }
    } else if (
      destination.droppableId.replace('_temp', '') !==
      source.droppableId.replace('_temp', '')
    ) {
      // ------------------------
      // Move step between days
      // ------------------------
      gtag('event', 'move-step-between-days');

      const { newDays, destinationDayIndex } = moveStepBetweenDays(
        days,
        source,
        destination,
      );

      dispatch(setDays(newDays));
      dispatch(setDayLoadingIndex(destinationDayIndex));
      dispatch(setStepLoadingIndex(destination.index));

      try {
        const response = await moveStep({
          variables: {
            step_id: draggableId.replace('_temp', ''),
            new_day_id: destination.droppableId.replace('_temp', ''),
            original_day_id: source.droppableId.replace('_temp', ''),
            new_step_number: destination.index + 1,
          },
        });
        dispatch(setDays(response.data.moveStep.days));

        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving activity'));
        dispatch(setErrorModalOpen(true));
      }
    } else {
      gtag('event', 'invalid-move-operation');
    }
  }

  return (
    <>
      <MapDrawer
        tripType="user"
        onMarkerClick={handleMarkerClick}
        onCloseClick={handleCloseClick}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <HorizontalFlex>
          <Box sx={{ width: '100%' }}>
            {/* <CreateTripHeaderImages trip={trip} /> */}
            <Box
              sx={theme => ({
                position: 'relative',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                  marginLeft: -1,
                  marginTop: -1,
                },
              })}
            >
              <TripHeaderImages tripType="user" />
              <AddTripImagesModal tripId={trip.id} trip={trip} />
            </Box>
            <Box display="flex" flexDirection="row">
              {!isSmallScreen && (
                <Box
                  sx={theme => ({
                    display: 'block',
                    width: '50%',
                    [theme.breakpoints.down('sm')]: {
                      display: 'none',
                    },
                  })}
                >
                  <TripMap
                    tripType="user"
                    onMarkerClick={handleMarkerClick}
                    onCloseClick={handleCloseClick}
                  />
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                flex="1 0 0"
                alignItems="flex-start"
                justifyContent="flex-start"
                // maxWidth="55%"
                sx={theme => ({
                  paddingLeft: 1,

                  // zoom: 0.9,
                  maxWidth: '55%',
                  [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                    paddingLeft: 0,
                    maxWidth: '100%',
                  },
                })}
              >
                <CreateTripDetails
                  tripId={trip.id}
                  tripName={tripName}
                  tripDescription={tripDescription}
                  edit={true}
                  showTripActions={true}
                />

                <Box
                  sx={theme => ({
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                      // decrease space on left and add it to the width
                      width: 'calc(100% + 8px)',
                    },
                  })}
                >
                  <DraggableDroppableDays
                    showEmptyDay={false}
                    selectedStepId={selectedStepId}
                    stepRefs={stepRefs}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </HorizontalFlex>
      </DragDropContext>
    </>
  );
}

import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Typography,
  Stack,
  Chip,
  Link,
  SvgIconProps,
  Divider,
  SvgIcon,
  useTheme,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import {
  Maybe,
  Place,
  Step,
  Image,
  StepPlaceImage,
} from '../../generated/public_graphql';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
} from '../../store/StepSlice';
import { Body1, TitleH4, TitleH5 } from '../../theme-components/Typography';
import {
  formatNumber,
  getMapUrls,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import { ActionsStep } from '../create-trip/edit-trip/ActionsStep';
import { LikePlace } from '../favourite/likePlace';
import GoogleIcon from '../icons/GoogleIcon';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { ExternalLinkRegular } from '../trip-details/TripDay';

import { AddToTripButton } from './AddToTrip';
import { PlaceMoreInfoModal, TodaysHours } from './Hours';
import InstagramEmbedModal, {
  PhotosModal,
  TikTokEmbedModal,
  YouTubeEmbedModal,
} from './InstagramEmbeded';
import {
  StepPlaceImages,
  StepPlaceSingleImage,
  UserStepImages,
  UserStepSingleImage,
} from './StepImages';
import { SideScrollRecommendations } from './StepRecommendation';
import { LinkButton } from '../../theme-components/Buttons';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';

interface PlaceCardProps {
  step: Step;
  dayId?: string;
  selected?: boolean;
  tripId?: string;
  showEdit?: boolean;
  collapsedDetails?: boolean;
  tripType: string;
}

export const PublicPlaceCard: React.FC<PlaceCardProps> = ({
  step,
  dayId,
  selected,
  tripId,
  showEdit,
  collapsedDetails,
  tripType,
}) => {
  const dispatch = useDispatch();
  let place: Place | null = null;

  if (step && step.place) {
    place = step.place;
  }
  if (!step) {
    return <></>;
  }

  let customAccomodationImages: Maybe<Image>[] = [];
  if (
    step.accommodations &&
    step.accommodations[0] &&
    step.accommodations[0].images &&
    step.accommodations[0].images.length > 0
  ) {
    customAccomodationImages = step.accommodations[0].images;
  }

  let customStepImages: Maybe<Image>[] = [];
  if (step.images && step.images.length > 0) {
    customStepImages = step.images;
  }

  let placeImages: Maybe<StepPlaceImage>[] = [];
  if (step && step.placeImages && step.placeImages.length > 0) {
    placeImages = step.placeImages;
  }

  const { takeMeThereUrl, openGoogleMapsUrl } = getMapUrls(step);

  let moreInfoForPlace = false;
  if (
    step.place &&
    ((step.place.openingHours && step.place.openingHours.length > 0) ||
      step.place.website ||
      step.place.address ||
      step.place.phoneNumber)
  ) {
    moreInfoForPlace = true;
  }

  let instagramLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const instagramItem = step.socialLinks.find(
      item => item?.socialType === 'instagram',
    );
    if (instagramItem) {
      instagramLink = instagramItem.urlLink!;
    }
  }

  let tikTokLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const tikTokItem = step.socialLinks.find(
      item => item?.socialType === 'tiktok',
    );
    if (tikTokItem) {
      tikTokLink = tikTokItem.urlLink!;
    }
  }

  let youTubeLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const youTubeItem = step.socialLinks.find(
      item => item?.socialType === 'youtube',
    );
    if (youTubeItem) {
      youTubeLink = youTubeItem.urlLink!;
    }
  }

  return (
    <Stack
      direction="column"
      alignItems="left"
      sx={theme => ({
        width: '100%',
        paddingTop: 1.5,
        border: 2,
        borderColor: '#E4DBFF',
        paddingBottom: 2,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderRadius: '20px',
        backgroundColor: selected ? '#fff7e6' : '#f6f0ff',
        marginBottom: 0,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginBottom: 0,
        },
      })}
    >
      <Stack
        direction="row"
        alignItems="left"
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
          },
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            marginRight: 1,
            [theme.breakpoints.down('sm')]: {
              marginRight: 0.5,
            },
          })}
        >
          <PlaceTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              color: selected
                ? theme.palette.primary.main
                : theme.palette.icon.main,
              [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
              },
            })}
          />
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {step.title ? <TitleH4>{step.title}</TitleH4> : <Box />}
          <AuthorizedApolloProvider>
            {dayId ? (
              <Box>
                <AddToTripButton stepId={step.id} dayId={dayId} />
              </Box>
            ) : (
              <>
                {tripId && !collapsedDetails && (
                  <Box>
                    {showEdit && (
                      <ActionsStep
                        copy={'Edit'}
                        step={step}
                        tripId={tripId}
                        tripType={tripType}
                      />
                    )}
                  </Box>
                )}
              </>
            )}
          </AuthorizedApolloProvider>
        </Stack>
      </Stack>

      <Box
        display={collapsedDetails === true ? 'none' : undefined}
        sx={theme => ({
          position: 'relative',
          [theme.breakpoints.down('sm')]: {},
        })}
      >
        {place && (place.rating || place.noOfReviews) && (
          <Stack
            direction="row"
            sx={{
              alignItems: 'center', // Vertically center content
              justifyContent: 'space-between', // Horizontally center content
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
              <Box
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: '#AB88F3',
                  display: 'flex', // Enable flexbox layout
                  alignItems: 'center', // Vertically center content
                  justifyContent: 'center', // Horizontally center content
                  [theme.breakpoints.down('sm')]: {},
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.5,
                    width: 20,
                    height: 20,
                  },
                })}
              >
                {place.dataSource === 'BOOKING_DOT_COM' ? (
                  <CustomBookingComBlueIcon
                    sx={{ width: '100%', height: '100%' }}
                  />
                ) : (
                  <GoogleIcon />
                )}
              </Box>

              {place.rating && (
                <>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: '#E6B800',
                      fontSize: 18,
                      marginRight: 0.5,
                    }}
                  >
                    {formatNumber(place.rating)}
                  </Typography>
                  <StarIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 1,
                      color: '#E6B800',
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                </>
              )}
              {place.noOfReviews && (
                <Typography variant="body1">
                  ({place.rating ? place.noOfReviews : 'No reviews'})
                </Typography>
              )}
            </Stack>
          </Stack>
        )}

        {/* Operating Hours */}
        {place && place.openingHours && place.openingHours.length > 0 && (
          <TodaysHours place={place} />
        )}
        {place && place.checkInTime && (
          <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
            <HistoryToggleOffTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 1,
                color: theme.palette.icon.main,
                [theme.breakpoints.down('sm')]: {
                  marginRight: 0.5,
                  width: 20,
                  height: 20,
                },
              })}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
                alignSelf: 'center',
                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              Check In:
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                alignSelf: 'center',
                color: '#6E7191',
                marginRight: 0.5,
              }}
            >
              {place.checkInTime && place.checkInTime}
            </Typography>
          </Stack>
        )}
        {place && place.checkOutTime && (
          <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
            <UpdateTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 1,
                color: theme.palette.icon.main,
                [theme.breakpoints.down('sm')]: {
                  marginRight: 0.5,
                  width: 20,
                  height: 20,
                },
              })}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
                alignSelf: 'center',
                color: '#36454F',
                marginRight: 0.5,
              }}
            >
              Check Out:
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                alignSelf: 'center',
                color: '#6E7191',
                marginRight: 0.5,
              }}
            >
              {place.checkInTime && place.checkOutTime}
            </Typography>
          </Stack>
        )}

        {place && place.bookingLink && (
          <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
            <EventTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 1,
                color: theme.palette.icon.main,
                [theme.breakpoints.down('sm')]: {
                  width: 20,
                  height: 20,
                },
              })}
            />
            <LinkButton
              url={place.bookingLink}
              type="external"
              prominent={true}
            >
              Check availability
            </LinkButton>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" spacing={0.5} sx={{ mt: 1 }}>
          {place && place.bookingLink && (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(place!.bookingLink!);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                primary={true}
                mobileCopy="Open stay"
                desktopCopy="Open stay"
                icon={CustomBookingComWhiteIcon}
              />
            </Link>
          )}
          {takeMeThereUrl && (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(takeMeThereUrl);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                mobileCopy="Navigate"
                desktopCopy="Navigate"
                icon={DirectionsIcon}
              />
            </Link>
          )}
          {openGoogleMapsUrl && !place?.bookingLink && (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(openGoogleMapsUrl);
                gtag('event', 'public-link-maps-url');
              }}
            >
              <ResponsiveChip
                mobileCopy="Maps"
                desktopCopy="Google Maps"
                icon={MapIcon}
              />
            </Link>
          )}
          {moreInfoForPlace && (
            <Link
              onClick={() => {
                gtag('event', 'public-place-more-info-click');
                dispatch(setOpenHoursModal(true));
                dispatch(setPlaceIdForOpenHours(step.place!.id));
              }}
            >
              <ResponsiveChip
                mobileCopy="More"
                desktopCopy="More info"
                icon={InfoIcon}
              />
            </Link>
          )}
          {/* <AuthorizedApolloProvider>
            {place && <LikePlace placeId={place.id} />}
          </AuthorizedApolloProvider> */}
        </Stack>
        {place &&
          (place.rating ||
            place.noOfReviews ||
            moreInfoForPlace ||
            openGoogleMapsUrl ||
            takeMeThereUrl) && (
            <Box sx={{ mt: 1.5 }}>
              <Divider />
            </Box>
          )}
        {step.description &&
        !(
          step.accommodations &&
          step.accommodations[0] &&
          step.accommodations[0].description
        ) ? (
          <Box>
            {/* <Divider /> */}
            <Body1>
              <RenderHtmlFromResponseNoMargin
                content={step.description}
                limitToLines={3}
              />
            </Body1>
          </Box>
        ) : (
          step.accommodations &&
          step.accommodations[0] &&
          step.accommodations[0].description && (
            <Box>
              {/* <Divider /> */}
              <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={step.accommodations[0].description}
                  limitToLines={3}
                />
              </Body1>
            </Box>
          )
        )}
        {/* // todo hide when no socials */}
        {(instagramLink || tikTokLink || youTubeLink) && (
          <>
            <TitleH5>Check out on</TitleH5>
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ mb: 1, mt: 0.5, overflow: 'hidden' }}
            >
              {step && step.placeImages && step.placeImages.length > 0 && (
                <PhotosModal images={step.placeImages} />
              )}
              {instagramLink && <InstagramEmbedModal url={instagramLink} />}
              {tikTokLink && <TikTokEmbedModal url={tikTokLink} />}
              {youTubeLink && <YouTubeEmbedModal url={youTubeLink} />}
            </Stack>
          </>
        )}

        {step.url && <ExternalLinkRegular url={step.url} copy={'Read more'} />}
        <Box>
          {customAccomodationImages && customAccomodationImages.length > 0 && (
            <>
              {customAccomodationImages.length > 1 ? (
                <UserStepImages images={customAccomodationImages} />
              ) : (
                <UserStepSingleImage images={customAccomodationImages} />
              )}
            </>
          )}
          {customStepImages.length > 0 &&
            customAccomodationImages.length === 0 && (
              <>
                {customStepImages.length > 1 ? (
                  <UserStepImages images={customStepImages} />
                ) : (
                  <UserStepSingleImage images={customStepImages} />
                )}
              </>
            )}
          {customStepImages.length === 0 &&
            customAccomodationImages.length === 0 &&
            placeImages.length > 0 && (
              <>
                {placeImages.length > 1 ? (
                  <StepPlaceImages images={placeImages} />
                ) : (
                  <StepPlaceSingleImage images={placeImages} />
                )}
              </>
            )}

          {step.place && <PlaceMoreInfoModal place={step.place} />}
        </Box>
        {step.recommendations && step.recommendations.length > 0 && (
          <SideScrollRecommendations recommentations={step.recommendations} />
        )}
        <div
          style={{
            position: 'absolute', // Position absolutely inside the parent
            top: '5px', // Place it at the top
            right: '0', // Place it at the right
            zIndex: 1,
          }}
        >
          <AuthorizedApolloProvider>
            {place && <LikePlace placeId={place.id} />}
          </AuthorizedApolloProvider>
        </div>
      </Box>
    </Stack>
  );
};

interface ResponsiveChipProps {
  mobileCopy: string;
  desktopCopy: string;
  small?: boolean;
  primary?: boolean;
  borderOnly?: boolean;
  icon?: React.ComponentType<SvgIconProps>; // Icon component type
}

export const ResponsiveChip: React.FC<ResponsiveChipProps> = ({
  mobileCopy,
  desktopCopy,
  small = false,
  primary = false,
  borderOnly = false,
  icon: IconComponent = InfoIcon,
}) => {
  const theme = useTheme();
  const backgroundColour = primary ? theme.palette.primary.main : '#e4d9ff';
  return (
    <Chip
      label={
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <IconComponent
            sx={theme => ({
              width: small ? 18 : 20,
              height: small ? 18 : 20,
              transition: 'color 0.3s ease',
              // color: '#6E7191',
              color: '#36454F',
              [theme.breakpoints.down('sm')]: {
                width: small ? 16 : 18,
                height: small ? 16 : 18,
              },
            })}
          />

          <Box
            sx={theme => ({
              display: 'block',
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            })}
          >
            {mobileCopy}
          </Box>
          <Box
            sx={theme => ({
              display: 'block',
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          >
            {desktopCopy}
          </Box>
        </Stack>
      }
      variant="outlined"
      clickable
      sx={theme => ({
        fontWeight: 600,
        textDecoration: 'none',
        // backgroundColor: '#F5F5F5',
        backgroundColor: borderOnly ? 'transparent' : backgroundColour,
        // borderColor: borderOnly ? '#cbb8ff' : 'none',
        // borderColor: borderOnly ? '#36454F' : 'none',
        border: borderOnly ? '2px solid #cbb8ff' : 0,

        borderRadius: '16px',
        padding: small ? '4px 6px' : '6px 8px',
        boxShadow: 'none',

        fontSize: small ? 12 : 14,
        color: primary ? 'white' : '#36454F',
        transition: 'background-color 0.3s ease, border-color 0.3s ease',
        '&:hover': {
          backgroundColor: primary
            ? '#3F18B0 !important'
            : '#cbb8ff !important',
          // borderColor: '#E0E0E0',
          textDecoration: 'none',
          color: primary ? 'white' : '#36454F',
        },
        [theme.breakpoints.down('sm')]: {
          padding: small ? '4px 6px' : '6px 8px',
        },
      })}
    />
  );
};

// const BookingShareIcon = () => {
//   return (
//     <Box display="flex" flexDirection="column" alignItems="center">
//       <IconButton
//         sx={{
//           width: 25,
//           height: 25,
//           borderRadius: '50%',
//           // backgroundColor: '#f0f4ff',
//           color: '#1e88e5',
//           // border: '1px solid #1e88e5',
//           // '&:hover': {
//           //   backgroundColor: '#e3f2fd',
//           // },
//         }}
//       >
//         <CustomBookingComIcon
//           sx={{
//             width: 20,
//             height: 20,
//           }}
//         />
//       </IconButton>
//     </Box>
//   );
// };

const CustomBookingComWhiteIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#FFFFFF" />{' '}
      {/* White circular background */}
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        fontFamily="Arial, sans-serif"
        fontSize="16"
        fontWeight="bold"
        fill="#003580"
        dominantBaseline="middle"
      >
        B.
      </text>
    </SvgIcon>
  );
};

const CustomBookingComBlueIcon = props => {
  return (
    <SvgIcon {...props} height="20" width="20" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#003580" />{' '}
      {/* Blue circular background */}
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        fontFamily="Arial, sans-serif"
        fontSize="16"
        fontWeight="bold"
        fill="#FFFFFF"
        dominantBaseline="middle"
      >
        B.
      </text>
    </SvgIcon>
  );
};

import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Img } from 'react-image';

import { LoaderCentred } from '../../theme-components/Loader';
import { TitleH1 } from '../../theme-components/Typography';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { QUERY_GET_COUNTRY } from '../gql-public/countryQuery';
import { TripCountryBox } from '../trip-details/TripCountryBox';
import { SideBarIcon } from '../trip-details/TripSidebar';

import { CountryInfoLinks } from './CountryInfo';

export function CountryHeader(props: { countryId: string }) {
  const { data, error, loading } = useQuery(QUERY_GET_COUNTRY, {
    variables: { country_id: props.countryId },
  });

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack direction={'row'}>
        <SideBarIcon $iconType={data.country.iso2Code} />
        <TitleH1>{data.country.name} </TitleH1>
      </Stack>
      {data.country.image && (
        <Box
          sx={theme => ({
            objectFit: 'cover',
            borderRadius: '5px',
            width: '100%',
            height: 420,
            [theme.breakpoints.down('sm')]: {
              height: 320,
            },
            [theme.breakpoints.down('sm')]: {
              height: 200,
            },
          })}
        >
          <Img
            src={''}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensures the image fills the box without distortion
              borderRadius: 'inherit', // Inherit border radius from the parent Box
            }}
            loader={
              <Skeleton
                variant="rectangular"
                sx={theme => ({
                  objectFit: 'cover',
                  borderRadius: '10px',
                  width: '100%',
                  height: 420,
                  [theme.breakpoints.down('sm')]: {
                    height: 320,
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 200,
                  },
                })}
              />
            }
          />
        </Box>
      )}
      <Box sx={{ width: '100%' }}>
        {loading && <LoaderCentred />}
        {!loading && !error && (
          <>
            <Box sx={{ width: '100%', display: { xs: 'none', sm: 'block' } }}>
              <CountryInfoLinks country={data.country} />
            </Box>

            <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 2 }}>
              <TripCountryBox country={data.country} />
            </Box>
          </>
        )}
      </Box>
    </Stack>
  );
}

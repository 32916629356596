import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Trip } from '../generated/user_graphql';

export interface TripState {
  trip: Trip | null;
  userTrips: Trip[] | null;
  loading: boolean;
  errors: string;
  tripName: string;
  tripDescription: string;
}

const initialState: TripState = {
  trip: null,
  userTrips: null,
  loading: false,
  errors: '',
  tripName: '',
  tripDescription: '',
};

const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },

    setTrip: (state, { payload }: PayloadAction<Trip>) => {
      state.trip = payload;
    },

    setUserTrips: (state, { payload }: PayloadAction<Trip[]>) => {
      state.userTrips = payload;
    },

    setTripName: (state, { payload }: PayloadAction<string>) => {
      state.tripName = payload;
    },

    setTripDescription: (state, { payload }: PayloadAction<string>) => {
      state.tripDescription = payload;
    },
  },
});

export const {
  setLoading,
  setErrors,
  setTrip,
  setUserTrips,
  setTripName,
  setTripDescription,
} = tripSlice.actions;

export const tripSliceReducer = tripSlice.reducer;

export const tripsSelector = (state: { userTrip: TripState }) => state.userTrip;

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Img } from 'react-image';
import { useSelector } from 'react-redux';

import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';

type TripType = 'public' | 'user' | 'preview';

type TripHeaderProps = {
  tripType: TripType;
};

export function TripHeaderImages({ tripType }: TripHeaderProps) {
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);

  const trip = tripType === 'user' ? userTrip : publicTrip;

  let firstImage,
    lastImage,
    middleImageOne,
    middleImageTwo: string | null | undefined = null;

  if (trip?.images && trip.images.length > 0) {
    // Find the index of the main image, if it exists
    const mainImageIndex = trip.images.findIndex(image => image?.main);

    // Set the main image as firstImage if it exists
    if (mainImageIndex !== -1) {
      firstImage = trip.images[mainImageIndex]?.path;

      // Create a new array excluding the main image for the other slots
      const remainingImages = [
        ...trip.images.slice(0, mainImageIndex),
        ...trip.images.slice(mainImageIndex + 1),
      ];

      // Assign other images based on remainingImages
      middleImageOne = remainingImages[0]?.path;
      middleImageTwo = remainingImages[1]?.path;
      lastImage = remainingImages[2]?.path;
    } else {
      // If no main image, assign images in order
      firstImage = trip.images[0]?.path;
      middleImageOne = trip.images[1]?.path;
      middleImageTwo = trip.images[2]?.path;
      lastImage = trip.images[3]?.path;
    }
  }

  return (
    <Box
      sx={theme => ({
        position: 'relative',
        width: '100%',
        paddingTop: 2,
        [theme.breakpoints.down('sm')]: {
          width: '100vw',
          paddingTop: 0,
        },
      })}
    >
      {trip && trip.images && trip.images && trip.images.length > 0 ? (
        <>
          {firstImage && middleImageOne && middleImageTwo && lastImage && (
            <Box
              sx={theme => ({
                marginBottom: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', // Ensures images are centered horizontally
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  marginTop: 1,
                },
                [theme.breakpoints.down('sm')]: {
                  marginTop: 0,
                },
              })}
            >
              <Box
                sx={theme => ({
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  width: '100%',
                  marginRight: 0.5,
                  height: 380,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.25,
                    height: 280,
                    borderRadius: 0,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    width: '100%',
                    height: 380,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      width: '100%',
                      height: 380,
                      [theme.breakpoints.down('md')]: {
                        height: 325,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      src={firstImage}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                            width: '100%',
                            height: 380,
                            [theme.breakpoints.down('md')]: {
                              height: 325,
                            },
                            [theme.breakpoints.down('sm')]: {
                              borderRadius: 0,
                              height: 280,
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={theme => ({
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginRight: 0.5,
                  width: '30%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 380,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '40%',
                    borderRadius: 0,
                    marginRight: 0,
                    height: 280,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    maxWidth: 300,
                    width: '100%',
                    marginBottom: 0.5,
                    height: 200,
                    [theme.breakpoints.down('md')]: {
                      height: 180,
                    },
                    [theme.breakpoints.down('sm')]: {
                      marginBottom: 0.25,
                      borderRadius: 0,
                      height: 148,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={middleImageOne}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          maxWidth: 300,
                          width: '100%',
                          marginBottom: 0.5,
                          height: 200,
                          [theme.breakpoints.down('md')]: {
                            height: 180,
                          },
                          [theme.breakpoints.down('sm')]: {
                            borderRadius: 0,
                            height: 148,
                          },
                        })}
                      />
                    }
                  />
                </Box>
                <Box
                  sx={theme => ({
                    maxWidth: 300,
                    width: '100%',
                    height: 176,
                    [theme.breakpoints.down('md')]: {
                      height: 145,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 130,
                      borderRadius: 0,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={middleImageTwo}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          maxWidth: 300,
                          width: '100%',
                          height: 176,
                          [theme.breakpoints.down('md')]: {
                            height: 145,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 130,
                            borderRadius: 0,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={theme => ({
                  display: { xs: 'none', sm: 'block' },
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  width: '40%',
                  height: 380,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    borderRadius: 0,
                    height: 280,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    width: '100%',
                    height: 380,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={lastImage}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          width: '100%',
                          height: 380,
                          [theme.breakpoints.down('md')]: {
                            height: 325,
                          },
                          [theme.breakpoints.down('sm')]: {
                            borderRadius: 0,
                            height: 280,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
            // </Box>
          )}
          {firstImage && middleImageOne && middleImageTwo && !lastImage && (
            <Box
              sx={{
                marginTop: 1,
                marginBottom: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', // Ensures images are centered horizontally
                alignItems: 'center',
              }}
            >
              <Box
                sx={theme => ({
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                  width: '100%',
                  marginRight: 0.5,
                  height: 380,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    borderRadius: 0,
                    height: 280,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    width: '100%',
                    height: 380,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={firstImage}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                          width: '100%',
                          height: 380,
                          [theme.breakpoints.down('md')]: {
                            height: 325,
                          },
                          [theme.breakpoints.down('sm')]: {
                            borderRadius: 0,
                            height: 280,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={theme => ({
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                  width: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 380,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    borderRadius: 0,
                    height: 280,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '10px',
                    width: '100%',
                    height: 200,
                    marginBottom: 0.5,
                    [theme.breakpoints.down('md')]: {
                      height: 175,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 140,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={middleImageOne}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderTopRightRadius: '10px',
                          width: '100%',
                          height: 200,
                          marginBottom: 0.5,
                          [theme.breakpoints.down('md')]: {
                            height: 175,
                          },
                          [theme.breakpoints.down('sm')]: {
                            borderRadius: 0,
                            height: 140,
                          },
                        })}
                      />
                    }
                  />
                </Box>
                <Box
                  sx={theme => ({
                    borderBottomRightRadius: '10px',
                    width: '100%',
                    height: 175,
                    [theme.breakpoints.down('md')]: {
                      height: 145,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 136,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={middleImageTwo}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderBottomRightRadius: '10px',
                          width: '100%',
                          height: 175,
                          [theme.breakpoints.down('md')]: {
                            height: 145,
                          },
                          [theme.breakpoints.down('sm')]: {
                            borderRadius: 0,
                            height: 136,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
          {firstImage && middleImageOne && !middleImageTwo && !lastImage && (
            <>
              <Box
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center', // Ensures images are centered horizontally
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    width: '100%',
                    marginRight: 0.5,
                    height: 350,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      width: '100%',
                      height: 350,
                      [theme.breakpoints.down('md')]: {
                        height: 325,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      src={firstImage}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                            width: '100%',
                            height: 350,
                            [theme.breakpoints.down('md')]: {
                              height: 325,
                            },
                            [theme.breakpoints.down('sm')]: {
                              borderRadius: 0,
                              height: 280,
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    width: '45%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                      width: '100%',
                      height: 350,
                      [theme.breakpoints.down('md')]: {
                        height: 325,
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: 280,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      src={middleImageOne}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            width: '100%',
                            height: 350,
                            [theme.breakpoints.down('md')]: {
                              height: 325,
                            },
                            [theme.breakpoints.down('sm')]: {
                              borderRadius: 0,
                              height: 280,
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {firstImage && !lastImage && !middleImageOne && !middleImageTwo && (
            <>
              <Box
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center', // Ensures images are centered horizontally
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={theme => ({
                    borderRadius: '10px',
                    width: '100%',
                    height: 350,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      borderRadius: 0,
                      height: 280,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderRadius: '10px',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      [theme.breakpoints.down('md')]: {
                        height: '100%', // Stretch to fill the available height
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0,
                        height: '100%', // Same for small screens
                      },
                    })}
                  >
                    <Img
                      src={firstImage}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Ensures the image fills the box without distortion
                            [theme.breakpoints.down('md')]: {
                              height: '100%', // Stretch to fill the available height
                            },
                            [theme.breakpoints.down('sm')]: {
                              borderRadius: 0,
                              height: '100%', // Same for small screens
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box
            sx={{
              marginTop: 1,
              marginBottom: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center', // Ensures images are centered horizontally
              alignItems: 'center',
            }}
          >
            <Box
              sx={theme => ({
                width: '100%',
                height: 350,
                // overflow: 'hidden',
                [theme.breakpoints.down('md')]: {
                  height: 325,
                },
                [theme.breakpoints.down('sm')]: {
                  borderRadius: 0,
                  height: 280,
                },
              })}
            >
              <Box
                sx={theme => ({
                  borderRadius: '10px',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Ensures the image fills the box without distortion
                  [theme.breakpoints.down('md')]: {
                    height: '100%', // Stretch to fill the available height
                  },
                  [theme.breakpoints.down('sm')]: {
                    borderRadius: 0,
                    height: '100%', // Same for small screens
                  },
                })}
              >
                <Img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensures the image fills the box without distortion
                    borderRadius: 'inherit', // Inherit border radius from the parent Box
                  }}
                  src={'/assets/image/placeholder-svg.svg'}
                  loader={
                    <Skeleton
                      variant="rectangular"
                      sx={theme => ({
                        borderRadius: '10px',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        [theme.breakpoints.down('md')]: {
                          height: '100%', // Stretch to fill the available height
                        },
                        [theme.breakpoints.down('sm')]: {
                          borderRadius: 0,
                          height: '100%', // Same for small screens
                        },
                      })}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

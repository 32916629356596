import { gql } from 'graphql-tag';

export const MUTATION_DELETE_USER_IMAGE = gql`
  mutation deleteUserImage($id: String!) {
    deleteUserImage(id: $id) {
      id
    }
  }
`;

export const MUTATION_DELETE_STEP_PLACE_IMAGE = gql`
  mutation deleteStepPlaceImage($step_place_image_id: String!) {
    deleteStepPlaceImage(step_place_image_id: $step_place_image_id) {
      id
    }
  }
`;

export const MUTATION_SET_TRIP_MAIN_IMAGE = gql`
  mutation setMainTripImage($image_id: String!, $trip_id: String!) {
    setMainTripImage(image_id: $image_id, trip_id: $trip_id) {
      id
      name
      description
      length
      archived
      startDate
      published
      tripType
      publish_pending
      tags {
        id
        name
      }
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        countryName
        itineraryId
      }
      cities {
        id
        name
      }
      images {
        id
        path
        name
        header
        main
        secondary
        tertiary
      }
      itinerary {
        id
        days {
          id
          dayNumber
          cities {
            cityName
          }
          steps {
            id
            title
            type
            description
            stepNumber
            latitude
            longitude
            locationUrl
            duration
            url
            city
            country
            countryCode
            region
            tags {
              id
              name
            }
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
            socialLinks {
              id
              socialType
              urlLink
              hashtag
              searchTerm
            }
            placeCategory
            placeImages {
              path
              id
              title
              contributorId
              imageUrl
            }
            place {
              id
              lat
              lng
              city
              country
              countryCode
              region
              externalPlaceId
              title
              category
              noOfReviews
              rating
              description
              quickFact
              phoneNumber
              website
              address
              aiDescription
              tags {
                id
                name
              }
              checkInTime
              checkOutTime
              placeType
              dataSource
              bookingLink
              openingHours {
                weekDay
                hours
              }
              images {
                title
                path
                contributorId
                imageUrl
              }
            }
            accommodations {
              id
              name
              description
              url
              budget
              latitude
              longitude
              locationUrl
              images {
                id
                path
                header
                main
                secondary
                tertiary
              }
            }
            recommendations {
              id
              name
              type
              description
              url
              latitude
              longitude
              locationUrl
            }
          }
        }
        coordinates {
          id
          title
          latitude
          longitude
          step {
            id
            title
            type
            description
            stepNumber
            latitude
            longitude
            locationUrl
            duration
            url
            city
            country
            countryCode
            region
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
            socialLinks {
              id
              socialType
              urlLink
              hashtag
              searchTerm
            }
            placeCategory
            placeImages {
              path
              id
              title
              contributorId
              imageUrl
            }
            place {
              id
              lat
              lng
              city
              country
              countryCode
              region
              externalPlaceId
              title
              category
              noOfReviews
              rating
              description
              quickFact
              phoneNumber
              website
              address
              aiDescription
              checkInTime
              checkOutTime
              placeType
              dataSource
              bookingLink
              openingHours {
                weekDay
                hours
              }
              images {
                title
                path
                contributorId
                imageUrl
              }
            }
            accommodations {
              id
              name
              description
              url
              budget
              latitude
              longitude
              locationUrl
              images {
                id
                path
                header
                main
                secondary
                tertiary
              }
            }
            recommendations {
              id
              name
              type
              description
              url
              latitude
              longitude
              locationUrl
            }
          }
        }
      }
    }
  }
`;

import { Box } from '@mui/system';

import { FavoriteBorderIcon, FavoriteIcon } from './Icons';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { IconButton } from '@mui/material';

interface LabelProps {
  backgroundColor?: string; // Background color for the label
  top?: string; // Optional top position
  right?: string; // Optional right position
  text: string; // Text to display in the label
}

export const CardLabel: React.FC<LabelProps> = ({
  backgroundColor = 'rgba(255, 255, 255, 0.7)',
  top = '10px',
  right = '10px',
  text,
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: top, // Set top position from props
        right: right, // Set right position from props
        backgroundColor: backgroundColor, // Set background color from props
        borderRadius: '20px',
        backdropFilter: 'blur(3px)', // Blur effect on the background
        padding: '4px 8px',
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'black',
      }}
    >
      {text}
    </Box>
  );
};

interface CardLikeProps {
  liked: boolean;
  backgroundColor?: string;
  top?: string;
  left?: string;
}

export const CardLike: React.FC<CardLikeProps> = ({
  liked,
  top = '10px',
  left = '10px',
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: top,
        left: left,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '100px',
        padding: '3px 3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backdropFilter: 'blur(8px)',
      }}
    >
      {liked ? (
        <FavoriteIcon color="error" sx={{ marginBottom: '-1px' }} />
      ) : (
        <FavoriteBorderIcon color="error" sx={{ marginBottom: '-1px' }} />
      )}
    </Box>
  );
};

interface CardStarProps {
  main: boolean;
  backgroundColor?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
}

export const CardStar: React.FC<CardStarProps> = ({
  main,
  top = 'none',
  left = 'none',
  bottom = 'none',
  right = 'none',
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: top,
        left: left,
        bottom: bottom,
        right: right,
        width: 39,
        height: 39,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '20px',
        padding: '3px 3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backdropFilter: 'blur(8px)',
        zIndex: 2,
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          bottom: 4,
          right: 4,
          zIndex: 3,
          color: '#FFFFFF',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <>
          {main ? (
            <StarIcon sx={{ color: '#FFFFF', height: 15, width: 15 }} />
          ) : (
            <StarBorderIcon sx={{ color: '#FFFFF', height: 15, width: 15 }} />
          )}
        </>
      </IconButton>
    </Box>
  );
};

export const PlaceLike: React.FC<CardLikeProps> = ({ liked }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#36454F',
        borderRadius: '100px',
        padding: '3px 3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      {liked ? (
        <FavoriteIcon color="error" sx={{ marginBottom: '-1px' }} />
      ) : (
        <FavoriteBorderIcon color="error" sx={{ marginBottom: '-1px' }} />
      )}
    </Box>
  );
};

import React from 'react';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';

interface SpaceProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  direction?: 'horizontal' | 'vertical';
}

const Space: React.FC<SpaceProps> = ({
  size = 'sm',
  direction = 'vertical',
}) => {
  const theme = useTheme();

  // Define the spacing values based on theme spacing scale
  const getSizeValue = (size: SpaceProps['size'], theme: Theme) => {
    switch (size) {
      case 'xs':
        return theme.spacing(0.5);
      case 'sm':
        return theme.spacing(1);
      case 'md':
        return theme.spacing(2);
      case 'lg':
        return theme.spacing(3);
      case 'xl':
        return theme.spacing(4);
      default:
        return theme.spacing(1);
    }
  };

  const spacingValue = getSizeValue(size, theme);

  return (
    <Box
      sx={{
        width: direction === 'horizontal' ? spacingValue : '100%',
        height: direction === 'vertical' ? spacingValue : '100%',
      }}
    />
  );
};

export default Space;

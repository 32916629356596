import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Trip } from '../generated/public_graphql';

export interface PublicTripState {
  publicTrip: Trip | null;
  publicTripLoading: boolean;
  publicTripErrors: string;
}

const initialState: PublicTripState = {
  publicTrip: null,
  publicTripLoading: false,
  publicTripErrors: '',
};

const publicTripSlice = createSlice({
  name: 'publicTrip',
  initialState,
  reducers: {
    setPublicTripLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.publicTripLoading = payload;
    },

    setPublicTripErrors: (state, { payload }: PayloadAction<string>) => {
      state.publicTripErrors = payload;
    },

    setPublicTrip: (state, { payload }: PayloadAction<Trip>) => {
      state.publicTrip = payload;
    },
  },
});

export const { setPublicTripLoading, setPublicTripErrors, setPublicTrip } =
  publicTripSlice.actions;

export const publicTripSliceReducer = publicTripSlice.reducer;

export const publicTripsSelector = (state: { publicTrip: PublicTripState }) =>
  state.publicTrip;

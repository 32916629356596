import React, { Component, ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { setErrorModalOpen, setErrorTitle } from '../store/ErrorSlice';

interface Props {
  children: React.ReactNode;
  setErrorModalOpen: (open: boolean) => void;
  setErrorTitle: (title: string) => void;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class GlobalErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error caught by GlobalErrorBoundary: ', error, errorInfo);
    this.props.setErrorTitle(error.message); // Set error title in Redux
    this.props.setErrorModalOpen(true); // Show the error modal
  }

  render() {
    if (this.state.hasError) {
      // Error has been caught, so return nothing (modal will show)
      return null;
    }

    return this.props.children;
  }
}

const mapDispatchToProps = {
  setErrorModalOpen,
  setErrorTitle,
};

export default connect(null, mapDispatchToProps)(GlobalErrorBoundary);

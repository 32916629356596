import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../auth/firebase';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { User } from '../../generated/user_graphql';
import { MUTATION_LIKE_PLACE } from '../gql-user/user';
import { setUser, userSelector } from '../../store/UserSlice';
import { Box } from '@mui/system';
import { Loader } from '../../theme-components/Loader';
import { PlaceLike } from '../../theme-components/Lables';
import SignUpModal from '../auth/SignUpModal';

export function LikePlace(props: { placeId: string }) {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [likedPlaces, setLikedPlaces] = useState<string[]>([]);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const handleLike = async () => {
    await likePlace();
  };

  const [likePlace, { loading, data }] = useMutation<{ likePlace: User }>(
    MUTATION_LIKE_PLACE,
    {
      variables: {
        place_id: props.placeId,
        is_liking: !likedPlaces.includes(props.placeId),
      },
    },
  );

  const { myUser } = useSelector(userSelector);

  useEffect(() => {
    if (myUser?.likedPlaces) {
      const uniqueLikedPlaces = new Set<string>();
      myUser.likedPlaces.forEach(place => {
        if (place?.placeId) uniqueLikedPlaces.add(place.placeId);
      });
      setLikedPlaces(Array.from(uniqueLikedPlaces));
    }
  }, [myUser]);

  useEffect(() => {
    if (data && data.likePlace) {
      dispatch(setUser(data.likePlace));
    }
  }, [data]);

  return (
    <Box>
      <Box
        onClick={event => {
          event.stopPropagation();
          if (isAuthenticated) {
            handleLike();
          } else {
            handleOpenSignUp();
          }
        }}
      >
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: '10px', // Set top position from props
              left: '10px', // Set right position from props
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Set background color from props
              borderRadius: '100px',
              backdropFilter: 'blur(3px)', // Blur effect on the background
              padding: '3px 3px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer', // Make it clickable
            }}
          >
            <Loader />
          </Box>
        ) : (
          <>
            <PlaceLike liked={likedPlaces.includes(props.placeId)} />
          </>
        )}
      </Box>
      <SignUpModal open={isSignUpModalOpen} onClose={handleCloseSignUp} />
    </Box>
  );
}

import { Box, useMediaQuery, useTheme } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // Toggle styles
    [{ color: [] }], // Text color and background color
    // [{ script: 'sub' }, { script: 'super' }], // Subscript/Superscrip
    [{ list: 'ordered' }, { list: 'bullet' }], // Ordered/Unordered lists
    [{ indent: '-1' }, { indent: '+1' }], // Indent/Outdent
    [{ align: [] }], // Text alignments
    ['blockquote', 'code-block'], // Blockquote and code block
    ['link'], // Insert link
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'background',
  'align',
  'direction',
  'code-block',
  'script',
];

// The reusable Quill Editor component
const MyEditor = ({
  value,
  onChange,
  placeholder = 'Start typing here...',
}) => {
  // this is used as the editor header is not counted in height and so it is weird with content
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(566));

  // let height = 120;

  // if (isSmallScreen) {
  //   height = 120;
  // }

  // if (isLargeScreen) {
  //   height = 120;
  // }

  return (
    <Box
      sx={theme => ({
        height: '202px',
        backgroundColor: '#fafafa',
        [theme.breakpoints.down(566)]: { height: '190px' },
      })}
    >
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        style={{
          height: isSmallScreen ? 120 : 160,
          backgroundColor: '#fafafa',
        }}
      />
    </Box>
  );
};

export default MyEditor;

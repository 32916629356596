import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StepsState {
  stepId: string;
  stepDescription: string;
  stepLatitude: number;
  stepLongitude: number;
  stepLocationUrl: string;
  stepMoreInfoUrl: string;
  stepDuration: number;
  stepDescriptionAdded: boolean;
  stepLocationAdded: boolean;
  stepAddStepModalOpen: boolean;
  openHoursModal: boolean;
  placeIdForOpenHours: string;
  stepDayId: string;
  stepCategory: string;
  stepType: string;
  stepTitle: string;
}

const initialState: StepsState = {
  stepId: '',
  stepDescription: '',
  stepLatitude: 0,
  stepLongitude: 0,
  stepLocationUrl: '',
  stepMoreInfoUrl: '',
  stepDuration: 0,
  stepDescriptionAdded: false,
  stepLocationAdded: false,
  stepAddStepModalOpen: false,
  openHoursModal: false,
  placeIdForOpenHours: '',
  stepDayId: '',
  stepCategory: '',
  stepType: '',
  stepTitle: '',
};

const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    resetStep: () => initialState,
    setStepId: (state, { payload }: PayloadAction<string>) => {
      state.stepId = payload;
    },
    setStepDescription: (state, { payload }: PayloadAction<string>) => {
      state.stepDescription = payload;
    },
    setStepLocationUrl: (state, { payload }: PayloadAction<string>) => {
      state.stepLocationUrl = payload;
    },
    setStepMoreInfoUrl: (state, { payload }: PayloadAction<string>) => {
      state.stepMoreInfoUrl = payload;
    },
    setStepLatitude: (state, { payload }: PayloadAction<number>) => {
      state.stepLatitude = payload;
    },
    setStepLongitude: (state, { payload }: PayloadAction<number>) => {
      state.stepLongitude = payload;
    },
    setStepDuration: (state, { payload }: PayloadAction<number>) => {
      state.stepDuration = payload;
    },
    setStepDescriptionAdded: (state, { payload }: PayloadAction<boolean>) => {
      state.stepDescriptionAdded = payload;
    },
    setStepLocationAdded: (state, { payload }: PayloadAction<boolean>) => {
      state.stepLocationAdded = payload;
    },
    setAddStepModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.stepAddStepModalOpen = payload;
    },
    setOpenHoursModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openHoursModal = payload;
    },
    setPlaceIdForOpenHours: (state, { payload }: PayloadAction<string>) => {
      state.placeIdForOpenHours = payload;
    },
    setStepDayId: (state, { payload }: PayloadAction<string>) => {
      state.stepDayId = payload;
    },
    setStepCategory: (state, { payload }: PayloadAction<string>) => {
      state.stepCategory = payload;
    },
    setStepType: (state, { payload }: PayloadAction<string>) => {
      state.stepType = payload;
    },
    setStepTitle: (state, { payload }: PayloadAction<string>) => {
      state.stepTitle = payload;
    },
  },
});

export const {
  resetStep,
  setStepId,
  setStepDescription,
  setStepLocationUrl,
  setStepMoreInfoUrl,
  setStepLatitude,
  setStepLongitude,
  setStepDuration,
  setStepDescriptionAdded,
  setStepLocationAdded,
  setAddStepModalOpen,
  setOpenHoursModal,
  setPlaceIdForOpenHours,
  setStepDayId,
  setStepCategory,
  setStepType,
  setStepTitle,
} = stepSlice.actions;

export default stepSlice.reducer;

export const stepsSelector = (state: { userAddingStep: StepsState }) =>
  state.userAddingStep;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Day, Maybe } from '../generated/user_graphql';

export interface DaysState {
  days: Maybe<Day>[];
  loading: boolean;
  errors: string;
  dayLoadingIndex: number;
  stepLoadingIndex: number;
}

const initialState: DaysState = {
  days: [],
  loading: false,
  errors: '',
  dayLoadingIndex: -1,
  stepLoadingIndex: -1,
};

const daySlice = createSlice({
  name: 'day',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },

    setDays: (state, { payload }: PayloadAction<Maybe<Day>[]>) => {
      state.days = payload;
    },

    setDayLoadingIndex: (state, { payload }: PayloadAction<number>) => {
      state.dayLoadingIndex = payload;
    },

    setStepLoadingIndex: (state, { payload }: PayloadAction<number>) => {
      state.stepLoadingIndex = payload;
    },
  },
});

export const {
  setLoading,
  setErrors,
  setDays,
  setDayLoadingIndex,
  setStepLoadingIndex,
} = daySlice.actions;

export default daySlice.reducer;

export const daysSelector = (state: { userTripDays: DaysState }) =>
  state.userTripDays;

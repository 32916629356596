import { useMutation } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { setTrip } from '../../../store/TripSlice';
import { PrimaryButton, TextButton } from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { returnFormattedDate } from '../../../utils/helpers';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import { VerticalFlex } from '../../helpers/flex';
import { CenteredModal, ModalPaper, ModalTitle } from '../../styling/modal';
import ModalHeader from '../../../theme-components/ModalComponents';

export default function AddStartDateModal(props: {
  tripId: string;
  date: Date;
  add: boolean;
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [addStartDay, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: {
      startDate: selectedDate,
      trip_id: props.tripId,
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {props.add ? (
        <>
          <TextButton
            onClick={() => {
              gtag('event', 'add-trip-start-date-button');
              handleOpen();
            }}
          >
            When are you leaving?
          </TextButton>
        </>
      ) : (
        <Stack direction="row" alignItems="center" spacing={0.5} mb={1}>
          <Typography
            sx={theme => ({
              alignSelf: 'center',
              fontWeight: 600,
              fontSize: 16,
              color: theme.palette.secondary.dark,
              [theme.breakpoints.down('sm')]: {
                fontSize: 14,
              },
            })}
          >
            Leaving:
          </Typography>
          <Typography
            sx={theme => ({
              alignSelf: 'center',
              fontWeight: 500,
              fontSize: 16,
              color: theme.palette.secondary.main,
              [theme.breakpoints.down('sm')]: {
                fontSize: 14,
              },
            })}
          >
            {props.date && returnFormattedDate(props.date, 0)}
          </Typography>
          <Typography
            sx={theme => ({
              alignSelf: 'center',
              fontWeight: 500,
              fontSize: 16,
              color: theme.palette.primary.light,
              [theme.breakpoints.down('sm')]: {
                fontSize: 14,
              },
            })}
            onClick={() => {
              gtag('event', 'edit-trip-start-date-button');
              handleOpen();
            }}
          >
            Change
          </Typography>
        </Stack>
      )}
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader title="When are you leaving?" />
          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={selectedDate}
                onChange={(newValue: Date | null) => setSelectedDate(newValue)}
              />
            </LocalizationProvider>
            <VerticalFlex>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <PrimaryButton
                  disabled={loading}
                  onClick={async () => {
                    gtag('event', 'edit-start-date-save');
                    try {
                      const res = await addStartDay();
                      if (
                        res &&
                        res.data &&
                        res.data.updateUserTrip &&
                        res.data.updateUserTrip.itinerary &&
                        res.data.updateUserTrip.itinerary.days
                      ) {
                        dispatch(setTrip(res.data.updateUserTrip));
                        dispatch(
                          setDays(res.data.updateUserTrip.itinerary.days),
                        );
                      }
                      handleClose();
                    } catch (e) {
                      gtag('event', 'error-edit-start-date');
                      dispatch(setErrorTitle('Error saving start date'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                >
                  {loading ? <Loader /> : 'Save start date'}
                </PrimaryButton>
              </Box>
            </VerticalFlex>
          </Box>
        </ModalPaper>
      </CenteredModal>
    </>
  );
}

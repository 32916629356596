import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { accommodationsSelector } from '../../../../store/AccommodationSlice';
import { setAddStepModalOpen } from '../../../../store/StepSlice';
import { tripsSelector } from '../../../../store/TripSlice';
import { PrimaryButton } from '../../../../theme-components/Buttons';
import Space from '../../../../theme-components/Spacing';
import UploadImage from '../../../Upload';

export const AddPhotosToAccommodation: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  const { accommodationId } = useSelector(accommodationsSelector);
  const { trip } = useSelector(tripsSelector);

  return (
    <>
      <UploadImage
        tripId={trip ? trip.id : ''}
        accommodationId={accommodationId}
        main={true}
      />
      <Space size="md" />
      <PrimaryButton
        onClick={() => {
          gtag('event', 'add-accommodation-photos-finish');
          dispatch(setAddStepModalOpen(false));
          handleCloseModal();
        }}
      >
        Done
      </PrimaryButton>
    </>
  );
};

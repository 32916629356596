import { useQuery } from '@apollo/client';
import { Instagram, YouTube } from '@mui/icons-material';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import {
  Box,
  Avatar,
  Typography,
  Link,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { QUERY_USER_PUBLIC_TRIPS } from '../components/gql-public/userPublicTrips';
import {
  GradientContainer,
  OuterContainer,
} from '../components/home/HomeHeader';
import { RoutePage } from '../components/route';
import { FollowUser } from '../components/trip-details/TripDetails';
import { RenderTrips } from '../components/user-account/UserTrips';
import { UserPublicInfo } from '../generated/public_graphql';
import { useUserData } from '../hooks/useUserData';
import { TikTokIcon } from '../theme-components/Icons';
import { isRunningStandalone, openLinkInBrowserNewTab } from '../utils/helpers';

export default function UserPublicTrips() {
  useUserData();
  gtag('event', 'public-country-page');
  const { id } = useParams<{ id: string }>();
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { data, error, loading } = useQuery(QUERY_USER_PUBLIC_TRIPS, {
    variables: { user_id: id || '' }, // Use id if available
    skip: !id, // Skip query if id is not available
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (error) {
    return <>Error loading trips</>;
  }

  return (
    <RoutePage>
      <OuterContainer>
        <GradientContainer>
          {data &&
            data.userPublicTrips &&
            data.userPublicTrips &&
            data.userPublicTrips.publicUserInfo && (
              <UserProfile
                publicUserInfo={data.userPublicTrips.publicUserInfo}
              />
            )}
          {data && data.userPublicTrips && data.userPublicTrips.trips && (
            <RenderTrips trips={data.userPublicTrips.trips} publicTrip={true} />
          )}
          {isStandalone && (
            <Box
              sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))' }}
            />
          )}
        </GradientContainer>
      </OuterContainer>
    </RoutePage>
  );
}

interface UserProfileProps {
  publicUserInfo: UserPublicInfo;
}

const UserProfile: React.FC<UserProfileProps> = ({ publicUserInfo }) => {
  const {
    nickName,
    tikTokUrl,
    instagramUrl,
    youTubeUrl,
    profilePictureUrl,
    blogUrl,
  } = publicUserInfo;

  return (
    <Box
      width="100%"
      sx={{
        // width: 'fit-content', // Adjust width to content
        margin: '0 auto', // Center horizontally
        // width="100%"
        p: 2, // Padding around the box
        display: 'flex', // Use flexbox for layout
        alignItems: 'center', // Align items vertically centered
        justifyContent: 'center',
      }}
    >
      {/* Profile picture on the left */}
      <Avatar
        src={profilePictureUrl ?? undefined} // Use profilePictureUrl if available
        alt={nickName ?? undefined} // Alt text for avatar
        sx={{ width: 120, height: 120, marginRight: 2 }} // Add margin to the right of the avatar
      />

      {/* Nickname and social media on the right */}
      <Box width="100%">
        {/* Nickname */}
        {nickName && (
          <Stack direction="row">
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              sx={{ marginLeft: 1 }}
            >
              @{nickName}
            </Typography>

            <AuthorizedApolloProvider>
              <FollowUser publicUser={publicUserInfo} />
            </AuthorizedApolloProvider>
          </Stack>
        )}
        {blogUrl && (
          <Link
            onClick={() => {
              openLinkInBrowserNewTab(blogUrl);
              gtag('event', 'public-link-maps-url');
            }}
            sx={{ textDecoration: 'none' }} // Remove underline
          >
            <Stack
              direction="row"
              alignItems="center" // Align icon and text vertically
              spacing={1}
              sx={{ paddingLeft: 1 }}
            >
              <LanguageTwoToneIcon fontSize="medium" />
              <Typography
                gutterBottom
                sx={{
                  margin: 0,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 1,
                  paddingTop: 1,
                  paddingBottom: 1,
                }}
              >
                Visit my blog
              </Typography>
            </Stack>
          </Link>
        )}

        {/* Social media icons in a row */}
        <Box display="flex" gap={1}>
          {tikTokUrl ? (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(tikTokUrl);
                gtag('event', 'open-user-tiktok');
              }}
            >
              <IconButton>
                <TikTokIcon fontSize="large" />
              </IconButton>
            </Link>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title="TikTok account not linked yet"
            >
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton disabled={true} sx={{ color: '#d3d3d3' }}>
                  <TikTokIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {instagramUrl ? (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(instagramUrl);
                gtag('event', 'open-user-instagram');
              }}
            >
              <IconButton>
                <Instagram fontSize="large" />
              </IconButton>
            </Link>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title="Instagram account not linked yet"
            >
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton disabled={true} sx={{ color: '#d3d3d3' }}>
                  <Instagram fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {youTubeUrl ? (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(youTubeUrl);
                gtag('event', 'open-user-youtube');
              }}
            >
              <IconButton>
                <YouTube fontSize="large" />
              </IconButton>
            </Link>
          ) : (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title="YouTube account not linked yet"
            >
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton disabled={true} sx={{ color: '#d3d3d3' }}>
                  <YouTube fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

import { useQuery } from '@apollo/client';
import DirectionsIcon from '@mui/icons-material/Directions';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import StarIcon from '@mui/icons-material/Star';
import { Box, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Maybe, Place, PlaceImage } from '../../generated/user_graphql';
import { errorsSelector } from '../../store/ErrorSlice';
import { setLikedPlaces } from '../../store/LikedPlacesSlice';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
} from '../../store/StepSlice';
import { setUserTrips } from '../../store/TripSlice';
import { ProminentOrangeButton } from '../../theme-components/Buttons';
import { Body1, TitleH4 } from '../../theme-components/Typography';
import {
  formatNumber,
  getPlaceMapUrls,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { Header } from '../AppWrapper';
import ErrorModal from '../Error';
import { LikePlace } from '../favourite/likePlace';
import { QUERY_GET_USER_FAVOURITE_PLACES } from '../gql-user/place';
import {
  QUERY_GET_USER_FAVOURITE_TRIPS,
  QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
} from '../gql-user/userTripsQuery';
import GoogleIcon from '../icons/GoogleIcon';
import { LikeSvg } from '../illustrations/Like';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { AddPlaceToDayButton } from '../trip-details/AddPlaceToDay';
import { PlaceMoreInfoModal, TodaysHours } from '../trip-details/Hours';
import { ResponsiveChip } from '../trip-details/PublicPlaceCard';
import { PlaceImages, PlaceSingleImage } from '../trip-details/StepImages';

import { RenderTrips } from './UserTrips';

export function UserFavouriteTrips() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(QUERY_GET_USER_FAVOURITE_TRIPS);
  const {
    loading: loadingPlaces,
    error: errorPlaces,
    data: dataPlaces,
  } = useQuery(QUERY_GET_USER_FAVOURITE_PLACES);

  const { data: userTripsData, loading: loadingUserTrips } = useQuery(
    QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
  );

  useEffect(() => {
    if (
      userTripsData &&
      userTripsData.userTripsWithDaysOnly &&
      userTripsData.userTripsWithDaysOnly.length > 0
    ) {
      dispatch(setUserTrips(userTripsData.userTripsWithDaysOnly));
    }
  }, [userTripsData, dispatch]);

  useEffect(() => {
    if (
      dataPlaces &&
      dataPlaces.userLikedPlaces &&
      dataPlaces.userLikedPlaces.length > 0
    ) {
      dispatch(setLikedPlaces(dataPlaces.userLikedPlaces));
    }
  }, [dataPlaces, dispatch]); // dependencies for useEffect

  const { errorModalOpen } = useSelector(errorsSelector);

  if (loading || loadingPlaces || loadingUserTrips) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingLeft: 1,
        paddingRight: 1,
        [theme.breakpoints.down('sm')]: {
          paddingTop: 1,
        },
      })}
    >
      {errorModalOpen && <ErrorModal />}
      {/* <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        
      </Box> */}
      {error || !data ? (
        <div>
          Error loading your favourite trips, refresh to try agian or contact
          help@rooutie.com
        </div>
      ) : (
        <>
          {data.userLikedTrips.length > 0 && <Header>Favourite trips</Header>}
          <RenderTrips trips={data.userLikedTrips} publicTrip={true} />
        </>
      )}
      {errorPlaces || !dataPlaces ? (
        <div>
          Error loading your favourite places, refresh to try agian or contact
          help@rooutie.com
        </div>
      ) : (
        <>
          {dataPlaces.userLikedPlaces.length > 0 && (
            <Header>Favourite places</Header>
          )}
          <FavouritePlaces places={dataPlaces.userLikedPlaces} />
        </>
      )}
      {dataPlaces.userLikedPlaces.length < 1 &&
      data.userLikedTrips.length < 1 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Horizontal alignment
            justifyContent: 'center', // Vertical alignment
            textAlign: 'center',
            gap: 2, // Space between text and button
          }}
        >
          <Box
            sx={theme => ({
              marginTop: '5%',
              width: '40%',
              [theme.breakpoints.down('sm')]: {
                width: '85%',
              },
            })}
          >
            <LikeSvg />
          </Box>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: 'text.secondary', // Softer color to complement primary button
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
              mb: 1, // Adds slight bottom margin
            }}
          >
            You haven’t liked any trips yet!
          </Typography>

          <ProminentOrangeButton
            sx={{
              marginRight: 1,
              marginBottom: 1,
              paddingX: 3,
              fontWeight: 'bold',
              boxShadow: '0px 4px 10px rgba(255, 87, 34, 0.4)', // Subtle shadow effect
            }}
            onClick={() => {
              gtag('event', 'explore-all-countries');
              navigate('/explore');
            }}
          >
            Explore All Countries
          </ProminentOrangeButton>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

interface PropsPlacesList {
  readonly places: Maybe<Place>[];
}

export function FavouritePlaces(props: PropsPlacesList) {
  return (
    <Box mb={2}>
      {props.places && props.places.length > 0 && (
        <>
          <Grid container>
            {props.places.map((place, index) => (
              <React.Fragment key={index}>
                {place && <FavouritePlaceCard place={place} />}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}
interface PlaceCardProps {
  place: Place;
  dayId?: string;
  selected?: boolean;
  tripId?: string;
  showEdit?: boolean;
  collapsedDetails?: boolean;
}

const FavouritePlaceCard: React.FC<PlaceCardProps> = ({ place }) => {
  const dispatch = useDispatch();

  if (!place) {
    return <></>;
  }

  let placeImages: Maybe<PlaceImage>[] = [];
  if (place && place.images && place.images.length > 0) {
    placeImages = place.images;
  }

  const { takeMeThereUrl, openGoogleMapsUrl } = getPlaceMapUrls(place);

  let moreInfoForPlace = false;
  if (
    place &&
    ((place.openingHours && place.openingHours.length > 0) ||
      place.website ||
      place.address ||
      place.phoneNumber)
  ) {
    moreInfoForPlace = true;
  }

  return (
    <Stack
      direction="column"
      alignItems="left"
      sx={theme => ({
        // width: '100%',
        paddingTop: 1.25,
        paddingBottom: 1.5,
        paddingLeft: 1,
        paddingRight: 1,
        marginRight: 1,
        marginTop: 1,
        borderRadius: '20px',
        // backgroundColor: selected ? '#fff7e6' : '#f6f0ff',
        backgroundColor: '#f6f0ff',
        marginBottom: 1,
        border: 2,
        borderColor: '#CBB8FF',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        width: '31%',
        [theme.breakpoints.down('xl')]: {
          width: '31%',
        },
        [theme.breakpoints.down('lg')]: {
          width: '48%',
        },
        [theme.breakpoints.down('md')]: {
          width: '47%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          marginRight: 0,
          marginLeft: 0,
          fontSize: 16,
          marginBottom: 0,
        },
      })}
    >
      <Stack
        direction="row"
        alignItems="left"
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            maxWidth: '99%',
          },
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            marginRight: 1,
            [theme.breakpoints.down('sm')]: {
              marginRight: 0.5,
            },
          })}
        >
          <PlaceTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              color: theme.palette.icon.main,
              [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
              },
            })}
          />
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            maxWidth: '100%',
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {place.title ? <TitleH4>{place.title}</TitleH4> : <Box />}
          <AuthorizedApolloProvider>
            {place && <LikePlace placeId={place.id} />}
            {place && (
              <AddPlaceToDayButton
                placeId={place.id}
                placeCountry={place.country ? place.country : undefined}
              />
            )}
          </AuthorizedApolloProvider>
        </Stack>
      </Stack>

      <Box
        // display={collapsedDetails === true ? 'none' : undefined}
        sx={theme => ({
          // paddingLeft: 1,

          [theme.breakpoints.down('sm')]: {
            // paddingLeft: 3,
          },
        })}
      >
        {place && (place.rating || place.noOfReviews) && (
          <Stack
            direction="row"
            sx={{
              alignItems: 'center', // Vertically center content
              justifyContent: 'space-between', // Horizontally center content
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
              <Box
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: '#AB88F3',
                  display: 'flex', // Enable flexbox layout
                  alignItems: 'center', // Vertically center content
                  justifyContent: 'center', // Horizontally center content
                  [theme.breakpoints.down('sm')]: {},
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.5,
                    width: 20,
                    height: 20,
                  },
                })}
              >
                <GoogleIcon />
              </Box>

              {place.rating && (
                <>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: '#E6B800',
                      fontSize: 18,
                      marginRight: 0.5,
                    }}
                  >
                    {formatNumber(place.rating)}
                  </Typography>
                  <StarIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 1,
                      color: '#E6B800',
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                </>
              )}
              {place.noOfReviews && (
                <Typography variant="body1">
                  ({place.rating ? place.noOfReviews : 'No reviews'})
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
        {/* Operating Hours */}
        {place && place.openingHours && place.openingHours.length > 0 && (
          <TodaysHours place={place} />
        )}

        <Stack direction="row" alignItems="center" spacing={0.5} sx={{ mt: 1 }}>
          {takeMeThereUrl && (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(takeMeThereUrl);
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                mobileCopy="Navigate"
                desktopCopy="Navigate"
                icon={DirectionsIcon}
              />
            </Link>
          )}
          {openGoogleMapsUrl && (
            <Link
              onClick={() => {
                openLinkInBrowserNewTab(openGoogleMapsUrl);
                gtag('event', 'public-link-maps-url');
              }}
            >
              <ResponsiveChip
                mobileCopy="Maps"
                desktopCopy="Maps"
                icon={MapIcon}
              />
            </Link>
          )}
          {moreInfoForPlace && (
            <Link
              onClick={() => {
                gtag('event', 'public-place-more-info-click');
                dispatch(setOpenHoursModal(true));
                dispatch(setPlaceIdForOpenHours(place!.id));
              }}
            >
              <ResponsiveChip
                mobileCopy="More"
                desktopCopy="More"
                icon={InfoIcon}
              />
            </Link>
          )}
        </Stack>

        {place.aiDescription && (
          <Box>
            {place &&
              (place.rating ||
                place.noOfReviews ||
                moreInfoForPlace ||
                openGoogleMapsUrl ||
                takeMeThereUrl) && (
                <Box sx={{ mt: 1.5, mb: 1 }}>
                  <Divider />
                </Box>
              )}
            <Body1>
              <RenderHtmlFromResponseNoMargin
                content={place.aiDescription}
                limitToLines={3}
              />
            </Body1>
          </Box>
        )}

        <Box sx={{ paddingRight: 1 }}>
          {placeImages.length > 1 ? (
            <PlaceImages images={placeImages} />
          ) : (
            <PlaceSingleImage images={placeImages} />
          )}

          {place && <PlaceMoreInfoModal place={place} />}
        </Box>
      </Box>
    </Stack>
  );
};

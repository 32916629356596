import * as React from 'react';

function Logo1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <linearGradient
        id="prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={269.2}
        x2={306.997}
        y1={158.084}
        y2={120.287}
      >
        <stop offset={0.011} stopColor="#60c6be" />
        <stop offset={1} stopColor="#80d1cb" />
      </linearGradient>
      <linearGradient
        id="prefix__c"
        x1={221.003}
        x2={258.8}
        xlinkHref="#prefix__a"
        y1={158.084}
        y2={120.287}
      />
      <linearGradient
        id="prefix__d"
        x1={326.905}
        x2={377.095}
        xlinkHref="#prefix__a"
        y1={460.16}
        y2={409.971}
      />
      <linearGradient
        id="prefix__e"
        x1={262.905}
        x2={313.095}
        xlinkHref="#prefix__a"
        y1={460.16}
        y2={409.971}
      />
      <linearGradient
        id="prefix__f"
        gradientUnits="userSpaceOnUse"
        x1={342}
        x2={394}
        y1={298}
        y2={246}
      >
        <stop offset={0.015} stopColor="#cacad5" />
        <stop offset={1} stopColor="#dcdce3" />
      </linearGradient>
      <linearGradient
        id="prefix__g"
        gradientUnits="userSpaceOnUse"
        x1={44.697}
        x2={227.303}
        y1={406.185}
        y2={223.579}
      >
        <stop offset={0.011} stopColor="#f8c73f" />
        <stop offset={1} stopColor="#fada7f" />
      </linearGradient>
      <linearGradient
        id="prefix__b"
        gradientUnits="userSpaceOnUse"
        x1={60}
        x2={212}
        y1={305.403}
        y2={305.403}
      >
        <stop offset={0.011} stopColor="#dcdce3" />
        <stop offset={1} stopColor="#ededf1" />
      </linearGradient>
      <linearGradient
        id="prefix__h"
        gradientUnits="userSpaceOnUse"
        x1={372.904}
        x2={477.096}
        y1={143.464}
        y2={39.272}
      >
        <stop offset={0} stopColor="#d46669" />
        <stop offset={1} stopColor="#dd888a" />
      </linearGradient>
      <linearGradient
        id="prefix__i"
        x1={405.201}
        x2={444.799}
        xlinkHref="#prefix__b"
        y1={108.799}
        y2={69.201}
      />
      <g data-name="05-destination">
        <g data-name="linear color">
          <path
            d="M264 142.9c0-13.309 13.08-36.9 24.1-36.9s24.1 23.592 24.1 36.9a24.1 24.1 0 01-48.2 0z"
            fill="url(#prefix__a)"
          />
          <path
            d="M215.8 142.9c0-13.309 13.081-36.9 24.1-36.9s24.1 23.592 24.1 36.9a24.1 24.1 0 11-48.2 0z"
            fill="url(#prefix__c)"
          />
          <path
            d="M320 440c0-17.673 17.369-49 32-49s32 31.327 32 49a32 32 0 01-64 0z"
            fill="url(#prefix__d)"
          />
          <path
            d="M256 440c0-17.673 17.369-49 32-49s32 31.327 32 49a32 32 0 01-64 0z"
            fill="url(#prefix__e)"
          />
          <path d="M336 292h64v-40h-64l-16 20z" fill="url(#prefix__f)" />
          <path
            d="M13.581 305.4a122.419 122.419 0 01244.838 0C258.419 385.242 136 497.016 136 497.016S13.581 385.242 13.581 305.4z"
            fill="url(#prefix__g)"
          />
          <circle cx={136} cy={305.403} fill="url(#prefix__b)" r={76} />
          <path
            d="M353 89a72 72 0 01144 0c0 48-72 104-72 104s-72-56-72-104z"
            fill="url(#prefix__h)"
          />
          <circle cx={425} cy={89} fill="url(#prefix__i)" r={28} />
          <path d="M403.89 332H388v-28h12a12 12 0 0012-12v-40a12 12 0 00-12-12h-64a12 12 0 00-9.37 4.5l-16 20a12 12 0 000 14.992l16 20A12 12 0 00336 304h28v28h-97.161a108.906 108.906 0 003.58-26.6A134.149 134.149 0 00224.142 204H360a12 12 0 000-24H184.392A133.737 133.737 0 00136 170.984c-74.119 0-134.419 60.3-134.419 134.419 0 84.164 121.17 195.765 126.328 200.475a12 12 0 0016.182 0c4.167-3.805 84.038-77.373 114.58-149.878H403.89a64.11 64.11 0 01.174 128.22l-204.11.78a12 12 0 00.046 24h.047l204.1-.78A88.11 88.11 0 00403.89 332zm-68.523-60l6.4-8H388v16h-46.232zM136 480.432C109.266 454.332 25.581 367.64 25.581 305.4a110.419 110.419 0 01220.838 0c0 10.473-2.382 21.644-6.46 33.1a11.915 11.915 0 00-1.014 2.758C217.841 397.134 157.86 459.072 136 480.432z" />
          <path d="M320 470.152A43.969 43.969 0 00396 440c0-10.742-4.709-25.077-12.29-37.411C378.3 393.779 366.976 379 352 379s-26.3 14.779-31.71 23.589c-.1.161-.192.327-.29.488-.1-.161-.191-.327-.29-.488C314.3 393.779 302.976 379 288 379s-26.3 14.779-31.71 23.589C248.709 414.923 244 429.258 244 440a43.969 43.969 0 0076 30.152zm20.036-53.829c5.259-8.966 10.248-12.773 11.964-13.29 1.716.517 6.7 4.324 11.964 13.29C368.771 424.518 372 434.033 372 440a20 20 0 01-40 0c0-5.967 3.229-15.482 8.036-23.677zM268 440c0-5.967 3.229-15.482 8.036-23.677 5.259-8.966 10.248-12.773 11.964-13.29 1.716.517 6.7 4.324 11.964 13.29C304.771 424.518 308 434.033 308 440a20 20 0 01-40 0zM136 217.4a88 88 0 1088 88 88.1 88.1 0 00-88-88zm0 152a64 64 0 1164-64 64.072 64.072 0 01-64 64zM417.633 202.472a12 12 0 0014.734 0 323.227 323.227 0 0037.553-35.445C495.852 138.214 509 111.962 509 89a84 84 0 00-168 0c0 22.962 13.148 49.214 39.08 78.027a323.227 323.227 0 0037.553 35.445zM425 29a60.068 60.068 0 0160 60c0 30.588-38.281 69.814-60 88.47-21.723-18.659-60-57.884-60-88.47a60.068 60.068 0 0160-60z" />
          <path d="M465 89a40 40 0 10-40 40 40.045 40.045 0 0040-40zm-56 0a16 16 0 1116 16 16.019 16.019 0 01-16-16zM239.9 179a35.952 35.952 0 0024.1-9.25 36.076 36.076 0 0060.2-26.85c0-16.739-15.244-48.9-36.1-48.9-9.5 0-17.837 6.688-24.1 15.544C257.738 100.688 249.406 94 239.9 94c-20.854 0-36.1 32.162-36.1 48.9a36.139 36.139 0 0036.1 36.1zm48.2-60.571c4.472 3.226 12.1 16.4 12.1 24.472a12.1 12.1 0 11-24.2 0c0-8.071 7.626-21.246 12.1-24.472zm-48.2 0c4.473 3.226 12.1 16.4 12.1 24.472a12.1 12.1 0 11-24.2 0c0-8.071 7.629-21.246 12.1-24.472z" />
        </g>
      </g>
    </svg>
  );
}

export default Logo1;

export function ModernLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        {/* Updated gradients for a fresher look */}
        <linearGradient
          id="prefix__a"
          x1={269.2}
          x2={306.997}
          y1={158.084}
          y2={120.287}
        >
          <stop offset={0.011} stopColor="#4fc3f7" /> {/* Lighter blue */}
          <stop offset={1} stopColor="#03a9f4" /> {/* Slightly darker blue */}
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1={221.003}
          x2={258.8}
          xlinkHref="#prefix__a"
          y1={158.084}
          y2={120.287}
        />
        <linearGradient
          id="prefix__d"
          x1={326.905}
          x2={377.095}
          xlinkHref="#prefix__a"
          y1={460.16}
          y2={409.971}
        />
        <linearGradient
          id="prefix__e"
          x1={262.905}
          x2={313.095}
          xlinkHref="#prefix__a"
          y1={460.16}
          y2={409.971}
        />
        <linearGradient id="prefix__f" x1={342} x2={394} y1={298} y2={246}>
          <stop offset={0.015} stopColor="#b0bec5" />{' '}
          {/* Grey with blue tint */}
          <stop offset={1} stopColor="#90a4ae" />{' '}
          {/* Slightly darker grey-blue */}
        </linearGradient>
        <linearGradient
          id="prefix__g"
          x1={44.697}
          x2={227.303}
          y1={406.185}
          y2={223.579}
        >
          <stop offset={0.011} stopColor="#ffb74d" /> {/* Warm yellow */}
          <stop offset={1} stopColor="#ff9800" /> {/* Richer orange */}
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={60}
          x2={212}
          y1={305.403}
          y2={305.403}
        >
          <stop offset={0.011} stopColor="#e0e0e0" /> {/* Light grey */}
          <stop offset={1} stopColor="#bdbdbd" /> {/* Medium grey */}
        </linearGradient>
        <linearGradient
          id="prefix__h"
          x1={372.904}
          x2={477.096}
          y1={143.464}
          y2={39.272}
        >
          <stop offset={0} stopColor="#e57373" /> {/* Soft red */}
          <stop offset={1} stopColor="#ef5350" /> {/* Stronger red */}
        </linearGradient>
        <linearGradient
          id="prefix__i"
          x1={405.201}
          x2={444.799}
          xlinkHref="#prefix__b"
          y1={108.799}
          y2={69.201}
        />
        {/* Adding a subtle shadow effect */}
        <filter id="f1" x="0" y="0">
          <feDropShadow
            dx="2"
            dy="2"
            stdDeviation="2"
            floodColor="#888"
            floodOpacity="0.5"
          />
        </filter>
      </defs>
      <g data-name="modernized-logo" filter="url(#f1)">
        <g data-name="updated-linear-color">
          {/* Updated shapes with new colors and styles */}
          <path
            d="M264 142.9c0-13.309 13.08-36.9 24.1-36.9s24.1 23.592 24.1 36.9a24.1 24.1 0 01-48.2 0z"
            fill="url(#prefix__a)"
          />
          <path
            d="M215.8 142.9c0-13.309 13.081-36.9 24.1-36.9s24.1 23.592 24.1 36.9a24.1 24.1 0 11-48.2 0z"
            fill="url(#prefix__c)"
          />
          <path
            d="M320 440c0-17.673 17.369-49 32-49s32 31.327 32 49a32 32 0 01-64 0z"
            fill="url(#prefix__d)"
          />
          <path
            d="M256 440c0-17.673 17.369-49 32-49s32 31.327 32 49a32 32 0 01-64 0z"
            fill="url(#prefix__e)"
          />
          <path d="M336 292h64v-40h-64l-16 20z" fill="url(#prefix__f)" />
          <path
            d="M13.581 305.4a122.419 122.419 0 01244.838 0C258.419 385.242 136 497.016 136 497.016S13.581 385.242 13.581 305.4z"
            fill="url(#prefix__g)"
          />
          <circle cx={136} cy={305.403} fill="url(#prefix__b)" r={76} />
          <path
            d="M353 89a72 72 0 01144 0c0 48-72 104-72 104s-72-56-72-104z"
            fill="url(#prefix__h)"
          />
          <circle cx={425} cy={89} fill="url(#prefix__i)" r={28} />
          <path d="M403.89 332H388v-28h12a12 12 0 0012-12v-40a12 12 0 00-12-12h-64a12 12 0 00-9.37 4.5l-16 20a12 12 0 000 14.992l16 20A12 12 0 00336 304h28v28h-97.161a108.906 108.906 0 003.58-26.6A134.149 134.149 0 00224.142 204H360a12 12 0 000-24H184.392A133.737 133.737 0 00136 170.984c-74.119 0-134.419 60.3-134.419 134.419 0 84.164 121.17 195.765 126.328 200.475a12 12 0 0016.182 0c4.167-3.805 84.038-77.373 114.58-149.878H403.89a64.11 64.11 0 01.174 128.22l-204.11.78a12 12 0 00.046 24h.047l204.1-.78A88.11 88.11 0 00403.89 332zm-68.523-60l6.4-8H388v16h-46.232zM136 480.432C109.266 454.332 25.581 367.64 25.581 305.4a110.419 110.419 0 01220.838 0c0 10.473-2.382 21.644-6.46 33.1a11.915 11.915 0 00-1.014 2.758C217.841 397.134 157.86 459.072 136 480.432z" />
          <path d="M320 470.152A43.969 43.969 0 00396 440c0-10.742-4.709-25.077-12.29-37.411C378.3 393.779 366.976 379 352 379s-26.3 14.779-31.71 23.589c-.1.161-.192.327-.29.488-.1-.161-.191-.327-.29-.488C314.3 393.779 302.976 379 288 379s-26.3 14.779-31.71 23.589C248.709 414.923 244 429.258 244 440a43.969 43.969 0 0076 30.152zm20.036-53.829c5.259-8.966 10.248-12.773 11.964-13.29 1.716.517 6.7 4.324 11.964 13.29C368.771 424.518 372 434.033 372 440a20 20 0 01-40 0c0-5.967 3.229-15.482 8.036-23.677zM268 440c0-5.967 3.229-15.482 8.036-23.677 5.259-8.966 10.248-12.773 11.964-13.29 1.716.517 6.7 4.324 11.964 13.29C304.771 424.518 308 434.033 308 440a20 20 0 01-40 0zM136 217.4a88 88 0 1088 88 88.1 88.1 0 00-88-88zm0 152a64 64 0 1164-64 64.072 64.072 0 01-64 64zM417.633 202.472a12 12 0 0014.734 0 323.227 323.227 0 0037.553-35.445C495.852 138.214 509 111.962 509 89a84 84 0 00-168 0c0 22.962 13.148 49.214 39.08 78.027a323.227 323.227 0 0037.553 35.445zM425 29a60.068 60.068 0 0160 60c0 30.588-38.281 69.814-60 88.47-21.723-18.659-60-57.884-60-88.47a60.068 60.068 0 0160-60z" />
          <path d="M465 89a40 40 0 10-40 40 40.045 40.045 0 0040-40zm-56 0a16 16 0 1116 16 16.019 16.019 0 01-16-16zM239.9 179a35.952 35.952 0 0024.1-9.25 36.076 36.076 0 0060.2-26.85c0-16.739-15.244-48.9-36.1-48.9-9.5 0-17.837 6.688-24.1 15.544C257.738 100.688 249.406 94 239.9 94c-20.854 0-36.1 32.162-36.1 48.9a36.139 36.139 0 0036.1 36.1zm48.2-60.571c4.472 3.226 12.1 16.4 12.1 24.472a12.1 12.1 0 11-24.2 0c0-8.071 7.626-21.246 12.1-24.472zm-48.2 0c4.473 3.226 12.1 16.4 12.1 24.472a12.1 12.1 0 11-24.2 0c0-8.071 7.629-21.246 12.1-24.472z" />
        </g>
      </g>
    </svg>
  );
}

export function SmallOptimizedLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      width={40}
      height={40}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        {/* Simplified gradients for clearer rendering at small sizes */}
        <linearGradient
          id="prefix__a"
          x1={269.2}
          x2={306.997}
          y1={158.084}
          y2={120.287}
        >
          <stop offset={0.011} stopColor="#4fc3f7" />
          <stop offset={1} stopColor="#03a9f4" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={221.003}
          x2={258.8}
          xlinkHref="#prefix__a"
          y1={158.084}
          y2={120.287}
        />
        <linearGradient
          id="prefix__c"
          x1={326.905}
          x2={377.095}
          xlinkHref="#prefix__a"
          y1={460.16}
          y2={409.971}
        />
        <linearGradient
          id="prefix__d"
          x1={262.905}
          x2={313.095}
          xlinkHref="#prefix__a"
          y1={460.16}
          y2={409.971}
        />
        <linearGradient id="prefix__e" x1={342} x2={394} y1={298} y2={246}>
          <stop offset={0.015} stopColor="#b0bec5" />
          <stop offset={1} stopColor="#90a4ae" />
        </linearGradient>
        <linearGradient
          id="prefix__f"
          x1={44.697}
          x2={227.303}
          y1={406.185}
          y2={223.579}
        >
          <stop offset={0.011} stopColor="#ffb74d" />
          <stop offset={1} stopColor="#ff9800" />
        </linearGradient>
        <linearGradient
          id="prefix__g"
          x1={60}
          x2={212}
          y1={305.403}
          y2={305.403}
        >
          <stop offset={0.011} stopColor="#e0e0e0" />
          <stop offset={1} stopColor="#bdbdbd" />
        </linearGradient>
        <linearGradient
          id="prefix__h"
          x1={372.904}
          x2={477.096}
          y1={143.464}
          y2={39.272}
        >
          <stop offset={0} stopColor="#e57373" />
          <stop offset={1} stopColor="#ef5350" />
        </linearGradient>
        <linearGradient
          id="prefix__i"
          x1={405.201}
          x2={444.799}
          xlinkHref="#prefix__g"
          y1={108.799}
          y2={69.201}
        />
      </defs>
      <g data-name="small-optimized-logo">
        <g data-name="optimized-linear-color">
          {/* Simplified shapes with solid colors */}
          <circle cx={264} cy={142.9} r={24.1} fill="url(#prefix__a)" />
          <circle cx={215.8} cy={142.9} r={24.1} fill="url(#prefix__b)" />
          <circle cx={352} cy={440} r={32} fill="url(#prefix__c)" />
          <circle cx={288} cy={440} r={32} fill="url(#prefix__d)" />
          <rect x={336} y={292} width={64} height={40} fill="url(#prefix__e)" />
          <circle cx={136} cy={305.403} r={76} fill="url(#prefix__g)" />
          <circle cx={425} cy={89} r={72} fill="url(#prefix__h)" />
          <circle cx={425} cy={89} r={28} fill="url(#prefix__i)" />
        </g>
        {/* Simplified outline */}
        <path
          d="M239.9 179a35.952 35.952 0 0024.1-9.25 36.076 36.076 0 0060.2-26.85c0-16.739-15.244-48.9-36.1-48.9-9.5 0-17.837 6.688-24.1 15.544C257.738 100.688 249.406 94 239.9 94c-20.854 0-36.1 32.162-36.1 48.9a36.139 36.139 0 0036.1 36.1z"
          fill="#000"
        />
        <path
          d="M417.633 202.472a12 12 0 0014.734 0 323.227 323.227 0 0037.553-35.445C495.852 138.214 509 111.962 509 89a84 84 0 00-168 0c0 22.962 13.148 49.214 39.08 78.027a323.227 323.227 0 0037.553 35.445z"
          fill="#000"
        />
      </g>
    </svg>
  );
}

// src/firebase.ts
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  User,
} from 'firebase/auth';
import { useEffect, useState } from 'react';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Helper function to send email link
export const sendEmailLink = async (email: string) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. It must be in the authorized domains in your Firebase console.
    url: 'https://localhost:4001/finish-sign-up', // Your redirect URL
    handleCodeInApp: true, // This must be true for email link sign-in.
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email); // Save the user's email in local storage
  } catch (error) {
    console.error('Error sending email link:', error);
  }
};

// Helper function to complete sign-in
export const completeSignIn = async (email: string, link: string) => {
  try {
    if (isSignInWithEmailLink(auth, link)) {
      const result = await signInWithEmailLink(auth, email, link);
      window.localStorage.removeItem('emailForSignIn'); // Clean up local storage
      return result.user;
    }
  } catch (error) {
    console.error('Error signing in with email link:', error);
  }
  return null;
};

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null); // State to store the token

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        setUser(currentUser);
        setIsAuthenticated(true);

        // Get the cached ID token, only fetch if it has expired
        const token = await currentUser.getIdToken(); // No 'true' argument, so no forced refresh
        setIdToken(token); // Store the token
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setIdToken(null); // Clear the token when user logs out
      }
      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return { user, isAuthenticated, isLoading, idToken };
};

import { useMutation } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PhotoSizeSelectActualTwoToneIcon from '@mui/icons-material/PhotoSizeSelectActualTwoTone';
import PublishedWithChangesTwoToneIcon from '@mui/icons-material/PublishedWithChangesTwoTone';
import UnpublishedTwoToneIcon from '@mui/icons-material/UnpublishedTwoTone';
import { Button, Tooltip, Box } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trip } from '../../../generated/user_graphql';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { setTrip } from '../../../store/TripSlice';
import { userSelector } from '../../../store/UserSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { isAdmin, isPublisher, useUserRoles } from '../../../utils/helpers';
import { MUTATION_PUBLISH_TRIP } from '../../gql-user/publishTrip';
import {
  ModalDescription,
  ModalTitle,
  CenteredModal,
  MediumModalPaper,
  ModalPaper,
} from '../../styling/modal';
import EditTripDescriptionModal from '../manage-trip-modals/AddEditDescription';
import EditTripNameModal from '../manage-trip-modals/EditTripName';
import { StayPrimaryPortraitSharp } from '@mui/icons-material';
import EditTagsModal from '../manage-trip-modals/EditTagsModal';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';

interface TripActionsProps {
  trip: Trip;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const ActionsTrip: React.FC<TripActionsProps> = ({ trip }) => {
  const roles = useUserRoles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [scrollY, setScrollY] = useState(0);
  const [isEditTitleOpen, setIsEditTitleOpen] = useState(false);
  const [isEditTagsOpen, setIsEditTagsOpen] = useState(false);
  const [isEditDescriptionOpen, setIsEditDescriptionOpen] = useState(false);

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;
    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
  };

  const handleClose = () => {
    setIsEditDescriptionOpen(false);
    setIsEditTagsOpen(false);
    setIsEditTitleOpen(false);
    setIsModalDisplayed(false);
    setAnchorEl(null);
    enableScroll();
  };

  const handleCloseModal = () => {
    setIsEditDescriptionOpen(false);
    setIsEditTagsOpen(false);
    setIsEditTitleOpen(false);
    setIsModalDisplayed(false);
    setAnchorEl(null);
    setTimeout(() => {
      enableScroll();
    }, 300);
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    document.body.style.position = 'fixed'; // Prevent page jump
    document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  };

  const enableScroll = () => {
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
    // document.body.style.top = ''; // Reset top
    window.scrollTo(0, scrollY); // Restore previous scroll position
  };

  useEffect(() => {
    if (isModalDisplayed) {
      disableScroll(); // Lock scrolling when modal is displayed
    }

    return () => {
      enableScroll(); // Clean up scrolling state when modal is closed
    };
  }, [isModalDisplayed]);

  // useEffect(() => {
  //   return () => {
  //     console.log('enableScroll 2T');
  //     enableScroll();
  //   };
  // }, []);

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !isModalDisplayed) {
      const handleScroll = e => {
        handleClose(); // Close modal on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close modal on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, isModalDisplayed]);

  return (
    <Box
      sx={theme => ({
        flexShrink: 0, // Prevent the button from shrinking
      })}
    >
      <Button
        variant="contained"
        endIcon={
          <ArrowDropDownIcon
            sx={theme => ({
              height: 17,
              width: 17,
              pointerEvents: 'none',
            })}
          />
        }
        onClick={e => {
          const scrollPosition = window.scrollY;
          setScrollY(scrollPosition);
          handleClick(e);
        }}
        sx={theme => ({
          fontSize: 12,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          paddingLeft: 1.75,
          paddingRight: 1.75,
          backgroundColor: '#36454F',
          borderRadius: 20,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          boxShadow: 'none', // Disable shadow
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#4A5D6F',
          },
        })}
      >
        {!trip.tripType || trip.tripType !== 'guide' ? (
          <>Trip actions</>
        ) : (
          <>Guide actions</>
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        disableScrollLock={true}
        anchorReference="anchorPosition"
        anchorPosition={
          fixedAnchorPosition
            ? {
                top: fixedAnchorPosition.top,
                left: fixedAnchorPosition.left,
              }
            : undefined
        }
        anchorOrigin={{
          vertical: 'bottom', // Adjust vertical anchor
          horizontal: 'left', // Adjust horizontal anchor
        }}
        transformOrigin={{
          vertical: 'top', // Adjust vertical transform
          horizontal: 'left', // Adjust horizontal transform
        }}
      >
        <MenuItem
          onClick={() => {
            if (!isModalDisplayed) {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              setIsModalDisplayed(!isModalDisplayed);
              setIsEditTitleOpen(true);
            }
          }}
        >
          <ListItemIcon>
            <EditTwoToneIcon />
          </ListItemIcon>
          Edit name
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (!isModalDisplayed) {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              setIsModalDisplayed(!isModalDisplayed);
            }
            setIsEditDescriptionOpen(true);
          }}
        >
          <ListItemIcon>
            <PhotoSizeSelectActualTwoToneIcon />
          </ListItemIcon>
          {trip.description ? 'Edit description' : 'Add description'}
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            {trip.published || trip.publish_pending ? (
              <UnpublishedTwoToneIcon />
            ) : (
              <PublishedWithChangesTwoToneIcon />
            )}
          </ListItemIcon>
          <Box
            onClick={() => {
              if (!isModalDisplayed) {
                const scrollPosition = window.scrollY;
                setScrollY(scrollPosition);
                setIsModalDisplayed(!isModalDisplayed);
              }
            }}
          >
            <PublishTrip
              tripId={trip.id}
              publish={trip.published || trip.publish_pending ? false : true}
              handleCloseModal={handleCloseModal}
              tripType={trip.tripType}
            />
          </Box>
        </MenuItem>
        <Tooltip title="Coming soon!">
          <span>
            {/* Wrapper required for disabled MenuItem to allow Tooltip */}
            <MenuItem disabled>
              <ListItemIcon>
                <DeleteTwoToneIcon />
              </ListItemIcon>
              Delete trip
            </MenuItem>
          </span>
        </Tooltip>
        {isAdmin(roles) && (
          <MenuItem
            onClick={() => {
              if (!isModalDisplayed) {
                const scrollPosition = window.scrollY;
                setScrollY(scrollPosition);
                setIsModalDisplayed(!isModalDisplayed);
                setIsEditTagsOpen(true);
              }
            }}
          >
            <ListItemIcon>
              <EditTwoToneIcon />
            </ListItemIcon>
            Manage tags
          </MenuItem>
        )}
      </Menu>
      {isEditTitleOpen && (
        <EditTripNameModal
          name={trip.name}
          editTripId={trip.id}
          tripType={trip.tripType ? trip.tripType : 'itinerary'}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isEditTagsOpen && (
        <EditTagsModal
          tripId={trip.id}
          handleCloseModal={handleCloseModal}
          tags={trip.tags}
        />
      )}
      {isEditDescriptionOpen && (
        <EditTripDescriptionModal
          description={trip.description ? trip.description : ''}
          editTripId={trip.id}
          tripType={trip.tripType ? trip.tripType : undefined}
          // tripActions={true}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Box>
  );
};

const PublishTrip: React.FC<{
  tripId: any;
  publish: boolean;
  tripType: string | undefined | null;
  handleCloseModal: () => void;
}> = ({ tripId, publish, tripType, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const roles = useUserRoles();

  const [publishTrip, { loading }] = useMutation(MUTATION_PUBLISH_TRIP, {
    variables: {
      trip_id: tripId,
      publish,
    },
  });

  const closeModal = () => {
    setOpen(false);
    handleCloseModal();
  };

  const { myUser } = useSelector(userSelector);

  return (
    <>
      <Tooltip
        title={
          !myUser || !myUser.nickName
            ? 'You need a nickname to perform this action'
            : ''
        }
        arrow
        placement="top"
        disableHoverListener={false}
      >
        <Box
          onClick={() => {
            if (!myUser || !myUser.nickName) {
            } else {
              gtag('event', 'publish-trip-button');
              setOpen(true);
            }
          }}
          sx={{ width: '100%' }}
        >
          {!tripType || tripType !== 'guide' ? (
            <> {publish ? 'Publish trip' : 'Unpublish trip'}</>
          ) : (
            <> {publish ? 'Publish guide' : 'Unpublish guide'}</>
          )}
        </Box>
      </Tooltip>

      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader title={publish ? 'Exciting!' : 'Oh no!'} />
          {publish ? (
            <>
              {isPublisher(roles) || isAdmin(roles) ? (
                <ModalHeader
                  description={
                    !tripType || tripType !== 'guide' ? (
                      <>
                        {
                          <>
                            <strong>Thank you</strong> for publishing this trip.
                            Your trip will be{' '}
                            <strong>visible to everyone</strong> once you
                            publish it
                          </>
                        }
                      </>
                    ) : (
                      <>
                        {
                          <>
                            <strong>Thank you</strong> for publishing this
                            guide.Your guide will be{' '}
                            <strong>visible to everyone</strong> once you
                            publish it
                          </>
                        }
                      </>
                    )
                  }
                />
              ) : (
                <ModalHeader
                  description={
                    !tripType || tripType !== 'guide' ? (
                      <>
                        {
                          <>
                            <strong>Thank you</strong> for publishing this trip.
                            Your trip will be{' '}
                            <strong>visible to everyone</strong> once we review
                            it and approve it
                          </>
                        }
                      </>
                    ) : (
                      <>
                        {
                          <>
                            <strong>Thank you</strong> for publishing this
                            guide.Your guide will be{' '}
                            <strong>visible to everyone</strong> once we review
                            it and approve it
                          </>
                        }
                      </>
                    )
                  }
                />
              )}
            </>
          ) : (
            <ModalHeader
              description={
                !tripType || tripType !== 'guide' ? (
                  <>
                    {
                      <>
                        We're sorry to see this trip go{' '}
                        <strong>inactive</strong>. Hope you publish another one
                        soon
                      </>
                    }
                  </>
                ) : (
                  <>
                    {
                      <>
                        We're sorry to see this guide go{' '}
                        <strong>inactive</strong>. Hope you publish another one
                        soon
                      </>
                    }
                  </>
                )
              }
            />
          )}
          <Space size="md" />
          <PrimaryButton
            disabled={loading}
            onClick={async () => {
              gtag('event', 'publish-trip-confirm');
              try {
                const res = await publishTrip();
                if (res && res.data && res.data.publishUserTrip) {
                  dispatch(setTrip(res.data.publishUserTrip));
                  closeModal();
                }
              } catch (e) {
                gtag('event', 'error-publishing-trip');
                dispatch(setErrorTitle('Error publishing trip'));
                dispatch(setErrorModalOpen(true));
              }
            }}
          >
            {loading ? <Loader /> : <>{publish ? 'Publish' : 'Unpublish'}</>}
          </PrimaryButton>
          <Space size="md" />
          <SecondaryButton
            disabled={loading}
            onClick={async () => {
              gtag('event', 'publish-trip-close');
              setOpen(false);
              closeModal();
            }}
          >
            Close
          </SecondaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
};

import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DirectionsSubwayFilledTwoToneIcon from '@mui/icons-material/DirectionsSubwayFilledTwoTone';
import FastfoodTwoToneIcon from '@mui/icons-material/FastfoodTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import { Button, Box, Stack, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  setAddStepModalOpen,
  setStepDayId,
  setStepType,
} from '../../../store/StepSlice';
import { returnFormattedDate } from '../../../utils/helpers';
import { AddStepToDay } from '../manage-trip-modals/add-step/AddStepToDayModal';
import { DeleteDayModal } from '../manage-trip-modals/DeleteDayModal';

interface ActionsDayProps {
  dayId: string;
  dayNumber: number;
  iconsOnly: boolean;
  tripType: string;
  dayDate?: Date | undefined | null;
  marginLeft?: boolean;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const ActionsDay: React.FC<ActionsDayProps> = ({
  dayId,
  dayNumber,
  iconsOnly,
  tripType,
  dayDate,
  marginLeft,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [scrollY, setScrollY] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);
  console.log(dayDate);
  const handleMenuItemClick = category => {
    if (!currentCategory) {
      const scrollPosition = window.scrollY;
      setScrollY(scrollPosition);
      setCurrentCategory(category);
      dispatch(setStepType(category)); // Set step type
    }
  };

  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;
    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
  };

  const handleClose = () => {
    setAnchorEl(null);
    enableScroll();
  };

  const handleCloseModal = () => {
    setCurrentCategory(null);
    setAnchorEl(null);
    setIsDelete(false);
    setTimeout(() => {
      enableScroll();
    }, 300);
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    document.body.style.position = 'fixed'; // Prevent page jump
    document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  };

  const enableScroll = () => {
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
    // document.body.style.top = ''; // Reset top
    window.scrollTo(0, scrollY); // Restore previous scroll position
  };

  useEffect(() => {
    if (currentCategory) {
      disableScroll(); // Lock scrolling when modal is displayed
    }

    return () => {
      // setIsModalDisplayed(false);
      // there was enable scroll but that caused unexpected screen scrolls to top of the screen
    };
  }, [currentCategory]);

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !currentCategory) {
      const handleScroll = e => {
        handleClose(); // Close menu on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close menu on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, currentCategory]);

  return (
    <Box
      sx={theme => ({
        width: '100%',
        flexShrink: 0, // Prevent the button from shrinking
      })}
    >
      {!iconsOnly ? (
        <>
          <Button
            variant="contained"
            endIcon={
              <ArrowDropDownIcon
                sx={theme => ({
                  height: 17,
                  width: 17,
                  pointerEvents: 'none',
                })}
              />
            }
            onClick={e => {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              handleClick(e);
            }}
            sx={theme => ({
              fontSize: 12,
              paddingTop: 0.5,
              paddingBottom: 0.5,
              paddingLeft: 1.75,
              paddingRight: 1.75,
              backgroundColor: '#36454F',
              borderRadius: 20,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              boxShadow: 'none', // Disable shadow
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#4A5D6F',
              },
            })}
          >
            {tripType && tripType === 'guide' ? (
              <>Add to guide</>
            ) : (
              <>Add to Day {dayNumber}</>
            )}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            disableScrollLock={true}
            anchorReference="anchorPosition"
            anchorPosition={
              fixedAnchorPosition
                ? {
                    top: fixedAnchorPosition.top,
                    left: fixedAnchorPosition.left,
                  }
                : undefined
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_PLACE');
                dispatch(setStepDayId(dayId));
              }}
            >
              <ListItemIcon>
                <AddLocationTwoToneIcon />
              </ListItemIcon>
              Place
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_FOOD_DRINK');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <FastfoodTwoToneIcon />
              </ListItemIcon>
              Food & Drink
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_ACCOMMODATION');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <HotelTwoToneIcon />
              </ListItemIcon>
              Stay
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_TRANSPORT');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <DirectionsSubwayFilledTwoToneIcon />
              </ListItemIcon>
              Transport
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuItemClick('CATEGORY_ACTIVITY');
                dispatch(setStepDayId(dayId));
                dispatch(setAddStepModalOpen(true));
              }}
            >
              <ListItemIcon>
                <LocalActivityTwoToneIcon />
              </ListItemIcon>
              Activity
            </MenuItem>
            {/* <Tooltip title="Coming soon!">
              <span> */}
            <MenuItem
              onClick={() => {
                dispatch(setAddStepModalOpen(false));
                setIsDelete(true);
              }}
            >
              <ListItemIcon>
                <DeleteTwoToneIcon />
              </ListItemIcon>
              Delete day
            </MenuItem>
            {/* </span>
            </Tooltip> */}
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            fontSize: 18,
            fontWeight: 600,
            marginLeft: 0,
            paddingX: 2,
            paddingBottom: 2,
            paddingTop: 1,
            backgroundColor: '#F0F0F0',
            borderRadius: '20px',
            marginTop: marginLeft ? 2 : 0,
            marginBottom: marginLeft ? 0 : 0,
          }}
        >
          <Stack direction="column" alignItems="center" width="100%">
            <Box
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: '#333',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {tripType && tripType === 'guide' ? (
                <Box sx={{ marginBottom: 1, marginTop: 0 }}>Add to guide</Box>
              ) : (
                // <Stack
                //   direction="row"
                //   alignItems="center"
                //   sx={{ width: '100%' }}
                // >
                //   <Box sx={{}}>Add to Day {dayNumber}</Box>

                //   {dayDate && (
                //     <Box pl={1}>
                //       <Typography
                //         sx={theme => ({
                //           fontWeight: 500,
                //           fontSize: 16,
                //           // marginTop: theme.spacing(0.5),
                //           color: '#4E4B66',
                //           [theme.breakpoints.down('sm')]: {
                //             fontSize: 14,
                //           },
                //         })}
                //       >
                //         ({returnFormattedDate(dayDate, dayNumber)})
                //       </Typography>
                //     </Box>
                //   )}
                // </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    position: 'relative',
                    height: 20,
                    marginTop: 1,
                    marginBottom: 1,
                  }} // Make the Stack position relative
                >
                  {/* Centered Box */}
                  <Box
                    sx={{
                      position: 'absolute', // Use absolute positioning
                      left: '50%', // Start from the middle of the parent
                      transform: 'translateX(-50%)', // Move it back half of its width
                      whiteSpace: 'nowrap', // Prevent wrapping
                      zIndex: 1, // Bring it in front if necessary
                    }}
                  >
                    Add to Day {dayNumber}
                  </Box>
                  {dayDate && (
                    <Box sx={{ position: 'absolute', right: 0 }}>
                      {/* Use pl={2} for spacing */}
                      <Typography
                        sx={theme => ({
                          fontWeight: 500,
                          fontSize: 16,
                          color: '#4E4B66',
                          [theme.breakpoints.down('sm')]: {
                            fontSize: 14,
                          },
                        })}
                      >
                        {returnFormattedDate(dayDate, dayNumber - 1, false)}
                      </Typography>
                    </Box>
                  )}
                  {/* Second child - aligned normally */}
                </Stack>
              )}
            </Box>
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              spacing={2}
              rowGap={1}
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#555',
                padding: '8px 16px',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_PLACE');
                  // dispatch(setStepType('CATEGORY_PLACE'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="row"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={0}
                >
                  <AddLocationTwoToneIcon />
                  <Box>{'Place'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_FOOD_DRINK');
                  // dispatch(setStepType('CATEGORY_FOOD_DRINK'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="row"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={2}
                >
                  <FastfoodTwoToneIcon />
                  <Box>{'Eat & drink'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_ACCOMMODATION');
                  // dispatch(setStepType('CATEGORY_ACCOMMODATION'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="row"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={1}
                >
                  <HotelTwoToneIcon />
                  <Box>{'Stay'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }

                  handleMenuItemClick('CATEGORY_TRANSPORT');
                  // dispatch(setStepType('CATEGORY_TRANSPORT'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="row"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={3}
                >
                  <DirectionsSubwayFilledTwoToneIcon />
                  <Box>{'Transport'}</Box>
                </Stack>
              </Box>
              <Box
                onClick={() => {
                  if (!currentCategory) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                  }
                  handleMenuItemClick('CATEGORY_ACTIVITY');
                  // dispatch(setStepType('CATEGORY_ACTIVITY'));
                  dispatch(setStepDayId(dayId));
                  dispatch(setAddStepModalOpen(true));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <Stack
                  direction="row"
                  spacing={iconsOnly ? 0.5 : 1}
                  width="100%"
                  key={4}
                >
                  <LocalActivityTwoToneIcon />
                  <Box>{'Activity'}</Box>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
      {currentCategory && (
        <AddStepToDay
          dayId={dayId}
          tripType={tripType}
          category={currentCategory}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isDelete && (
        <DeleteDayModal dayId={dayId} handleCloseModal={handleCloseModal} />
      )}
    </Box>
  );
};

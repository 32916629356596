const SuccessIllustration = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width={524.67}
    // height={520.188}
    viewBox="0 0 524.67 520.188"
    {...props}
  >
    <path
      fill="#3f3d56"
      d="M524.67 518.998c0 .66-.53 1.19-1.19 1.19H1.19c-.66 0-1.19-.53-1.19-1.19s.53-1.19 1.19-1.19h522.29c.66 0 1.19.53 1.19 1.19Z"
    />
    <path
      fill="#ffb6b6"
      d="m362.542 461.059 6 25-18 5-4-27 16-3zM391.542 380.059l-19 16-12 19 17 11 29-33-15-13z"
    />
    <path
      fill="#2f2e41"
      d="m385.542 215.059 16 54s71 62 64 75-54 49-54 49 3.488 11.512-6.756 6.756-21.244-17.756-21.244-17.756 0-10 5-8 28-25 28-25l-50-46v153.815s12 10.492 0 11.338c-12 .847-23 2.847-23 2.847s-14-5-9-10l-15-82-11-100 8-52 69-12ZM353.542 483.059s1-4 5-4h12.216s3.784 17 2.784 17 16 22-2 23-23-1-23-1l-2-7.894s-3-5.106-2-9.106 9-18 9-18Z"
    />
    <path
      fill="#2f2e41"
      d="M378.542 418.059s11.485-.181 8.743 2.91c-2.743 3.09-7.743 8.09-7.743 8.09s-11.894 24-17.447 19.5c-5.553-4.5-14.553-54.5-11.553-60.5s7-6 7-6l18.449 11.095s-11.45 27.905 2.55 24.905Z"
    />
    <path fill="#ffb6b6" d="m350.542 130.059 4-25-27-11-4 38 27-2z" />
    <path d="m350.542 130.059 4-25-27-11-4 38 27-2z" opacity={0.1} />
    <path
      fill="#e6e6e6"
      d="m322.042 126.559 32-8 34 11s15 42 10 57-5 44.299-5 44.299-.5-12.8-29.5-3.8-50 8-50 8l-1-36-12.562-31.976c-5.37-13.668.418-29.186 13.426-36l8.636-4.523Z"
    />
    <path
      fill="#ffb6b6"
      d="M468.936 244.361c4.516 5.41 5.385 12.125 1.941 14.998-3.443 2.873-9.893.816-14.41-4.596a17.635 17.635 0 0 1-3.777-7.423l-18.842-23.162 9.178-7.428 19.28 22.565a17.636 17.636 0 0 1 6.63 5.046Z"
    />
    <path
      fill="#e6e6e6"
      d="m386.589 129.878-6.558 25.976 32.097 51.38 32.766 32.93 10.62-8.81-33.045-41.117-25.048-52.978-10.832-7.38z"
    />
    <ellipse
      cx={459.129}
      cy={52.448}
      fill="#6c63ff"
      rx={41.5}
      ry={52.5}
      transform="rotate(-4.226 459.129 52.448)"
    />
    <path fill="#2f2e41" d="M460.129 104.805h2v199.438h-2z" />
    <path
      fill="#6c63ff"
      d="m462.626 100.594.24 7.067 3.194 6.542-13.324-.68 5.6-5.633-.633-6.421 4.923-.875z"
    />
    <circle cx={343.719} cy={85.562} r={27.534} fill="#ffb6b6" />
    <path
      fill="#2f2e41"
      d="M341.521 48.545c.797.465 1.864-.239 2.12-1.125s-.042-1.828-.337-2.702l-1.486-4.4c-1.054-3.12-2.172-6.35-4.454-8.725-3.444-3.585-8.917-4.497-13.844-3.837-6.328.849-12.571 4.275-15.513 9.94-2.942 5.667-1.69 13.573 3.627 17.107-7.577 8.685-10.218 18.363-9.801 29.88.417 11.517 12.968 22.117 21.153 30.23 1.828-1.107 3.49-6.3 2.484-8.186s.435-4.072-.81-5.81-2.286 1.03-1.028-.698c.795-1.09-2.305-3.599-1.124-4.251 5.71-3.156 7.608-10.273 11.194-15.723 4.325-6.573 11.728-11.025 19.562-11.763 4.316-.407 8.874.33 12.412 2.835 3.537 2.505 5.827 6.983 5.007 11.239 2.124-2.157 3.181-5.318 2.783-8.32-.4-3-2.246-5.775-4.86-7.303 1.59-5.256.228-11.302-3.461-15.37s-18.654-3.374-24.04-2.304"
    />
    <path
      fill="#2f2e41"
      d="M340.685 68.641c-7.133.77-12.284 6.95-16.633 12.656-2.507 3.289-5.133 6.92-5.07 11.056.064 4.18 2.859 7.766 4.195 11.728 2.183 6.476.055 14.18-5.142 18.618 5.135.974 10.687-2.877 11.574-8.028 1.032-5.998-3.514-11.786-2.976-17.847.475-5.34 4.683-9.45 8.26-13.444 3.579-3.992 6.939-9.29 5.293-14.393"
    />
    <path
      fill="#fff"
      d="M314.426 234.558H108.758c-23.323 0-42.23 18.907-42.23 42.23V476.22c0 23.323 18.907 42.23 42.23 42.23h205.668c23.323 0 42.23-18.907 42.23-42.23V276.788c0-23.323-18.907-42.23-42.23-42.23Z"
    />
    <path
      fill="#3f3d56"
      d="M314.426 519.45H108.758c-23.837 0-43.23-19.392-43.23-43.23V276.789c0-23.837 19.393-43.23 43.23-43.23h205.668c23.837 0 43.23 19.393 43.23 43.23v199.433c0 23.837-19.393 43.23-43.23 43.23ZM108.758 235.558c-22.735 0-41.23 18.496-41.23 41.23v199.433c0 22.734 18.495 41.23 41.23 41.23h205.668c22.735 0 41.23-18.496 41.23-41.23V276.788c0-22.734-18.495-41.23-41.23-41.23H108.758Z"
    />
    <circle cx={292.725} cy={249.408} r={4.769} fill="#3f3d56" />
    <circle cx={305.84} cy={249.408} r={4.769} fill="#3f3d56" />
    <circle cx={318.955} cy={249.408} r={4.769} fill="#3f3d56" />
    <path
      fill="#e6e6e6"
      d="M102.015 448.63a2.69 2.69 0 0 0-2.687 2.688c0 .722.28 1.39.786 1.884.51.523 1.18.803 1.901.803h215.099a2.69 2.69 0 0 0 2.687-2.687c0-.723-.28-1.39-.786-1.884a2.624 2.624 0 0 0-1.901-.803H102.015Z"
    />
    <path
      fill="#6c63ff"
      d="M217.03 447.735v7.165H102.015c-.985 0-1.88-.394-2.525-1.056a3.502 3.502 0 0 1-1.057-2.526 3.593 3.593 0 0 1 3.582-3.583H217.03Z"
    />
    <path
      fill="#e6e6e6"
      d="M312.636 434.3H276.81c-3.95 0-7.165-3.213-7.165-7.164s3.214-7.166 7.165-7.166h35.826c3.95 0 7.165 3.214 7.165 7.166s-3.215 7.165-7.165 7.165Z"
    />
    <path
      fill="#2f2e41"
      d="M178.044 439.422 153 355.274l-1.917.57 24.956 83.852"
    />
    <ellipse
      cx={175.042}
      cy={195.559}
      fill="#6c63ff"
      rx={41.5}
      ry={52.5}
      transform="rotate(-4.226 175.042 195.559)"
    />
    <path fill="#2f2e41" d="M176.042 247.916h2v199.438h-2z" />
    <path
      fill="#6c63ff"
      d="m154.126 352.836 2.245 6.705 4.927 5.36-12.965 3.148 3.762-6.997-2.438-5.974 4.469-2.242zM178.54 243.705l.24 7.067 3.193 6.542-13.324-.68 5.6-5.633-.632-6.422 4.923-.874z"
    />
    <ellipse
      cx={136.042}
      cy={305.559}
      fill="#6c63ff"
      rx={41.5}
      ry={52.5}
      transform="rotate(-20.934 136.042 305.559)"
    />
    <path
      fill="#ffb6b6"
      d="M336.51 224.322c6.745 2.036 11.162 7.168 9.865 11.46-1.296 4.293-7.815 6.121-14.563 4.083a17.635 17.635 0 0 1-7.236-4.124l-28.473-8.99 3.584-11.25 28.51 8.25a17.636 17.636 0 0 1 8.312.57Z"
    />
    <path
      fill="#e6e6e6"
      d="m287.042 150.559 30-22-1 42-28 36 30 12-1 17.134s-38-9.134-44-10.134-11-15-11-15l25-60Z"
    />
  </svg>
);
export default SuccessIllustration;

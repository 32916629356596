import { Divider, Box } from '@mui/material';
import { Fragment } from 'react';

import { Country, Recommendation } from '../../generated/public_graphql';
import { VerticalFlex, HorizontalFlex } from '../helpers/flex';

import {
  SideBarDescription,
  SideBarHeader,
  SideBarIcon,
  SideBarLink,
  SideBarSubHeader,
  SideBarTitleDivider,
} from './TripSidebar';

interface Props {
  readonly countries: [Country];
}

export function TripTipsBeforeYouGo(props: Props) {
  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.countries[0];

  return (
    <VerticalFlex>
      <HorizontalFlex>
        <SideBarIcon $iconType={'TIPS'} />
        <SideBarHeader>{'Before you go'}</SideBarHeader>
      </HorizontalFlex>
      <SideBarTitleDivider />

      {country &&
        country.recommendations &&
        country.recommendations.map((recommendation, i) => {
          return (
            <Fragment key={'vaccination' + i}>
              {recommendation &&
                recommendation.type !== 'RECOMMENDATION_VISAS' &&
                recommendation.type !== 'RECOMMENDATION_VACCINATION' &&
                // recommendation.type !== 'RECOMMENDATION_MEDICATION' &&
                recommendation.type !== 'RECOMMENDATION_HEALTH' && (
                  <RecommendationDetails recommendation={recommendation} />
                )}
            </Fragment>
          );
        })}
    </VerticalFlex>
  );
}

function RecommendationDetails(props: { recommendation: Recommendation }) {
  return (
    <VerticalFlex>
      <SideBarSubHeader>{props.recommendation.name}</SideBarSubHeader>

      {props.recommendation.description && (
        <SideBarDescription>
          {props.recommendation.description}
        </SideBarDescription>
      )}
      {props.recommendation.url && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <SideBarLink url={props.recommendation.url} copy={'More info'} />
        </Box>
      )}
      <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
    </VerticalFlex>
  );
}

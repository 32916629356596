import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import { Box, Stack, IconButton, Drawer, Typography } from '@mui/material';
import { useState } from 'react';

import { TripMap, TripMapProps } from './TripMap';

export function MapDrawer({
  tripType,
  onMarkerClick,
  onCloseClick,
}: TripMapProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(prev => !prev);
  };

  return (
    <div>
      <Box
        sx={theme => ({
          position: 'fixed',
          top: '55px',
          right: '0px', // Touch the right edge
          padding: 0.5, // Larger padding to increase size
          zIndex: 5000,
          display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          flexDirection: 'column', // Align icons and titles vertically
          backgroundColor: '#f0f4f8', // Use a light color that blends with the app (adjust to your theme)
          color: '#1c3a57', // Match the text color with your theme
          fontWeight: 'bold', // Make the text more prominent
          borderTopLeftRadius: '12px', // Larger radius for a smoother corner
          borderBottomLeftRadius: '12px', // Same as above
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
          border: '1px solid #dfe3e8', // Optional: subtle border to match theme
          '&:hover': {
            backgroundColor: '#e0ebf5', // Slightly darker on hover
          },
        })}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={theme => ({
            // backgroundColor: theme.palette.background.paper, // Using theme color for background
            display: { xs: 'flex', sm: 'none' }, // Show only on small screens
            backgroundColor: '#f0f4f8',
          })}
        >
          {isDrawerOpen ? (
            <Stack direction="row" spacing={1}>
              <ListIcon sx={{ color: '#37474F' }} />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                  color: '#1C3A57',
                })}
              >
                Itinerary
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1}>
              <MapIcon sx={{ color: '#37474F' }} />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                  color: '#1C3A57',
                })}
              >
                Map
              </Typography>
            </Stack>
          )}
        </IconButton>
      </Box>
      {/* Drawer Component */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{ width: '100%' }} // Set the width of the drawer
      >
        <Box
          sx={theme => ({
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          {/* Placeholder for Mapbox */}
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#e0e0e0', // Light gray background
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            <TripMap
              tripType={tripType}
              onMarkerClick={onMarkerClick}
              onCloseClick={onCloseClick}
              openOnMobile={isDrawerOpen}
            />
          </div>
        </Box>
      </Drawer>
    </div>
  );
}

export default MapDrawer;

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { RoutePage } from '../components/route';
import PublicTrip from '../components/trip-details/Trip';
import { isRunningStandalone } from '../utils/helpers';

export default function TripRoute() {
  gtag('event', 'public-trip-page');
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  return (
    <RoutePage>
      <PublicTrip />
      {isStandalone && (
        <Box
          sx={{
            paddingBottom: 'calc(70px + env(safe-area-inset-bottom))',
          }}
        />
      )}
    </RoutePage>
  );
}

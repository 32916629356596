import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import MapIcon from '@mui/icons-material/Map';
import { Box, Grid, Link, styled } from '@mui/material';
import { useRef } from 'react';

import { Maybe, Recommendation } from '../../generated/public_graphql';
import { Body1, TitleH5 } from '../../theme-components/Typography';
import { openLinkInBrowserNewTab } from '../../utils/helpers';

interface RecommendationProps {
  readonly recommentations: Maybe<Recommendation>[];
}

export function StepRecommendations(props: RecommendationProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {props.recommentations &&
        props.recommentations.length > 0 &&
        props.recommentations.map((recommendation, i) => {
          return (
            <div key={i}>
              {recommendation && (
                <Box
                  sx={{
                    background:
                      'linear-gradient(180deg, #FAFAFA 0%, #E9D7FC 100%)',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                    marginBottom: 2,
                  }}
                >
                  <TitleH5>{recommendation.name}</TitleH5>
                  {recommendation.latitude && recommendation.longitude && (
                    <Box
                      sx={theme => ({
                        marginBottom: theme.spacing(0.5),
                        marginTop: theme.spacing(0.5),
                        alignItems: 'center',
                        display: 'flex',
                      })}
                    >
                      <DirectionsIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      {/* FIXME add this properly
                      <ExternalLinkNavigation
                        lat={recommendation.latitude}
                        lng={recommendation.longitude}
                        copy={'Take me there'}
                      /> */}
                    </Box>
                  )}
                  {recommendation.locationUrl && (
                    <Box
                      sx={theme => ({
                        marginBottom: theme.spacing(0.5),
                        marginTop: theme.spacing(0.5),
                        alignItems: 'center',
                        display: 'flex',
                      })}
                    >
                      <MapIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkMapsUrl
                        url={recommendation.locationUrl}
                        copy={'Open in Google Maps'}
                      />
                    </Box>
                  )}
                  {recommendation && recommendation.url && (
                    <Box
                      sx={theme => ({
                        marginBottom: theme.spacing(0.5),
                        marginTop: theme.spacing(0.5),
                        alignItems: 'center',
                        display: 'flex',
                      })}
                    >
                      <EventTwoToneIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkBooking
                        url={recommendation.url}
                        copy={'Get more info'}
                      />
                    </Box>
                  )}
                  <Body1>{recommendation.description}</Body1>
                </Box>
              )}
            </div>
          );
        })}
    </Box>
  );
}

export function ExternalLinkMapsUrl(props: { url: string; copy: string }) {
  return (
    <Link
      onClick={() => {
        openLinkInBrowserNewTab(props.url);
        gtag('event', 'external-link-maps-click');
      }}
      sx={theme => ({
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: '#6E7191',
        '&:hover': {
          textDecoration: 'none',
        },
      })}
    >
      {props.copy}
    </Link>
  );
}

export function ExternalLinkBooking(props: { url: string; copy: string }) {
  return (
    <Link
      onClick={() => {
        openLinkInBrowserNewTab(props.url);
        gtag('event', 'external-link-maps-click');
      }}
      sx={theme => ({
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: theme.palette.primary.light,
        '&:hover': {
          textDecoration: 'none',
        },
      })}
    >
      {props.copy}
    </Link>
  );
}

export function SideScrollRecommendations(props: RecommendationProps) {
  const contentWrapper = useRef<HTMLDivElement | null>(null);

  return (
    <Box sx={{ marginTop: 2 }}>
      <TitleH5>What's nearby</TitleH5>
      <StyledGrid container ref={contentWrapper}>
        {props.recommentations &&
          props.recommentations.length > 0 &&
          props.recommentations.map((recommendation, index) => (
            <StyledCard key={index} boxShadow={1}>
              <TitleH5>{recommendation!.name}</TitleH5>
              {recommendation!.latitude && recommendation!.longitude && (
                <Box
                  sx={theme => ({
                    marginBottom: theme.spacing(0.5),
                    marginTop: theme.spacing(0.5),
                    alignItems: 'center',
                    display: 'flex',
                  })}
                >
                  <DirectionsIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 1,
                      color: theme.palette.icon.main,
                    })}
                  />
                  {/* FIXME add this properly
                  <ExternalLinkNavigation
                    lat={recommendation!.latitude}
                    lng={recommendation!.longitude}
                    copy={'Take me there'}
                  /> */}
                </Box>
              )}
              {recommendation?.locationUrl && (
                <Box
                  sx={theme => ({
                    marginBottom: theme.spacing(0.5),
                    marginTop: theme.spacing(0.5),
                    alignItems: 'center',
                    display: 'flex',
                  })}
                >
                  <MapIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 1,
                      color: theme.palette.icon.main,
                    })}
                  />
                  <ExternalLinkMapsUrl
                    url={recommendation.locationUrl}
                    copy={'Open in Google Maps'}
                  />
                </Box>
              )}
              {recommendation && recommendation.url && (
                <Box
                  sx={theme => ({
                    marginBottom: theme.spacing(0.5),
                    marginTop: theme.spacing(0.5),
                    alignItems: 'center',
                    display: 'flex',
                  })}
                >
                  <EventTwoToneIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 1,
                      color: theme.palette.icon.main,
                    })}
                  />
                  <ExternalLinkBooking
                    url={recommendation.url}
                    copy={'Get more info'}
                  />
                </Box>
              )}
              <Box
                sx={theme => ({
                  whiteSpace: 'normal',
                  textOverflow: 'ellipsis',
                })}
              >
                <Body1>{recommendation!.description}</Body1>
              </Box>
              {/* </Box> */}
            </StyledCard>
          ))}
      </StyledGrid>
      {props.recommentations && props.recommentations.length > 1 && (
        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
          <ScrollButtons>
            <Box
              onClick={() => sideScroll(contentWrapper!.current!, 1, 420, -5)}
            >
              <ButtonBackground>
                <ChevronLeftIcon />
              </ButtonBackground>
            </Box>
            <ButtonBackground
              onClick={() => sideScroll(contentWrapper!.current!, 1, 420, 5)}
            >
              <ChevronRightIcon />
            </ButtonBackground>
          </ScrollButtons>
          <Box height={200} />
        </Box>
      )}
    </Box>
  );
}

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number,
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);

  // Return a cleanup function
  return () => clearInterval(slideTimer);
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  overflowY: 'hidden',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 8,
  },
}));

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  background: 'linear-gradient(180deg, #FAFAFA 0%, #E9D7FC 100%)',
  marginRight: 10,
  marginLeft: 10,
  padding: 8,
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto', // Enable vertical scrolling within the card
  flexShrink: 0, // Prevent the card from shrinking in size
  width: 400,
  height: 250,
  [theme.breakpoints.down('sm')]: {
    width: 330,
    height: 220,
  },
}));

const ScrollButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: -150,
  marginBottom: -80,
  justifyContent: 'space-between',
}));

const ButtonBackground = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  margin: 5,
  backgroundColor: '#FFF3DB',
  borderRadius: 20,
  position: 'relative',
  padding: 4,
}));

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AccommodationsState {
  accommodationId: string;
  accommodationDescription: string;
  accommodationLatitude: number;
  accommodationLongitude: number;
  accommodationLocationUrl: string;
  accommodationUrl: string;
  accommodationDescriptionAdded: boolean;
  accommodationLocationAdded: boolean;
  accommodationBudget: string;
  accommodationName: string;
}

const initialState: AccommodationsState = {
  accommodationId: '',
  accommodationDescription: '',
  accommodationLatitude: 0,
  accommodationLongitude: 0,
  accommodationLocationUrl: '',
  accommodationUrl: '',
  accommodationDescriptionAdded: false,
  accommodationLocationAdded: false,
  accommodationBudget: '',
  accommodationName: '',
};

const accommodationSlice = createSlice({
  name: 'accommodation',
  initialState,
  reducers: {
    resetAccommodation: () => initialState,
    setAccommodationId: (state, { payload }: PayloadAction<string>) => {
      state.accommodationId = payload;
    },
    setAccommodationDescription: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.accommodationDescription = payload;
    },
    setAccommodationLocationUrl: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.accommodationLocationUrl = payload;
    },
    setAccommodationUrl: (state, { payload }: PayloadAction<string>) => {
      state.accommodationUrl = payload;
    },
    setAccommodationLatitude: (state, { payload }: PayloadAction<number>) => {
      state.accommodationLatitude = payload;
    },
    setAccommodationLongitude: (state, { payload }: PayloadAction<number>) => {
      state.accommodationLongitude = payload;
    },
    setAccommodationDescriptionAdded: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.accommodationDescriptionAdded = payload;
    },
    setAccommodationLocationAdded: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.accommodationLocationAdded = payload;
    },
    setAccommodationBudget: (state, { payload }: PayloadAction<string>) => {
      state.accommodationBudget = payload;
    },
    setAccommodationName: (state, { payload }: PayloadAction<string>) => {
      state.accommodationName = payload;
    },
  },
});

export const {
  resetAccommodation,
  setAccommodationId,
  setAccommodationDescription,
  setAccommodationLocationUrl,
  setAccommodationUrl,
  setAccommodationLatitude,
  setAccommodationLongitude,
  setAccommodationDescriptionAdded,
  setAccommodationLocationAdded,
  setAccommodationBudget,
  setAccommodationName,
} = accommodationSlice.actions;

export default accommodationSlice.reducer;

export const accommodationsSelector = (state: {
  userAddingAccommodation: AccommodationsState;
}) => state.userAddingAccommodation;

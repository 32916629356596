import { Box, Stack } from '@mui/material';

import { CountryTrips } from '../../generated/public_graphql';
import { TitleH2 } from '../../theme-components/Typography';
import { GradientContainer } from '../home/HomeHeader';
import { SideBarIcon } from '../trip-details/TripSidebar';
import { RenderTrips } from '../user-account/UserTrips';

export function CountriesTrips(props: {
  countryTrips: CountryTrips;
  gradient: string;
}) {
  return (
    <Box
      width="100%"
      sx={theme => ({
        marginTop: 2,
        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
        },
      })}
    >
      <GradientContainer gradient={props.gradient}>
        <Stack direction={'row'}>
          <SideBarIcon $iconType={props.countryTrips.country!.iso2Code} />
          <TitleH2>{props.countryTrips.country?.name}</TitleH2>
        </Stack>
        <RenderTrips
          trips={
            props.countryTrips.countryTrips
              ? props.countryTrips.countryTrips
              : []
          }
          publicTrip={true}
        />
      </GradientContainer>
    </Box>
  );
}

export interface QueryParams {
  [key: string]: string | string[];
}

export function decodeQueryParams(queryString: string) {
  const searchParams = new URLSearchParams(queryString);
  const result: QueryParams = {};

  // Display the key/value pairs
  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key).filter(Boolean);
    if (values.length) {
      result[key] = values.length > 1 ? values : values[0];
    }
  }

  return result;
}

export function encodeQueryParams(queryParams: QueryParams) {
  const searchParams = new URLSearchParams('');

  for (const [key, values] of Object.entries(queryParams)) {
    if (typeof values === 'string') {
      searchParams.append(key, values);
    } else {
      for (const value of values) {
        searchParams.append(key, value);
      }
    }
  }

  const result = searchParams.toString();

  return result ? `?${result}` : '';
}

export function getQueryParam(queryString: string, key: string) {
  const queryParams = decodeQueryParams(queryString);
  if (queryParams[key]) {
    return queryParams[key];
  }
  return '';
}

import { configureStore } from '@reduxjs/toolkit';
import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom/client';
import { MapProvider } from 'react-map-gl';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { App } from './components/App';
import { AppWrapper } from './components/AppWrapper';
import { AuthProvider } from './components/auth/AuthWrapper';
import { firebaseConfig } from './components/auth/firebase';
import ErrorModal from './components/Error';
import ErrorBoundary from './components/ErrorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import accommodationSliceReducer from './store/AccommodationSlice';
import daysSliceReducer from './store/DaySlice';
import errorSliceReducer from './store/ErrorSlice';
import { likedPlacesSliceReducer } from './store/LikedPlacesSlice';
import { publicTripSliceReducer } from './store/PublicTripSlice';
import stepsSliceReducer from './store/StepSlice';
import transportSliceReducer from './store/TransportSlice';
import { tripSliceReducer } from './store/TripSlice';
import { userSliceReducer } from './store/UserSlice';
import UnauthorizedApolloProvider from './UnAuthorizedApolloProvider';

const store = configureStore({
  reducer: {
    publicTrip: publicTripSliceReducer,
    userLikedPlaces: likedPlacesSliceReducer,
    userTrip: tripSliceReducer,
    userTripDays: daysSliceReducer,
    userAddingStep: stepsSliceReducer,
    userAddingAccommodation: accommodationSliceReducer,
    errorModal: errorSliceReducer,
    user: userSliceReducer,
    transport: transportSliceReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

// Initialise FB
initializeApp(firebaseConfig);

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ErrorModal />
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <UnauthorizedApolloProvider>
            <AppWrapper>
              <MapProvider>
                <App />
              </MapProvider>
            </AppWrapper>
          </UnauthorizedApolloProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  </Provider>,
);

// Register the service worker
serviceWorkerRegistration.register();

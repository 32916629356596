import { Card, CardMedia, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';

import { Maybe, Image, StepPlaceImage } from '../../generated/public_graphql';
import { PlaceImage } from '../../generated/user_graphql';
import { ImageCard } from '../create-trip/edit-trip/StepImages';

export function UserStepSingleImage(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={300}
      offset={350}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <UserImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </LazyLoad>
  );
}

export function UserStepImages(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');

  return (
    <LazyLoad
      height={200}
      offset={350}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </LazyLoad>
  );
}

export function StepPlaceImages(props: { images: Maybe<StepPlaceImage>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={350}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </LazyLoad>
  );
}

export function StepPlaceSingleImage(props: {
  images: Maybe<StepPlaceImage>[];
}) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={300}
      offset={350}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <ImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </LazyLoad>
  );
}

export function PlaceImages(props: { images: Maybe<PlaceImage>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={350}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </LazyLoad>
  );
}

export function PlaceSingleImage(props: { images: Maybe<PlaceImage>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={300}
      offset={350}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <ImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </LazyLoad>
  );
}

const UserImageCard = ({ image, index }) => {
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            maxHeight: 300,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}

      <CardMedia
        component="img"
        src={image!.path!}
        onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
        sx={{
          maxHeight: 300,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
        }}
      />
    </Card>
  );
};

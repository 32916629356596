import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../store/ErrorSlice';
import { PrimaryButton, SecondaryButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import { MUTATION_PUBLISH_TRIP } from '../gql-user/publishTrip';
import {
  CenteredModal,
  MediumModalPaper,
  ModalDescription,
  ModalPaper,
  ModalTitle,
} from '../styling/modal';
import ModalHeader from '../../theme-components/ModalComponents';
import Space from '../../theme-components/Spacing';

export default function UnpublishTripModal(props: {
  tripId: string;
  tripType: string | undefined;
  handleCloseModal: () => void;
}) {
  const dispatch = useDispatch();

  const [publishTrip, { loading }] = useMutation(MUTATION_PUBLISH_TRIP, {
    variables: {
      trip_id: props.tripId,
      publish: false,
    },
  });

  const closeModal = () => {
    props.handleCloseModal();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ModalHeader
          title="Oh no!"
          description={
            !props.tripType || props.tripType !== 'guide' ? (
              <> {'We are sad for this trip to go hidden.'}</>
            ) : (
              <> {'We are sad for this guide to go hidden.'}</>
            )
          }
        />
        <Space size="md" />
        <PrimaryButton
          disabled={loading}
          onClick={async () => {
            gtag('event', 'publish-trip-confirm');
            try {
              const res = await publishTrip();
              if (res && res.data && res.data.publishUserTrip) {
                // dispatch(setTrip(res.data.publishUserTrip));
                closeModal();
              }
            } catch (e) {
              gtag('event', 'error-publishing-trip');
              dispatch(setErrorTitle('Error publishing trip'));
              dispatch(setErrorModalOpen(true));
            }
          }}
        >
          {loading ? <Loader /> : <>Unpublish</>}
        </PrimaryButton>
        <Space size="md" />
        <SecondaryButton
          disabled={loading}
          onClick={async () => {
            gtag('event', 'publish-trip-close');
            closeModal();
          }}
        >
          Close
        </SecondaryButton>
      </ModalPaper>
    </CenteredModal>
  );
}

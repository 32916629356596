import { gql } from 'graphql-tag';

export const MUTATION_UPSERT_SOCIAL_LINK = gql`
  mutation upsertSocialLink(
    $source_trip_id: String!
    $social_type: String!
    $step_id: String
    $day_id: String
    $trip_id: String
    $url_link: String
    $hashtag: String
    $search_term: String
  ) {
    upsertSocialLink(
      source_trip_id: $source_trip_id
      social_type: $social_type
      step_id: $step_id
      day_id: $day_id
      trip_id: $trip_id
      url_link: $url_link
      hashtag: $hashtag
      search_term: $search_term
    ) {
      id
      name
      description
      length
      archived
      startDate
      published
      tripType
      publish_pending
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        countryName
        itineraryId
      }
      cities {
        id
        name
      }
      images {
        id
        path
        name
        header
        main
        secondary
        tertiary
      }
      itinerary {
        id
        days {
          id
          dayNumber
          cities {
            cityName
          }
          steps {
            id
            title
            type
            description
            stepNumber
            latitude
            longitude
            locationUrl
            duration
            url
            city
            country
            countryCode
            region
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
            socialLinks {
              id
              socialType
              urlLink
              hashtag
              searchTerm
            }
            placeCategory
            placeImages {
              path
              id
              title
              contributorId
              imageUrl
            }
            place {
              id
              lat
              lng
              city
              country
              countryCode
              region
              externalPlaceId
              title
              category
              noOfReviews
              rating
              description
              quickFact
              phoneNumber
              website
              address
              aiDescription
              checkInTime
              checkOutTime
              placeType
              dataSource
              bookingLink
              openingHours {
                weekDay
                hours
              }
              images {
                title
                path
                contributorId
                imageUrl
              }
            }
            accommodations {
              id
              name
              description
              url
              budget
              latitude
              longitude
              locationUrl
              images {
                id
                path
                header
                main
                secondary
                tertiary
              }
            }
            recommendations {
              id
              name
              type
              description
              url
              latitude
              longitude
              locationUrl
            }
          }
        }
        coordinates {
          id
          title
          latitude
          longitude
          step {
            id
            title
            type
            description
            stepNumber
            latitude
            longitude
            locationUrl
            duration
            url
            city
            country
            countryCode
            region
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
            socialLinks {
              id
              socialType
              urlLink
              hashtag
              searchTerm
            }
            placeCategory
            placeImages {
              path
              id
              title
              contributorId
              imageUrl
            }
            place {
              id
              lat
              lng
              city
              country
              countryCode
              region
              externalPlaceId
              title
              category
              noOfReviews
              rating
              description
              quickFact
              phoneNumber
              website
              address
              aiDescription
              checkInTime
              checkOutTime
              placeType
              dataSource
              bookingLink
              openingHours {
                weekDay
                hours
              }
              images {
                title
                path
                contributorId
                imageUrl
              }
            }
            accommodations {
              id
              name
              description
              url
              budget
              latitude
              longitude
              locationUrl
              images {
                id
                path
                header
                main
                secondary
                tertiary
              }
            }
            recommendations {
              id
              name
              type
              description
              url
              latitude
              longitude
              locationUrl
            }
          }
        }
      }
    }
  }
`;

// transportSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the structure of a transport step
// Define your TransportState type as you have it
interface TransportStep {
  stepId: string;
  transportTime: number;
}

interface TransportState {
  transportByDay: {
    [dayId: string]: {
      steps: TransportStep[];
      totalTransportTime: number;
    };
  };
}

// Define the overall RootState type which includes all slices
export interface RootState {
  transport: TransportState;
  // Include other slices if you have them
}

const initialState: TransportState = {
  transportByDay: {},
};

const transportSlice = createSlice({
  name: 'transport',
  initialState,
  reducers: {
    addTransport: (
      state,
      action: PayloadAction<{
        dayId: string;
        stepId: string;
        transportTime: number;
      }>,
    ) => {
      const { dayId, stepId, transportTime } = action.payload;

      // Initialize if not present
      if (!state.transportByDay[dayId]) {
        state.transportByDay[dayId] = { steps: [], totalTransportTime: 0 };
      }

      // Check if the step ID already exists for the day
      const existingStepIndex = state.transportByDay[dayId].steps.findIndex(
        step => step.stepId === stepId,
      );

      if (existingStepIndex >= 0) {
        // Update existing transport time
        state.transportByDay[dayId].totalTransportTime -=
          state.transportByDay[dayId].steps[existingStepIndex].transportTime; // Remove old time
        state.transportByDay[dayId].steps[existingStepIndex].transportTime =
          transportTime; // Update time
        state.transportByDay[dayId].totalTransportTime += transportTime; // Add new time
      } else {
        // Otherwise, add a new transport step
        state.transportByDay[dayId].steps.push({ stepId, transportTime });
        state.transportByDay[dayId].totalTransportTime += transportTime; // Update total time
      }
    },
  },
});

// Selector to get total transport time for a specific day
export const selectTotalTransportTimeByDayId = (
  state: RootState, // Use the defined RootState type
  dayId: string,
) => {
  return state.transport.transportByDay[dayId]?.totalTransportTime || 0; // Return total time or 0 if not found
};

// Selector to get transport data by day
export const selectTransportByDay = (state: RootState) =>
  state.transport.transportByDay;

// Export actions and reducer
export const { addTransport } = transportSlice.actions;
export default transportSlice.reducer;

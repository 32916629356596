import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import {
  Box,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Typography,
  Link,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextButton } from '../theme-components/Buttons';
import { isRunningStandalone } from '../utils/helpers';
import { RoutePage } from './route';
import { LogoName } from './AppBar';
import Space from '../theme-components/Spacing';

export default function AppFooter() {
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  return (
    <>
      {isStandalone ? (
        <>
          <BottomTabBar />
        </>
      ) : (
        // <Box
        //   boxShadow={2}
        //   sx={{ position: 'absolute', width: '100%', height: 100 }}
        // >
        //   <Container>
        //     <Box sx={{ paddingTop: 2, textAlign: 'center' }}>
        //       © 2024 rooutie.com &middot; All rights reserved
        //     </Box>
        //     <Box
        //       sx={theme => ({
        //         color: theme.palette.secondary.main,
        //         textAlign: 'center',
        //         paddingBottom: 2,
        //       })}
        //     >
        //       <ContactMeGate />
        //     </Box>
        //   </Container>
        // </Box>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            backgroundColor: '#f9f9f9',
          }}
        >
          <RoutePage>
            <Divider
              sx={{
                width: '100%',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start', // Align items to the top initially
                paddingBottom: 2,
              }}
            >
              {/* Company Address on the left */}
              <Box
                sx={theme => ({
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#595959',
                  maxWidth: '60%',
                })}
              >
                <LogoName>rooutie</LogoName>
                <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                  71-75, Shelton Street, Covent Garden, London, WC2H 9JQ
                </Typography>
                <Space size="xs" />
                <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                  © 2024 ROOUTIE LTD
                </Typography>
              </Box>

              {/* Centered copyright text */}

              {/* Privacy Policy, Terms of Use, and Contact on the right */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left', // Align the text to the left
                }}
              >
                <Typography
                  variant="h6" // Use Typography component for consistent heading style
                  sx={{
                    fontWeight: 'bold',
                    color: '#3F3D56',
                  }}
                >
                  About
                </Typography>
                <Space size="xs" />
                <Link
                  href="/privacy"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Privacy Policy
                </Link>
                <Space size="xs" />
                <Link
                  href="/terms"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Terms of Use
                </Link>
                <Space size="xs" />
                <Link
                  href="mailto:help@rooutie.com"
                  sx={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Contact Us
                </Link>
              </Box>
            </Box>
          </RoutePage>
        </Box>
      )}
    </>
  );
}
// TODO: fix this in footer
// interface IContactMeGateProps {}
// interface IContactMeHrefProps {}

const ContactMeHref: React.FC<unknown> = props => {
  return (
    <div>
      <a href="mailto:info@rooutie.com">info@rooutie.com</a>
    </div>
  );
};

// Make user click a button to show email adderss via lazy loading
const ContactMeGate: React.FC<unknown> = props => {
  const [showingEmail, setShowingEmail] = React.useState(false);

  const email = showingEmail ? (
    <ContactMeHref />
  ) : (
    <TextButton onClick={() => setShowingEmail(true)}>
      Click for contact info
    </TextButton>
  );
  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <div>{email}</div>
    </React.Suspense>
  );
};

const BottomTabBar = () => {
  const [value, setValue] = useState(0); // State to manage active tab
  const navigate = useNavigate(); // React Router navigation hook

  // Handle tab change and navigation
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate('/explore'); // Navigate to "My Account" (Home)
    } else if (newValue === 1) {
      navigate('/my-trips'); // Navigate to "Main" (Explore)
    } else if (newValue === 2) {
      navigate('/my-favourite'); // Navigate to "Help"
    } else if (newValue === 3) {
      navigate('/my-account'); // Navigate to "Help"
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 960,
        paddingTop: 1,
        backgroundColor: '#FFFFFF',
        overflow: 'visible',
        paddingBottom: 'calc(22px + env(safe-area-inset-bottom))',
        borderTop: '1px solid', // Add top border
        borderColor: 'rgba(0, 0, 0, 0.12)', // Customize the color of the border
      }}
    >
      <BottomNavigation
        value={value}
        onChange={handleChange}
        // sx={{
        //   paddingTop: 1,
        //   paddingBottom: 'calc(14px + env(safe-area-inset-bottom))',
        // }}
      >
        <BottomNavigationAction
          label="Explore"
          showLabel={true}
          icon={<TravelExploreTwoToneIcon sx={{ height: 30, width: 30 }} />}
        />
        <BottomNavigationAction
          label="My trips"
          showLabel={true}
          icon={<CardTravelTwoToneIcon sx={{ height: 30, width: 30 }} />}
        />
        <BottomNavigationAction
          label="Favourite"
          showLabel={true}
          icon={<FavoriteTwoToneIcon sx={{ height: 30, width: 30 }} />}
        />
        <BottomNavigationAction
          label="Account"
          showLabel={true}
          icon={<AccountCircleTwoToneIcon sx={{ height: 30, width: 30 }} />}
        />
      </BottomNavigation>
    </Box>
  );
};

import { gql } from 'graphql-tag';

export const MUTATION_TRANSPORT_STEP_DETAILS = gql`
  mutation getDirections($start_step_id: String!, $end_step_id: String!) {
    getDirections(start_step_id: $start_step_id, end_step_id: $end_step_id) {
      startStepId
      endStepId
      transportType
      durationMinutes
      distanceMetres
    }
  }
`;

import CloseIcon from '@mui/icons-material/Close';
import DirectionsIcon from '@mui/icons-material/Directions';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import PhoneIcon from '@mui/icons-material/Phone';
import StarIcon from '@mui/icons-material/Star';
import {
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  Stack,
  Link,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Map, Marker } from 'react-map-gl'; // Import Popup
import { useSelector } from 'react-redux';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Step } from '../../generated/public_graphql';
import {
  Day,
  Itinerary,
  ItineraryCoordinates,
  Maybe,
} from '../../generated/user_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { Body1, TitleH4 } from '../../theme-components/Typography';
import {
  formatNumber,
  getMapUrls,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import { ImageCard } from '../create-trip/edit-trip/StepImages';
import GoogleIcon from '../icons/GoogleIcon';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';

import { AddToTripButton } from './AddToTrip';
import { TodaysHours } from './Hours';
import { ResponsiveChip } from './PublicPlaceCard';

type TripType = 'public' | 'user' | 'preview';

export type TripMapProps = {
  tripType: TripType;
  onMarkerClick?: (stepId: string) => void;
  onCloseClick?: () => void;
  openOnMobile?: boolean;
};

export function TripMap({
  tripType,
  onMarkerClick,
  onCloseClick,
  openOnMobile,
}: TripMapProps) {
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const trip = tripType === 'user' ? userTrip : publicTrip;
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedDay, setSelectedDate] = useState('all');

  const boundingBox = useMemo(() => {
    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length > 0
    ) {
      if (selectedDay === 'all') {
        return calculateBoundingBox(trip.itinerary.coordinates);
      } else {
        const coordinates = getCoordinatesForDay(
          trip.itinerary.days!,
          trip.itinerary.coordinates,
          selectedDay,
        );
        return calculateBoundingBox(coordinates);
      }
    }
    return [
      [0, 0],
      [0, 0],
    ];
  }, [trip, selectedDay]);

  const [viewport, setViewport] = useState({
    latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
    longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
    zoom: 10,
    width: '100%',
    height: '100%',
  });

  const fitBounds = useCallback(
    map => {
      if (boundingBox) {
        map.fitBounds(boundingBox, {
          padding: { top: 100, bottom: 20, left: 20, right: 20 },
          duration: 2000,
        });
      }
    },
    [boundingBox],
  );

  useEffect(() => {
    if (mapInstance && boundingBox) {
      fitBounds(mapInstance);
    }
  }, [mapInstance, boundingBox, fitBounds]);

  const [selectedPlace, setSelectedPlace] = useState<{ step: Step } | null>(
    null,
  );
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  );

  const [mapWidth, setMapWidth] = useState(0);
  const [mapPosition, setMapPosition] = useState(0);
  const [mapVisiblePercentage, setMapVisiblePercentage] = useState(0);
  const [distanceFromBottom, setDistanceFromBottom] = useState(0);
  const mapRef = useRef<HTMLDivElement>(null);

  const calculateMapPosition = () => {
    if (mapRef.current) {
      const rect = mapRef.current.getBoundingClientRect();

      const leftPosition = rect.left;
      const mapWidth = rect.width;

      setMapPosition(leftPosition);
      setMapWidth(mapWidth);

      const visibleHeight =
        Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
      const visibleHeightClamped = Math.max(0, visibleHeight);
      const visibilityPercentage = (visibleHeightClamped / rect.height) * 100;

      setMapVisiblePercentage(visibilityPercentage);
      const distanceFromBottom = window.innerHeight - rect.bottom;
      setDistanceFromBottom(distanceFromBottom);
    }
  };

  useEffect(() => {
    // Call on mount
    calculateMapPosition();
    // Listen for resize events
    window.addEventListener('resize', calculateMapPosition);
    window.addEventListener('scroll', calculateMapPosition);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', calculateMapPosition);
      window.addEventListener('scroll', calculateMapPosition);
    };
  }, []); // Empty dependency array to run only on mount and when resized

  // Load the map only once on mount
  const handleMapLoad = event => {
    setMapInstance(event.target);
    fitBounds(event.target);
  };

  useEffect(() => {
    // Calculate viewport and bounding box when trip changes
    if (trip && boundingBox) {
      setViewport({
        latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
        longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
        zoom: 10,
        width: '100%',
        height: '100%',
      });
    }
  }, [trip, boundingBox]);

  const handleClose = () => {
    setSelectedPlace(null);
    setSelectedMarkerIndex(null);
    if (onCloseClick) {
      onCloseClick(); // Call the parent-provided function if it exists.
    }
  };

  const handleMarkerClick = (step, latitude, longitude) => {
    if (step) {
      setSelectedPlace({ step });
    }
    if (onMarkerClick) {
      onMarkerClick(step.id); // Call the parent-provided function if it exists.
    }

    const newViewport = {
      ...viewport,
      latitude,
      longitude,
      zoom: 5,
    };

    // Update the viewport
    setViewport(newViewport);
  };

  if (!trip) {
    return <div>Loading map...</div>; // Display a loading message or spinner
  }

  const handleDaySelect = (selectedDayId: string | 'all') => {
    console.log('Selected Day ID:', selectedDayId);
    setSelectedDate(selectedDayId);
  };

  const itineraryDays = getDaySelectorData(trip!.itinerary!);

  return (
    <Box
      key={trip.id}
      id={trip.id}
      // id="map"
      ref={mapRef}
      sx={theme => ({
        flex: '1 0 0',
        position: 'sticky',
        top: '0vh',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
        zIndex: 1000,
        borderTopLeftRadius: mapVisiblePercentage > 99.9 ? 0 : 10,
        borderBottomLeftRadius: mapVisiblePercentage > 99.9 ? 0 : 10,
        display: 'block',
        [theme.breakpoints.down(500)]: {
          display: openOnMobile ? 'block' : 'none',
        },
        // Padding top because of the sticky nav bar on mobile and pwa
        [theme.breakpoints.down('sm')]: {
          paddingTop: '50px',
        },
      })}
    >
      {trip.itinerary &&
        trip.itinerary.days &&
        trip.itinerary.days.length > 1 && (
          <DaySelector days={itineraryDays} onDaySelect={handleDaySelect} />
        )}

      <Map
        id={trip.id}
        key={trip.id}
        initialViewState={{
          latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
          longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
          zoom: 10,
        }}
        style={{ width: viewport.width, height: viewport.height }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onLoad={handleMapLoad}
        mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
      >
        {trip.itinerary &&
          trip.itinerary.coordinates &&
          trip.itinerary.coordinates.length > 0 &&
          trip.itinerary.coordinates.map((co, i) => {
            const step = co!.step;
            const dayWithStep = trip.itinerary!.days!.find(day =>
              day!.steps!.some(step => step!.id === co!.step!.id),
            );
            const onlyOneDay = trip.itinerary!.days!.length === 1;
            const isSelected = selectedMarkerIndex === i; // Check if this marker is selected

            return (
              co && (
                <Marker
                  key={i}
                  longitude={co.longitude!}
                  latitude={co.latitude!}
                  onClick={() => {
                    handleMarkerClick(step, co.latitude, co.longitude);
                    setSelectedMarkerIndex(i);
                  }}
                  anchor="bottom"
                >
                  {/* <img
                    src={
                      isSelected
                        ? '/assets/icons/pin-selected.svg'
                        : '/assets/icons/pin.svg'
                    }
                    alt="Map Marker"
                    width={isSelected ? 40 : 30}
                    height={isSelected ? 40 : 30}
                    style={{ cursor: 'pointer' }}
                  /> */}
                  <PinWithText
                    text={
                      !onlyOneDay && dayWithStep
                        ? `D${dayWithStep.dayNumber}`
                        : ''
                    }
                  />
                </Marker>
              )
            );
          })}
        {/* Render Popup when marker is clicked */}
        {selectedPlace && (
          <PlaceCard
            step={selectedPlace ? selectedPlace.step : null}
            mapWidth={mapWidth} // Adjust as needed
            mapPosition={mapPosition} // Adjust as needed
            onClose={handleClose}
            distanceFromBottom={distanceFromBottom} // Adjust as needed
            openOnMobile={openOnMobile}
          />
        )}
      </Map>
    </Box>
  );
}

const getCoordinatesForDay = (
  itineraryDays: Maybe<Day>[],
  coordinates: Maybe<ItineraryCoordinates>[],
  dayId: string,
): Maybe<ItineraryCoordinates>[] => {
  // Find the day by ID
  const day = itineraryDays.find(d => d!.id === dayId);

  // If day not found or has no steps, return an empty array
  if (!day || !day.steps || day.steps.length === 0) {
    console.log('----> EMPTY');
    return coordinates;
  }

  // Extract all step IDs from the day
  const stepIds = day.steps
    .filter(step => step !== null) // Ensure steps are not null
    .map(step => step!.id); // Map to step IDs

  // Filter the ItineraryCoordinates array to include only those with matching step IDs
  const filteredCoordinates = coordinates.filter(
    coord => coord!.step && stepIds.includes(coord!.step.id),
  );
  console.log('----> filteredCoordinates', filteredCoordinates);
  return filteredCoordinates;
};

const getDaySelectorData = (itinerary: Itinerary) => {
  return itinerary.days!.map(day => ({
    dayId: day!.id,
    dayNumber: day!.dayNumber,
  }));
};

interface PinWithTextProps {
  color?: string;
  size?: number;
  text?: string;
  textColor?: string;
  textMarginTop?: number;
}

const PinWithText: React.FC<PinWithTextProps> = ({
  color = '#F17A29',
  // color = '#D35C3B',
  size = 40,
  text,
  textColor = 'white',
  textMarginTop = -6,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 90 90"
      role="img"
    >
      <g
        transform="translate(1.4066 1.4066) scale(1,1)"
        style={{
          stroke: 'none',
          strokeWidth: 0,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
          cursor: 'pointer',
        }}
      >
        <path
          d="M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z"
          fill={color}
        />

        {text && (
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="30"
            fontWeight="bold"
            fill="#36454F" // Change this if you need a different text color
            transform={`translate(0, ${textMarginTop})`}
          >
            {text}
          </text>
        )}
      </g>
    </svg>
  );
};

interface DaySelectorProps {
  days: { dayId: string; dayNumber: number }[];
  onDaySelect: (selectedDayId: string) => void;
}

const DaySelector: React.FC<DaySelectorProps> = ({ days, onDaySelect }) => {
  const [selectedDayId, setSelectedDayId] = useState('all');

  const handleDayChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    setSelectedDayId(selectedId);
    onDaySelect(selectedId); // Notify parent component of the selection
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius={20}
      bgcolor="#f5f5f5"
      sx={theme => ({
        paddingLeft: 2,
        paddingY: 1,
        position: 'absolute',
        top: 10,
        display: 'inline-flex',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
        backgroundColor: 'rgba(240, 240, 240, 0.95)',
        borderRadius: '20px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.50)',
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          top: '55px',
          left: '0px',
          padding: 0.5,
          zIndex: 1,
          justifyContent: 'center',
          transform: 'none',
          display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          backgroundColor: '#f0f4f8', // Use a light color that blends with the app (adjust to your theme)
          color: '#1c3a57', // Match the text color with your theme
          fontWeight: 'bold', // Make the text more prominent
          borderTopRightRadius: '12px', // Larger radius for a smoother corner
          borderBottomRightRadius: '12px', // Same as above
          borderTopLeftRadius: 0, // Larger radius for a smoother corner
          borderBottomLeftRadius: 0,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
          border: '1px solid #dfe3e8', // Optional: subtle border to match theme
          '&:hover': {
            backgroundColor: '#e0ebf5', // Slightly darker on hover
          },
        },
      })}
    >
      <Typography
        sx={theme => ({
          marginRight: 1,
          whiteSpace: 'nowrap',
          color: '#36454F',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            marginLeft: 1,
            fontWeight: 600,
            color: '#1C3A57',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          },
        })}
      >
        Zoom to:
      </Typography>

      <FormControl fullWidth>
        <Select
          value={selectedDayId}
          onChange={handleDayChange}
          displayEmpty
          variant="outlined"
          sx={theme => ({
            padding: 0,
            margin: 0,
            color: '#36454F',
            fontWeight: 500,
            // minWidth: 120,
            '& .MuiOutlinedInput-root': {
              padding: 0,
            },
            '& .MuiSelect-select': {
              // padding: '4px 8px',
              paddingLeft: 0,
              paddingRight: 0,
              paddingY: 0,
              minHeight: 'auto',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            [theme.breakpoints.down('sm')]: {
              color: '#1C3A57',
              height: '40px',
            },
          })}
        >
          <MenuItem key="all" value="all">
            All Days
          </MenuItem>
          {days.map(day => (
            <MenuItem key={day.dayId} value={day.dayId}>
              Day {day.dayNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

interface PlaceCardProps {
  step: Step | null;
  onClose: () => void;
  mapWidth: number;
  mapPosition: number;
  distanceFromBottom: number;
  openOnMobile: boolean | undefined;
}

const PlaceCard: React.FC<PlaceCardProps> = ({
  step,
  onClose,
  mapWidth,
  mapPosition,
  distanceFromBottom,
  openOnMobile,
}) => {
  let bottomValue;
  if (openOnMobile) {
    bottomValue = 0;
  } else if (distanceFromBottom > 0) {
    bottomValue = `${distanceFromBottom + 10}px`;
  } else {
    bottomValue = '10px';
  }

  let bottomValueActionButtons;
  if (openOnMobile) {
    bottomValueActionButtons = '400px';
  } else if (distanceFromBottom > 0) {
    bottomValueActionButtons = `${distanceFromBottom + 330}px`;
  } else {
    bottomValueActionButtons = '330px';
  }

  const { takeMeThereUrl, openGoogleMapsUrl } = getMapUrls(step!);

  function gtag(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: 'fixed',
          bottom: bottomValueActionButtons,
          zIndex: 10,
          left: openOnMobile ? 0 : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          backgroundColor: 'rgba(240, 240, 240, 0.9)',
          padding: 1,
          paddingRight: 2,
          paddingLeft: 2,
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
          ...(openOnMobile ? { width: '100%' } : {}),
        }}
      >
        {step && (
          <Box mr={1} sx={{}}>
            <AuthorizedApolloProvider>
              <AddToTripButton stepId={step.id} dayId={''} />
            </AuthorizedApolloProvider>
          </Box>
        )}

        <IconButton
          onClick={onClose}
          style={{
            backgroundColor: 'rgba(54, 69, 79, 0.9)',
            borderRadius: '50%',
            color: '#ffffff',
            width: 30,
            height: 30,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box
        style={{
          height: openOnMobile ? '400px' : '320px',
          minHeight: openOnMobile ? '395px' : '315px',
          paddingBottom: '5px',
          position: 'fixed',
          backgroundColor: 'white',
          overflowY: 'auto',
          zIndex: 10,
          left: openOnMobile ? 0 : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          padding: 1,
          paddingRight: 2,
          paddingLeft: 2,
          bottom: bottomValue,
          borderBottomLeftRadius: openOnMobile ? 0 : '10px',
          borderBottomRightRadius: openOnMobile ? 0 : '10px',
          ...(openOnMobile ? { width: '100%' } : {}),
        }}
      >
        <Box
          style={{
            position: 'relative',
          }}
        >
          <CardContent>
            {/* Title and Category */}
            {step && step.title && <TitleH4>{step.title}</TitleH4>}
            {/* <Typography variant="body2" color="text.secondary">
              {place.category}
            </Typography> */}
            {/* Rating and Number of Reviews */}
            {step && step.place && step.place.rating && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ mb: 0.5 }}
              >
                <Box
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: '#AB88F3',
                    display: 'flex', // Enable flexbox layout
                    alignItems: 'center', // Vertically center content
                    justifyContent: 'center', // Horizontally center content
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                >
                  <GoogleIcon />
                </Box>

                <>
                  <Typography
                    sx={{ fontWeight: 600, color: '#E6B800', fontSize: 18 }}
                  >
                    {formatNumber(step.place.rating)}
                  </Typography>
                  <StarIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      color: '#E6B800',
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                </>

                {step && step.place && step.place.noOfReviews && (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      alignSelf: 'center',
                      // color: '#6E7191',
                    }}
                  >
                    ({step.place.rating ? step.place.noOfReviews : 'No reviews'}
                    )
                  </Typography>
                )}
              </Stack>
            )}
            {/* Opening Hours */}
            {step &&
              step.place &&
              step.place.openingHours &&
              step.place.openingHours.length > 0 && (
                <TodaysHours place={step.place} />
              )}
            {step &&
              step.place &&
              step.place.website &&
              step.place.website !== '' && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <LanguageIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 10,
                      color: theme.palette.icon.main,
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  <Button
                    component="a"
                    onClick={() => {
                      openLinkInBrowserNewTab(step.place!.website!);
                      gtag('event', 'public-ext-link-website');
                    }}
                    size="small"
                    sx={{
                      margin: 0,
                      padding: 0,
                      fontWeight: 500,
                      fontSize: 16,
                      alignSelf: 'center',
                      // color: '#6E7191',
                    }}
                  >
                    Visit website
                  </Button>
                </Stack>
              )}
            {/* Address */}
            {step && step.place && step.place && step.place.address && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 1 }}
              >
                <LocationOnIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: theme.palette.icon.main,
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                  }}
                >
                  {step.place.address}
                </Typography>
              </Stack>
            )}
            {/* Contact Details */}
            {step && step.place && step.place && step.place.phoneNumber && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 1 }}
              >
                <PhoneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: theme.palette.icon.main,
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                />
                <Link
                  href={`tel:${step && step.place && step.place.phoneNumber}`}
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                  }}
                >
                  {step.place.phoneNumber}
                </Link>
              </Stack>
            )}
            {step &&
            step.description &&
            !(
              step.accommodations &&
              step.accommodations[0] &&
              step.accommodations[0].description
            ) ? (
              <Box sx={{ mt: 2 }}>
                {/* <Divider /> */}
                <Body1>
                  <RenderHtmlFromResponseNoMargin content={step.description} />
                </Body1>
              </Box>
            ) : (
              step &&
              step.accommodations &&
              step.accommodations[0] &&
              step.accommodations[0].description && (
                <Box sx={{ mt: 2 }}>
                  {/* <Divider /> */}
                  <Body1>
                    <RenderHtmlFromResponseNoMargin
                      content={step.accommodations[0].description}
                    />
                  </Body1>
                </Box>
              )
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ mt: 1 }}
            >
              {takeMeThereUrl && (
                <Link
                  onClick={() => {
                    openLinkInBrowserNewTab(takeMeThereUrl);
                    gtag('event', 'public-link-navigate-click');
                  }}
                >
                  <ResponsiveChip
                    mobileCopy="Navigate"
                    desktopCopy="Navigate"
                    icon={DirectionsIcon}
                  />
                </Link>
              )}
              {openGoogleMapsUrl && (
                <Link
                  onClick={() => {
                    openLinkInBrowserNewTab(openGoogleMapsUrl);
                    gtag('event', 'public-link-maps-url');
                  }}
                >
                  <ResponsiveChip
                    mobileCopy="Maps"
                    desktopCopy="Google Maps"
                    icon={MapIcon}
                  />
                </Link>
              )}
            </Stack>
            {step &&
              step.place &&
              step.place.images &&
              step.place.images.length > 0 && (
                <Grid container spacing={2} mt={2}>
                  {step.place.images.map(image => (
                    <Grid item xs={4} key={image!.path}>
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          height: '150px', // Set a fixed height for all images
                          overflow: 'hidden', // Hide anything outside the box
                          borderRadius: '4px', // Optional: to add rounded corners
                        }}
                      >
                        {/* <img
                          src={image!.imageUrl!}
                          alt={image!.title!}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Ensure image covers the area without stretching
                          }}
                        /> */}
                        <ImageCard image={image} index={image!.id} />
                        <Typography
                          variant="caption"
                          sx={{
                            position: 'absolute',
                            bottom: '8px', // Adjust to move away from the bottom
                            left: '8px', // Adjust to move away from the left
                            color: 'white', // Set text color to stand out on image
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background for better visibility
                            padding: '4px 8px', // Add padding for readability
                            borderRadius: '4px', // Optional: to round the text box
                          }}
                        >
                          {image!.title!}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
          </CardContent>
        </Box>
      </Box>
    </>
    // </Card>
  );
};

function calculateBoundingBox(coordinates: Maybe<ItineraryCoordinates>[]) {
  // Initialize variables with extreme values
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // Iterate over all coordinates
  coordinates
    .filter(
      (item): item is ItineraryCoordinates =>
        item !== null && item !== undefined,
    )
    .forEach(({ latitude, longitude }) => {
      if (isValidCoordinates(latitude, longitude)) {
      } else {
      }
      if (
        latitude !== null &&
        latitude !== undefined &&
        longitude !== null &&
        longitude !== undefined
      ) {
        if (latitude < minLat) minLat = latitude;
        if (latitude > maxLat) maxLat = latitude;
        if (longitude < minLng) minLng = longitude;
        if (longitude > maxLng) maxLng = longitude;
      }
    });

  // Return the bounding box
  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
}

function isValidLatitude(lat) {
  return lat >= -90 && lat <= 90;
}

function isValidLongitude(lng) {
  return lng >= -180 && lng <= 180;
}

function isValidCoordinates(lat, lng) {
  return isValidLatitude(lat) && isValidLongitude(lng);
}

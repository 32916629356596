import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { ReactNode } from 'react';

interface ChildProps {
  readonly children?: ReactNode;
}

interface RoutePageProps extends ChildProps {
  paddingTop?: number;
}
export function RoutePage({ paddingTop = 0, ...props }: RoutePageProps) {
  return (
    <Box
      sx={theme => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingRight: 12,
        paddingLeft: 12,
        // backgroundColor: '#FAFAFA',
        paddingTop,
        [theme.breakpoints.down('lg')]: {
          paddingRight: 5,
          paddingLeft: 5,
        },
        [theme.breakpoints.down('md')]: {
          paddingRight: 1,
          paddingLeft: 1,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function RouteTitle(props: ChildProps) {
  return (
    <Typography variant="h4" component="h1" gutterBottom>
      {props.children}
    </Typography>
  );
}

export function RouteContent(props: ChildProps) {
  return <Box>{props.children}</Box>;
}

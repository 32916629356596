export const categoryTourOrExperiences = [
  { name: 'Tour', type: 'STEP_TYPE_TOUR' },
  { name: 'Experience', type: 'STEP_TYPE_EXPERIENCE' },
];

export const categoryAccommodation = [
  { name: 'Hotel', type: 'STEP_TYPE_ACCOMMODATION_HOTEL' },
  { name: 'AirBnb', type: 'STEP_TYPE_ACCOMMODATION_AIRBNB' },
  { name: 'Other', type: 'STEP_TYPE_ACCOMMODATION_OTHER' },
];

export const categoryFoodOrDrink = [
  { name: 'Food', type: 'STEP_TYPE_FOOD_DRINK_FOOD' },
  { name: 'Drink', type: 'STEP_TYPE_FOOD_DRINK_DRINK' },
  { name: 'Coffee place', type: 'STEP_TYPE_FOOD_DRINK_COFFEE' },
  { name: 'Groceries', type: 'STEP_TYPE_FOOD_DRINK_GROCERIES' },
  { name: 'Market', type: 'STEP_TYPE_FOOD_DRINK_MARKET' },
  { name: 'Other', type: 'STEP_TYPE_FOOD_DRINK_OTHER' },
];

export const categoryTransport = [
  { name: 'Walk', type: 'STEP_TYPE_TRANSPORT_WALK' },
  { name: 'Bike', type: 'STEP_TYPE_TRANSPORT_BIKE' },
  { name: 'Motorbike', type: 'STEP_TYPE_TRANSPORT_MOTORBIKE' },
  { name: 'Taxi', type: 'STEP_TYPE_TRANSPORT_TAXI' },
  { name: 'Car', type: 'STEP_TYPE_TRANSPORT_CAR' },
  { name: 'Bus', type: 'STEP_TYPE_TRANSPORT_BUS' },
  { name: 'Train', type: 'STEP_TYPE_TRANSPORT_TRAIN' },
  { name: 'Tube', type: 'STEP_TYPE_TRANSPORT_TUBE' },
  { name: 'Tram', type: 'STEP_TYPE_TRANSPORT_TRAM' },
  { name: 'Plane', type: 'STEP_TYPE_TRANSPORT_PLANE' },
  { name: 'Boat', type: 'STEP_TYPE_TRANSPORT_BOAT' },
  { name: 'Other', type: 'STEP_TYPE_TRANSPORT_OTHER' },
];

export const generateTransportTitle = (
  transportStepType: string,
  duration: number,
) => {
  const h = Math.floor(duration / 60);
  const m = duration % 60;

  let hours = '';
  let minutes = '';

  if (h >= 1) {
    if (m >= 1) {
      hours =
        h === 1 ? h.toString() + ' hour and ' : h.toString() + ' hours and ';
    } else {
      hours = h === 1 ? h.toString() + ' hour and ' : h.toString() + ' hours ';
    }
  }

  if (m >= 1) {
    minutes = m === 1 ? m.toString() + ' minute' : m.toString() + ' minutes';
  }

  const timeString = hours + minutes;

  switch (transportStepType) {
    case 'STEP_TYPE_TRANSPORT_BIKE':
      return 'Cycle ' + timeString;
    case 'STEP_TYPE_TRANSPORT_MOTORBIKE':
      return 'Drive ' + timeString;
    case 'STEP_TYPE_TRANSPORT_TAXI':
      return 'Taxi ride ' + timeString;
    case 'STEP_TYPE_TRANSPORT_CAR':
      return 'Drive ' + timeString;
    case 'STEP_TYPE_TRANSPORT_BUS':
      return 'Bus ride ' + timeString;
    case 'STEP_TYPE_TRANSPORT_TRAIN':
      return 'Train ride ' + timeString;
    case 'STEP_TYPE_TRANSPORT_TUBE':
      return 'Tube ride ' + timeString;
    case 'STEP_TYPE_TRANSPORT_TRAM':
      return 'Tram ride ' + timeString;
    case 'STEP_TYPE_TRANSPORT_PLANE':
      return 'Fly ' + timeString;
    case 'STEP_TYPE_TRANSPORT_BOAT':
      return 'Sail ' + timeString;
    case 'STEP_TYPE_TRANSPORT_WALK':
      return 'Walk ' + timeString;
    default:
      return 'Transport takes ' + timeString;
  }
};

export const categoryServices = [
  { name: 'Gas station', type: 'STEP_TYPE_SERVICES_GAS_STATION' },
  { name: 'Cash machine', type: 'STEP_TYPE_SERVICES_CASH_MACHINE' },
  { name: 'Exchange', type: 'STEP_TYPE_SERVICES_EXCHANGE' },
  { name: 'Other', type: 'STEP_TYPE_SERVICES_OTHER' },
];

export const categoryPlacesAndLandmarks = [
  { name: 'Landmark', type: 'STEP_TYPE_PLACES_LANDMARK' },
  { name: 'Place to chill', type: 'STEP_TYPE_PLACES_PLACE_TO_CHILL' },
  { name: 'Historic place', type: 'STEP_TYPE_PLACES_HISTORIC' },
  { name: 'Nature landmark', type: 'STEP_TYPE_PLACES_NATURE' },
  { name: 'Park', type: 'STEP_TYPE_PLACES_PARK' },
  { name: 'Other', type: 'STEP_TYPE_PLACES_OTHER' },
];

export const categoryEntertainment = [
  { name: 'Drinks', type: 'STEP_TYPE_ENTERTAINMENT_DRINKS' },
  { name: 'Performance', type: 'STEP_TYPE_ENTERTAINMENT_PERFORMANCE' },
  { name: 'Shoping', type: 'STEP_TYPE_ENTERTAINMENT_SHOPING' },
  { name: 'Sport', type: 'STEP_TYPE_ENTERTAINMENT_SPORT' },
  { name: 'Music', type: 'STEP_TYPE_ENTERTAINMENT_MUSIC' },
  { name: 'Other', type: 'STEP_TYPE_ENTERTAINMENT_OTHER' },
];

export const categoryOther = [{ name: 'Other', type: 'STEP_TYPE_OTHER' }];

export const categories = [
  {
    name: 'Accommoation',
    type: 'CATEGORY_ACCOMMODATION',
    subCategories: categoryAccommodation,
  },
  {
    name: 'Food and Drink',
    type: 'CATEGORY_FOOD_DRINK',
    subCategories: categoryFoodOrDrink,
  },
  {
    name: 'Places and Landmarks',
    type: 'CATEGORY_PLACE',
    subCategories: categoryPlacesAndLandmarks,
  },
  {
    name: 'Transport',
    type: 'CATEGORY_TRANSPORT',
    subCategories: categoryTransport,
  },
  {
    name: 'Tour or experience',
    type: 'CATEGORY_ACTIVITY',
    subCategories: categoryTourOrExperiences,
  },
  {
    name: 'Entertinement',
    type: 'CATEGORY_ACTIVITY',
    subCategories: categoryEntertainment,
  },
];

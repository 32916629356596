import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import InterestsTwoToneIcon from '@mui/icons-material/InterestsTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import PhotoSizeSelectActualTwoToneIcon from '@mui/icons-material/PhotoSizeSelectActualTwoTone';
import { Box, Button } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';

import { Step } from '../../../generated/user_graphql';
import { DeleteStepModal } from '../manage-trip-modals/DeleteStepModal';
import { EditImagesModal } from '../manage-trip-modals/EditAddStepImages';
import { EditStepModal } from '../manage-trip-modals/EditStepModal';
import { LinkPlaceToStepModal } from '../manage-trip-modals/LinkPlaceToStepModal';
import { LinkSocialLinkToStepModal } from '../manage-trip-modals/LinkSocialLinkModal';
import { isAdmin, useUserRoles } from '../../../utils/helpers';
import EditTagsModal from '../manage-trip-modals/EditTagsModal';

interface EditOrAddStepButtonProps {
  tripId: string;
  copy: string;
  step: Step;
  tripType: string;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const ActionsStep: React.FC<EditOrAddStepButtonProps> = ({
  tripId,
  copy,
  step,
  tripType,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isEditImages, setIsEditImages] = useState(false);
  const [isEditLinkSocial, setIsEditLinkSocial] = useState(false);
  const [isEditLinkPlace, setIsEditLinkPlace] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEditTagsOpen, setIsEditTagsOpen] = useState(false);
  const roles = useUserRoles();

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;
    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
  };

  const handleClose = () => {
    setIsModalDisplayed(false);
    setAnchorEl(null);
    enableScroll();
  };

  const handleCloseModal = () => {
    setIsEditDetailsModalOpen(false);
    setIsEditLinkSocial(false);
    setIsEditTagsOpen(false);
    setIsEditImages(false);
    setIsEditLinkPlace(false);
    setIsDelete(false);
    setIsModalDisplayed(false);
    setAnchorEl(null);
    setTimeout(() => {
      enableScroll();
    }, 300);
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    document.body.style.position = 'fixed'; // Prevent page jump
    document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  };

  const enableScroll = () => {
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
    window.scrollTo(0, scrollY); // Restore previous scroll position
  };

  // useEffect(() => {
  //   if (!isModalDisplayed) {
  //     console.log('enableScroll isModalDisplayed 0S');
  //     enableScroll(); // Lock scrolling when modal is displayed
  //   }

  //   // return () => {
  //   //   console.log('enableScroll isModalDisplayed 1S');
  //   //   enableScroll(); // Clean up scrolling state when modal is closed
  //   // };
  // }, [isModalDisplayed]);

  useEffect(() => {
    if (isModalDisplayed) {
      disableScroll(); // Lock scrolling when modal is displayed
    }

    // return () => {
    //   console.log('enableScroll isModalDisplayed 1S');
    //   enableScroll(); // Clean up scrolling state when modal is closed
    // };
  }, [isModalDisplayed]);

  // useEffect(() => {
  //   console.log('enableScroll 0S');
  //   return () => {
  //     console.log('enableScroll 1S');
  //     enableScroll();
  //   };
  // }, []);

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !isModalDisplayed) {
      const handleScroll = e => {
        handleClose(); // Close modal on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close modal on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, isModalDisplayed]);

  return (
    <Box
      sx={theme => ({
        flexShrink: 0, // Prevent the button from shrinking
      })}
    >
      <Button
        variant="contained"
        endIcon={
          <ArrowDropDownIcon
            sx={theme => ({
              height: 17,
              width: 17,
              pointerEvents: 'none',
            })}
          />
        }
        onClick={e => {
          const scrollPosition = window.scrollY;
          setScrollY(scrollPosition);
          handleClick(e);
        }}
        sx={theme => ({
          fontSize: 12,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          paddingLeft: 1.75,
          paddingRight: 1.75,
          backgroundColor: '#36454F',
          borderRadius: 20,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          boxShadow: 'none', // Disable shadow
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#4A5D6F',
          },
        })}
      >
        {copy}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        disableScrollLock={true}
        anchorReference="anchorPosition"
        anchorPosition={
          fixedAnchorPosition
            ? {
                top: fixedAnchorPosition.top,
                left: fixedAnchorPosition.left,
              }
            : undefined
        }
        anchorOrigin={{
          vertical: 'bottom', // Adjust vertical anchor
          horizontal: 'left', // Adjust horizontal anchor
        }}
        transformOrigin={{
          vertical: 'top', // Adjust vertical transform
          horizontal: 'left', // Adjust horizontal transform
        }}
      >
        <MenuItem
          onClick={() => {
            if (!isModalDisplayed) {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              setIsModalDisplayed(!isModalDisplayed);
            }
            setIsEditDetailsModalOpen(true);
          }}
        >
          <ListItemIcon>
            <EditTwoToneIcon />
          </ListItemIcon>
          Edit details
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (!isModalDisplayed) {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              setIsModalDisplayed(!isModalDisplayed);
            }
            setIsEditLinkSocial(true);
          }}
        >
          <ListItemIcon>
            <InterestsTwoToneIcon />
          </ListItemIcon>
          Link social
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (!isModalDisplayed) {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              setIsModalDisplayed(!isModalDisplayed);
            }
            setIsEditImages(true);
          }}
        >
          <ListItemIcon>
            <PhotoSizeSelectActualTwoToneIcon />
          </ListItemIcon>
          Edit images
        </MenuItem>
        {!step.place && (
          <MenuItem
            onClick={() => {
              if (!isModalDisplayed) {
                const scrollPosition = window.scrollY;
                setScrollY(scrollPosition);
                setIsModalDisplayed(!isModalDisplayed);
              }
              setIsEditLinkPlace(true);
            }}
          >
            <ListItemIcon>
              <MapTwoToneIcon />
            </ListItemIcon>
            Link place
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            if (!isModalDisplayed) {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              setIsModalDisplayed(!isModalDisplayed);
            }
            setIsDelete(true);
          }}
        >
          <ListItemIcon>
            <DeleteTwoToneIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
        {isAdmin(roles) && (
          <MenuItem
            onClick={() => {
              if (!isModalDisplayed) {
                const scrollPosition = window.scrollY;
                setScrollY(scrollPosition);
                setIsModalDisplayed(!isModalDisplayed);
                setIsEditTagsOpen(true);
              }
            }}
          >
            <ListItemIcon>
              <EditTwoToneIcon />
            </ListItemIcon>
            Manage tags
          </MenuItem>
        )}
      </Menu>
      {isEditDetailsModalOpen && (
        <EditStepModal step={step} handleCloseModal={handleCloseModal} />
      )}
      {isEditImages && (
        <EditImagesModal
          step={step}
          tripId={tripId}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isEditLinkPlace && (
        <LinkPlaceToStepModal
          stepId={step.id}
          tripType={tripType}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isEditLinkSocial && (
        <LinkSocialLinkToStepModal
          stepId={step.id}
          tripId={tripId}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isEditTagsOpen && (
        <EditTagsModal
          stepId={step.id}
          placeId={step.place && step.place.id ? step.place.id : undefined}
          handleCloseModal={handleCloseModal}
          tags={step.tags}
          placeTags={
            step.place && step.place.tags ? step.place.tags : undefined
          }
        />
      )}
      {isDelete && (
        <DeleteStepModal stepId={step.id} handleCloseModal={handleCloseModal} />
      )}
    </Box>
  );
};

import { useMutation } from '@apollo/client';
import { Box, TextField } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector, setTripName } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
  ModalPaper,
} from '../../styling/modal';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import { CustomFormControl } from './Styles';

export default function EditTripNameModal(props: {
  name?: string;
  editTripId: string;
  tripType: string;
  handleCloseModal: () => void;
}) {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { tripName } = useSelector(tripsSelector);

  const [editTrip, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: { name: tripName, trip_id: props.editTripId },
  });

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    props.handleCloseModal();
    // setOpen(false);
  };

  return (
    <>
      {/* <Box
        onClick={() => {
          gtag('event', 'edit-trip-name-icon');
          handleOpen();
        }}
        sx={theme => ({
          width: 25,
          height: 25,
          [theme.breakpoints.down('sm')]: {
            width: 20,
            height: 20,
          },
        })}
      >
        Edit title
      </Box> */}
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={true}
        onClose={() => handleClose()}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader
            title={
              props.tripType && props.tripType === 'guide'
                ? 'Change guide name'
                : 'Change trip name'
            }
            description={
              <>
                <strong>Short and descriptive</strong> name is always the best
                choice
              </>
            }
          />
          <CustomFormControl>
            <TextField
              id="standard-basic"
              label="Trip name"
              name="name"
              defaultValue={tripName}
              fullWidth={true}
              variant="outlined"
              onChange={e => dispatch(setTripName(e.target.value))}
              sx={{ backgroundColor: '#fafafa' }}
            />
          </CustomFormControl>
          <Space size="md" />
          <PrimaryButton
            disabled={loading}
            onClick={async () => {
              gtag('event', 'edit-trip-name-save');
              try {
                await editTrip();
                handleClose();
              } catch (e) {
                gtag('event', 'error-edit-trip-name');
                dispatch(setErrorTitle('Error saving trip name'));
                dispatch(setErrorModalOpen(true));
              }
            }}
          >
            {loading ? <Loader /> : 'Save name'}
          </PrimaryButton>
          <Space size="md" />
          <SecondaryButton
            disabled={loading}
            onClick={async () => {
              gtag('event', 'publish-trip-close');
              // setOpen(false);
              handleClose();
            }}
          >
            Close
          </SecondaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
}

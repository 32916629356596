import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import VaccinesTwoToneIcon from '@mui/icons-material/VaccinesTwoTone';
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone';
import { Drawer, IconButton, Box, Divider, Link } from '@mui/material';
import * as Flags from 'country-flag-icons/react/3x2';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Country } from '../../generated/public_graphql';
import { getQueryParam } from '../../utils/queryParams';

import { TripCountries } from './TripCountries';
import { TripHealth } from './TripHealth';
import { TripSeasons } from './TripSeasons';
import { TripTipsBeforeYouGo } from './TripTipsBeforeYouGo';
import { TripVisas } from './TripVisas';
import { openLinkInBrowserNewTab } from '../../utils/helpers';

interface IconeTypeProps {
  readonly $iconType: string;
}

export function TripSidebar(props: { countries: [Country] }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalId, setModalId] = useState('');
  const open = [
    'seasons',
    'tips',
    'vaccinations',
    'countries_details',
    'visas',
    'tips',
  ].includes(modalId);

  useEffect(() => {
    const modal = getQueryParam(location.search, 'modal');
    const modalValue = typeof modal === 'string' ? modal : '';
    setModalId(modalValue || '');
  }, [location]);

  function onClose() {
    navigate(-1);
  }

  return (
    <Drawer
      sx={theme => ({
        // width: 60,
        zIndex: 10000,
        flexShrink: 0, // Ensures the Drawer does not shrink
        '& .MuiDrawer-paper': {
          // Target the Drawer paper to set its width
          width: 600,
          boxSizing: 'border-box', // Ensures width includes padding and border
          [theme.breakpoints.down('md')]: {
            width: 500,
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      })}
      open={open}
      anchor="right"
      variant="temporary"
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <Box display="flex" flexDirection="column" sx={{ padding: 3 }}>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={theme => ({
            mb: -8,
            backgroundColor: 'white',
            [theme.breakpoints.down('sm')]: {
              mb: -6,
            },
          })}
        >
          <IconButton
            aria-label="closeDrawer"
            color="secondary"
            onClick={() => {
              onClose();
              gtag('event', 'close-side-bar');
            }}
          >
            <CloseIcon sx={{ width: 30, height: 30 }} />
          </IconButton>
        </Box>
        {modalId === 'seasons' && <TripSeasons countries={props.countries} />}
        {modalId === 'vaccinations' && (
          <TripHealth countries={props.countries} />
        )}
        {modalId === 'countries_details' && (
          <TripCountries countries={props.countries} />
        )}
        {modalId === 'visas' && <TripVisas countries={props.countries} />}
        {modalId === 'tips' && (
          <TripTipsBeforeYouGo countries={props.countries} />
        )}
      </Box>
    </Drawer>
  );
}

export function SideBarHeader(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 600,
        fontSize: 45,
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: 35,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function SideBarSubHeader(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 600,
        fontSize: 22,
        marginBottom: 1,
        [theme.breakpoints.down('sm')]: {
          fontSize: 22,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function SideBarSubSubHeader(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 1,
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      })}
    >
      {props.children}
    </Box>
  );
}
export function SideBarDescription(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 400,
        fontSize: 18,
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function SideBarTitleDivider() {
  return <Divider sx={theme => ({ marginBottom: 1.5, marginTop: 1 })} />;
}

export function SideBarLink(props: { url: string; copy: string }) {
  return (
    <Link
      sx={theme => ({
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      })}
      onClick={() => {
        openLinkInBrowserNewTab(props.url);
        gtag('event', 'click-link-on-side-bar');
      }}
    >
      {props.copy}
    </Link>
  );
}

const CountryFlag = ({ countryCode }) => {
  // eslint-disable-next-line import/namespace
  const FlagComponent = Flags[countryCode]; // Dynamically select the flag component

  if (!FlagComponent) {
    return (
      <LanguageTwoToneIcon
        sx={theme => ({
          marginRight: 2,
          width: 60,
          height: 60,
          alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
            marginRight: 1,
            width: 40,
            height: 40,
          },
        })}
      />
    );
  }

  return (
    <Box
      component={FlagComponent}
      title={countryCode}
      sx={theme => ({
        marginRight: 2,
        width: 60,
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
          marginRight: 1,
          width: 40,
        },
      })}
    />
  );
};

export function SideBarIcon(props: IconeTypeProps) {
  switch (true) {
    case /^[A-Z]{2}$/.test(props.$iconType):
      return <CountryFlag countryCode={props.$iconType} />;
    case props.$iconType.includes('WEATHER'):
      return (
        <WbSunnyTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 60,
            height: 60,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('VISAS'):
      return (
        <ArticleTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 60,
            height: 60,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('VACCINE'):
      return (
        <VaccinesTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 60,
            height: 60,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    case props.$iconType.includes('TIPS'):
      return (
        <TipsAndUpdatesTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 60,
            height: 60,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
    default:
      return (
        <LanguageTwoToneIcon
          sx={theme => ({
            marginRight: 2,
            width: 60,
            height: 60,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 1,
              width: 40,
              height: 40,
            },
          })}
        />
      );
  }
}

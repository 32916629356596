import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CountryTrips } from '../components/country/country-trips';
import { CountryHeader } from '../components/country/header';
import {
  GradientContainer,
  OuterContainer,
} from '../components/home/HomeHeader';
import { RoutePage } from '../components/route';
import { isRunningStandalone } from '../utils/helpers';

export default function CountryRoute() {
  gtag('event', 'public-country-page');
  const { id } = useParams<{ id: string }>();
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  return (
    <RoutePage>
      <OuterContainer>
        <GradientContainer>
          {id && (
            <>
              <CountryHeader countryId={id} />
              <CountryTrips countryId={id} />
            </>
          )}
          {isStandalone && (
            <Box
              sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))' }}
            />
          )}
        </GradientContainer>
      </OuterContainer>
    </RoutePage>
  );
}

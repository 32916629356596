import { Box } from '@mui/material';
import { Fragment } from 'react';

import { Country } from '../../generated/public_graphql';
import { VerticalFlex, HorizontalFlex } from '../helpers/flex';

import {
  SideBarIcon,
  SideBarHeader,
  SideBarTitleDivider,
  SideBarSubHeader,
  SideBarLink,
  SideBarDescription,
} from './TripSidebar';

interface Props {
  readonly countries: [Country];
}

export function TripVisas(props: Props) {
  // const classes = useStyles();

  // For now we will support only one country per
  // trip untill we have designs to show multiple
  const country = props.countries[0];

  return (
    <VerticalFlex>
      <HorizontalFlex>
        <SideBarIcon $iconType={'VISAS'} />
        <SideBarHeader>Visas</SideBarHeader>
      </HorizontalFlex>
      <SideBarTitleDivider />
      {country &&
        country.recommendations &&
        country.recommendations.map((recommendation, i) => {
          return (
            <Fragment key={'vaccination' + i}>
              {recommendation &&
                recommendation.type === 'RECOMMENDATION_VISAS' && (
                  <>
                    <SideBarSubHeader>{recommendation.name}</SideBarSubHeader>
                    {recommendation.description && (
                      <SideBarDescription>
                        {recommendation.description}
                      </SideBarDescription>
                    )}
                    <br />
                    {recommendation.url && (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <SideBarLink
                          url={recommendation.url}
                          copy={'More info'}
                        />
                      </Box>
                    )}
                  </>
                )}
            </Fragment>
          );
        })}
    </VerticalFlex>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ErrorsState {
  errorModalOpen: boolean;
  errorTitle: string;
}

const initialState: ErrorsState = {
  errorModalOpen: false,
  errorTitle: '',
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    resetError: () => initialState,
    setErrorTitle: (state, { payload }: PayloadAction<string>) => {
      state.errorTitle = payload;
    },
    setErrorModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.errorModalOpen = payload;
    },
  },
});

export const { resetError, setErrorModalOpen, setErrorTitle } =
  errorSlice.actions;

export default errorSlice.reducer;

export const errorsSelector = (state: { errorModal: ErrorsState }) =>
  state.errorModal;

import { Box, Container } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { CreateUserTrip } from '../components/create-trip/CreateUserTrip';
import { RoutePage } from '../components/route';
import { isRunningStandalone } from '../utils/helpers';
import { LoaderFullScreen } from '../theme-components/Loader';
import { useAuth } from '../components/auth/firebase';

export default function CreateTripRoute() {
  const { isLoading } = useAuth();
  const [isStandalone, setIsStandalone] = useState<boolean>(false);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <RoutePage>
      <AuthorizedApolloProvider>
        <CreateUserTrip />
        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(130px + env(safe-area-inset-bottom))' }}
          />
        )}
      </AuthorizedApolloProvider>
    </RoutePage>
  );
}

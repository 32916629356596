import { useMutation } from '@apollo/client';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import {
  Box,
  FormControlLabel,
  Switch,
  FormGroup,
  Typography,
  Stack,
  LinearProgress,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { daysSelector, setDays } from '../../../store/DaySlice';
import { tripsSelector } from '../../../store/TripSlice';
import { Loader } from '../../../theme-components/Loader';
import { TitleH3 } from '../../../theme-components/Typography';
import {
  isRunningStandalone,
  returnFormattedDate,
} from '../../../utils/helpers';
import { MUTATION_ADD_DAY_TO_ITINERARY } from '../../gql-user/addDayToItinerary';
import { ActionsDay } from '../edit-trip/ActionsDay';

import { DraggableDroppableSteps } from './DraggableDroppableSteps';
import { ContainedHandle, ContainerDay, Item } from './Styles';
import {
  RootState,
  selectTotalTransportTimeByDayId,
} from '../../../store/TransportSlice';
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import DayFullness from '../../trip-details/DayFullnessIndicator';
import { AddAccomodationDirections } from '../../trip-details/AddAccomodationDirections';

export function DraggableDroppableDays(props: {
  showEmptyDay: boolean;
  selectedStepId?;
  stepRefs?;
}) {
  const dispatch = useDispatch();
  const [collapsedSteps, setCollapsedSteps] = useState(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true
  const location = useLocation(); // React Router hook (optional)
  const [hasSelectedTrip, setHasSelectedTrip] = useState(false);

  useEffect(() => {
    // Get the query parameters from the current URL
    const queryParams = new URLSearchParams(location.search); // or window.location.search
    const hasSelectedTripParam = queryParams.has('selected-trip'); // Check if 'selected-trip' exists

    // Set the boolean value based on the presence of 'selected-trip'
    setHasSelectedTrip(hasSelectedTripParam);
  }, [location]);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { days, dayLoadingIndex, stepLoadingIndex } = useSelector(daysSelector);
  const { trip } = useSelector(tripsSelector);

  const [addDay, { loading: loadingNewDay, error: errorNewDay }] = useMutation(
    MUTATION_ADD_DAY_TO_ITINERARY,
  );

  return (
    <>
      <Box
        sx={theme => ({
          position: 'fixed', // Sticks to a fixed position
          bottom: isStandalone
            ? 'calc(100px + env(safe-area-inset-bottom))'
            : '16px', // Distance from the bottom of the screen
          left: hasSelectedTrip ? '25%' : '75%', // Center horizontally relative to the viewport
          transform: 'translateX(-50%)', // Offset to truly center the element
          // width: 60, // Adjust the size of the circular element
          // height: 60,
          borderRadius: '20px', // Make the element circular
          backgroundColor: theme.palette.grey[900], // MUI primary color (or use any custom color)
          // color: 'white',
          // fontWeight: 900,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingRight: 3,
          paddingLeft: 1,
          paddingY: 1,
          // zIndex: 100,
          zIndex: 10000,
          [theme.breakpoints.down('sm')]: {
            left: '50%',
            zIndex: 1000,
          },
        })}
      >
        <FormGroup>
          <FormControlLabel
            onClick={() => {
              gtag('event', 'collapse-details');
              setCollapsedSteps(!collapsedSteps);
            }}
            control={
              <Switch
                size="small"
                checked={collapsedSteps}
                onChange={() => setCollapsedSteps(!collapsedSteps)}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: 'white', // Handle color when unchecked
                  },
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: 'primary.main', // Handle color when checked
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'white', // Track color when unchecked
                  },
                  '& .MuiSwitch-track.Mui-checked': {
                    backgroundColor: 'primary.main', // Track color when checked
                  },
                }}
              />
            }
            labelPlacement="start"
            label={
              <Typography
                onClick={() => {
                  gtag('event', 'collapse-details');
                  setCollapsedSteps(!collapsedSteps);
                }}
                sx={{
                  fontWeight: 700,
                  color: 'white',
                  whiteSpace: 'nowrap',
                  marginRight: 1,
                }}
              >
                {/* {collapsedSteps ? 'Show details' : 'Hide details'} */}
                {hasSelectedTrip ? 'Hide details' : 'Reorder itinerary'}
              </Typography>
            }
          />
        </FormGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          paddingRight: 1,
          marginLeft: collapsedSteps ? -1 : 0,
        }}
      >
        <Droppable type="itineraryDay" droppableId="itinerary">
          {(provided, snapshot) => (
            <ContainerDay
              ref={provided.innerRef}
              $isDraggingOver={snapshot.isDraggingOver}
            >
              {days &&
                days.map((day, dayIndex) => (
                  <Box
                    key={dayIndex}
                    width="100%"
                    sx={theme => ({
                      [theme.breakpoints.down('sm')]: {
                        // marginLeft: '-5px',
                      },
                    })}
                  >
                    {day && (
                      <Draggable
                        key={day.id}
                        draggableId={day.id}
                        index={dayIndex}
                      >
                        {(provided, snapshot) => (
                          <>
                            {/* {day.steps && day.steps.length > 0 && ( */}
                            <>
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                $isDragging={snapshot.isDragging}
                                style={provided.draggableProps.style}
                              >
                                <Stack width="100%" direction="column">
                                  <Box width="100%">
                                    {dayLoadingIndex === dayIndex &&
                                    stepLoadingIndex === -1 ? (
                                      <ContainedHandle
                                        {...provided.dragHandleProps}
                                      >
                                        <Loader size={20} />
                                      </ContainedHandle>
                                    ) : (
                                      <Stack
                                        direction="row"
                                        alignItems="flex-start"
                                        sx={{
                                          marginTop: 1,
                                          width: '100%',
                                          // position: 'relative',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {day.steps && day.steps.length > 0 ? (
                                          <>
                                            {collapsedSteps && (
                                              <ContainedHandle
                                                {...provided.dragHandleProps}
                                              >
                                                <DragIndicatorIcon
                                                  sx={theme => ({
                                                    marginTop: 1.5,
                                                    width: 25,
                                                    height: 25,
                                                    // [theme.breakpoints.down(
                                                    //   'sm',
                                                    // )]: {
                                                    //   width: 20,
                                                    //   height: 20,
                                                    // },
                                                  })}
                                                />
                                              </ContainedHandle>
                                            )}
                                            <Stack
                                              direction="column"
                                              width="100%"
                                              sx={{
                                                position: 'relative',
                                                overflow: 'hidden',
                                                borderRadius: '20px',
                                                border:
                                                  '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
                                                padding: 1,
                                                paddingLeft: 1.5,
                                                marginBottom: 1.5,
                                                boxShadow:
                                                  '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                              }}
                                            >
                                              <Stack
                                                direction="row"
                                                width="100%"
                                                justifyContent="space-between"
                                              >
                                                <Stack
                                                  direction="row"
                                                  width="100%"
                                                >
                                                  {trip &&
                                                  (!trip.tripType ||
                                                    trip.tripType !==
                                                      'guide') ? (
                                                    <>
                                                      <TodayTwoToneIcon
                                                        sx={theme => ({
                                                          marginLeft: 0,
                                                          marginRight: 1,
                                                          width: 25,
                                                          height: 25,
                                                          alignSelf: 'center',
                                                          color: '#F39C6A',
                                                          [theme.breakpoints.down(
                                                            'sm',
                                                          )]: {
                                                            marginLeft: 0,
                                                            width: 20,
                                                            height: 20,
                                                          },
                                                        })}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Box
                                                        sx={theme => ({
                                                          // width: 25,
                                                          // height: 25,
                                                        })}
                                                      />
                                                      <StarsTwoToneIcon
                                                        sx={theme => ({
                                                          marginLeft: 0,
                                                          marginRight: 1,
                                                          width: 25,
                                                          height: 25,
                                                          alignSelf: 'center',
                                                          color: '#F39C6A',
                                                          [theme.breakpoints.down(
                                                            'sm',
                                                          )]: {
                                                            marginLeft: 0,
                                                            width: 20,
                                                            height: 20,
                                                          },
                                                        })}
                                                      />
                                                    </>
                                                  )}
                                                  {/* <Stack direction="row"> */}
                                                  <TitleH3 color="#001B30">
                                                    {trip &&
                                                    (!trip.tripType ||
                                                      trip.tripType !==
                                                        'guide') ? (
                                                      <>
                                                        {'Day ' + day.dayNumber}
                                                      </>
                                                    ) : (
                                                      <>Guide</>
                                                    )}
                                                  </TitleH3>
                                                  {trip && trip.startDate && (
                                                    <Box pl={0.5}>
                                                      <Typography
                                                        sx={theme => ({
                                                          fontWeight: 500,
                                                          fontSize: 18,
                                                          marginTop:
                                                            theme.spacing(0.5),
                                                          color: '#4E4B66',
                                                          [theme.breakpoints.down(
                                                            'sm',
                                                          )]: {
                                                            fontSize: 14,
                                                          },
                                                        })}
                                                      >
                                                        {returnFormattedDate(
                                                          trip.startDate,
                                                          dayIndex,
                                                          false,
                                                        )}
                                                      </Typography>
                                                    </Box>
                                                  )}
                                                </Stack>

                                                {/* </Stack> */}
                                                {!props.showEmptyDay &&
                                                  !collapsedSteps && (
                                                    <Box
                                                      sx={theme => ({
                                                        // zoom: 1.11,
                                                      })}
                                                    >
                                                      <ActionsDay
                                                        dayId={day.id}
                                                        marginLeft={true}
                                                        dayNumber={
                                                          day.dayNumber
                                                        }
                                                        iconsOnly={false}
                                                        tripType={
                                                          trip && trip.tripType
                                                            ? trip.tripType
                                                            : 'itinerary'
                                                        }
                                                      />
                                                    </Box>
                                                  )}
                                              </Stack>
                                              {!collapsedSteps && (
                                                <>
                                                  <Stack
                                                    direction="row"
                                                    sx={theme => ({
                                                      [theme.breakpoints.down(
                                                        'sm',
                                                      )]: {
                                                        // paddingLeft: '40px',
                                                      },
                                                    })}
                                                  >
                                                    {day &&
                                                      day.cities &&
                                                      day.cities.length > 0 && (
                                                        <MapsHomeWorkTwoToneIcon
                                                          sx={theme => ({
                                                            marginRight: 1,
                                                            width: 25,
                                                            height: 25,
                                                            alignSelf: 'center',
                                                            color: '#F39C6A',
                                                            [theme.breakpoints.down(
                                                              'sm',
                                                            )]: {
                                                              width: 20,
                                                              height: 20,
                                                            },
                                                          })}
                                                        />
                                                      )}
                                                    <Typography
                                                      sx={theme => ({
                                                        fontWeight: 500,
                                                        fontSize: 18,
                                                        color: '#003366',
                                                        [theme.breakpoints.down(
                                                          'sm',
                                                        )]: {
                                                          fontSize: 16,
                                                        },
                                                      })}
                                                    >
                                                      {day &&
                                                        day.cities &&
                                                        day.cities.length > 0 &&
                                                        day.cities.map(
                                                          (city, i) => (
                                                            <React.Fragment
                                                              key={i}
                                                            >
                                                              {city && (
                                                                <>
                                                                  {
                                                                    city.cityName
                                                                  }
                                                                  {i !==
                                                                    day.cities!
                                                                      .length -
                                                                      1 && (
                                                                    <>{', '}</>
                                                                  )}
                                                                </>
                                                              )}
                                                            </React.Fragment>
                                                          ),
                                                        )}
                                                    </Typography>
                                                    {/* <Box className={classes.alignButtons}>
                                                      <DayInfoModal day={day} />
                                                      </Box> */}
                                                  </Stack>
                                                  <DayFullness
                                                    dayId={day!.id}
                                                    numberOfStepsInDay={
                                                      day?.steps?.length ?? 0
                                                    }
                                                    tripType={
                                                      trip && trip.tripType
                                                        ? trip.tripType
                                                        : 'itinerary'
                                                    }
                                                  />
                                                </>
                                              )}
                                            </Stack>
                                          </>
                                        ) : (
                                          <>
                                            {!props.showEmptyDay &&
                                              !collapsedSteps && (
                                                <>
                                                  {trip &&
                                                  (!trip.tripType ||
                                                    trip.tripType !==
                                                      'guide') ? (
                                                    <>
                                                      <Stack
                                                        direction="row"
                                                        alignItems="flex-start"
                                                        sx={{
                                                          width: '100%',
                                                          position: 'relative',
                                                          overflow: 'hidden',
                                                        }}
                                                      >
                                                        {collapsedSteps && (
                                                          <ContainedHandle>
                                                            <DragIndicatorIcon
                                                              sx={theme => ({
                                                                width: 25,
                                                                height: 25,
                                                                marginTop: 1.5,
                                                              })}
                                                            />
                                                          </ContainedHandle>
                                                        )}
                                                        <Box
                                                          sx={{
                                                            flexGrow: 1, // Allows the PublicPlaceCard to take up the remaining space
                                                            minWidth: 0, // Ensures the content can shrink and not overflow
                                                          }}
                                                        >
                                                          <ActionsDay
                                                            dayId={day.id}
                                                            dayNumber={
                                                              day.dayNumber
                                                            }
                                                            iconsOnly={true}
                                                            dayDate={
                                                              trip?.startDate
                                                                ? trip.startDate
                                                                : undefined
                                                            }
                                                            tripType={
                                                              trip &&
                                                              trip.tripType
                                                                ? trip.tripType
                                                                : 'itinerary'
                                                            }
                                                            marginLeft={false}
                                                          />
                                                        </Box>
                                                      </Stack>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}

                                            {!props.showEmptyDay &&
                                              collapsedSteps && (
                                                <Stack
                                                  direction="row"
                                                  alignItems="center"
                                                  sx={{
                                                    width: '100%',
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                  }}
                                                >
                                                  {collapsedSteps && (
                                                    <ContainedHandle>
                                                      <DragIndicatorIcon
                                                        sx={theme => ({
                                                          width: 25,
                                                          height: 25,
                                                        })}
                                                      />
                                                    </ContainedHandle>
                                                  )}
                                                  <Stack
                                                    direction="row"
                                                    width="100%"
                                                    sx={{
                                                      position: 'relative',
                                                      overflow: 'hidden',
                                                      borderRadius: '20px',
                                                      border:
                                                        '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
                                                      padding: 1,
                                                      paddingLeft: 1.5,
                                                      marginBottom: 1,
                                                      boxShadow:
                                                        '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                                    }}
                                                  >
                                                    <TodayTwoToneIcon
                                                      sx={theme => ({
                                                        marginLeft: 0,
                                                        marginRight: 1,
                                                        width: 25,
                                                        height: 25,
                                                        alignSelf: 'center',
                                                        color: '#F39C6A',
                                                        [theme.breakpoints.down(
                                                          'sm',
                                                        )]: {
                                                          marginLeft: 0,
                                                        },
                                                      })}
                                                    />
                                                    <TitleH3 color="#001B30">
                                                      {trip &&
                                                      (!trip.tripType ||
                                                        trip.tripType !==
                                                          'guide') ? (
                                                        <>
                                                          {'Day ' +
                                                            day.dayNumber}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </TitleH3>
                                                  </Stack>
                                                </Stack>
                                              )}
                                          </>
                                        )}
                                      </Stack>
                                      // </ContainedHandle>
                                    )}
                                  </Box>
                                  <Box width="100%">
                                    <AddAccomodationDirections
                                      dayId={day.id}
                                      isFirstDirection={true}
                                      isLastDirection={false}
                                      tripUser={'user'}
                                      tripType={
                                        trip && trip.tripType
                                          ? trip.tripType
                                          : 'itinerary'
                                      }
                                    />
                                    <DraggableDroppableSteps
                                      tripId={trip!.id}
                                      day={day}
                                      dayIndex={dayIndex}
                                      collapsedSteps={collapsedSteps}
                                      showEmptyDay={props.showEmptyDay}
                                      tripType={
                                        trip && trip.tripType
                                          ? trip.tripType
                                          : 'itinerary'
                                      }
                                      selectedStepId={props.selectedStepId}
                                      stepRefs={props.stepRefs}
                                    />
                                    <AddAccomodationDirections
                                      dayId={day.id}
                                      isFirstDirection={false}
                                      isLastDirection={true}
                                      tripUser={'user'}
                                      tripType={
                                        trip && trip.tripType
                                          ? trip.tripType
                                          : 'itinerary'
                                      }
                                    />
                                  </Box>
                                </Stack>
                              </Item>
                            </>
                            {day.steps && day.steps.length > 0 && (
                              <>
                                {!props.showEmptyDay && !collapsedSteps && (
                                  <ActionsDay
                                    marginLeft={true}
                                    dayId={day.id}
                                    dayNumber={day.dayNumber}
                                    iconsOnly={true}
                                    dayDate={
                                      trip?.startDate
                                        ? trip.startDate
                                        : undefined
                                    }
                                    tripType={
                                      trip && trip.tripType
                                        ? trip.tripType
                                        : 'itinerary'
                                    }
                                  />
                                )}
                              </>
                            )}
                            {day.steps &&
                              day.steps.length === 0 &&
                              trip &&
                              trip.tripType &&
                              trip.tripType === 'guide' && (
                                <>
                                  {!props.showEmptyDay && !collapsedSteps && (
                                    <ActionsDay
                                      marginLeft={true}
                                      dayId={day.id}
                                      dayNumber={day.dayNumber}
                                      iconsOnly={true}
                                      dayDate={
                                        trip?.startDate
                                          ? trip.startDate
                                          : undefined
                                      }
                                      tripType={trip!.tripType}
                                    />
                                  )}
                                </>
                              )}
                            {/* )} */}
                          </>
                        )}
                      </Draggable>
                    )}
                  </Box>
                ))}
              {provided.placeholder}
              {trip &&
                trip.itinerary &&
                (!trip.tripType || trip.tripType !== 'guide') && (
                  <>
                    {/* <Divider sx={{ marginTop: 3, marginLeft: 2 }} /> */}
                    <Stack
                      direction="row"
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      flexWrap="wrap"
                      mt={2}
                      mb={3}
                    >
                      <Box
                        onClick={async () => {
                          gtag('event', 'add-new-day');
                          const response = await addDay({
                            variables: {
                              itinerary_id: trip.itinerary!.id,
                            },
                          });
                          dispatch(setDays(response.data.createUserDay.days));
                        }}
                        sx={theme => ({
                          fontSize: 16,
                          fontWeight: 600,
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 3,
                          paddingRight: 3,
                          color: '#FFFFFF',
                          backgroundColor: '#36454F',
                          borderRadius: 20,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          boxShadow: 'none', // Disable shadow
                          '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#4A5D6F',
                          },
                          [theme.breakpoints.down('sm')]: {
                            fontSize: 14,
                          },
                        })}
                      >
                        {loadingNewDay ? (
                          <Loader color="primary" size={20} />
                        ) : (
                          <>
                            {'Add another day (Day ' + (days.length + 1) + ')'}
                          </>
                        )}
                      </Box>
                      <br />
                      {errorNewDay && (
                        <div>Error adding day to this itinerary</div>
                      )}
                    </Stack>
                  </>
                )}
            </ContainerDay>
          )}
        </Droppable>
      </Box>
    </>
  );
}

import { useMutation } from '@apollo/client';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addTransport } from '../../store/TransportSlice';
import { LoadingDots } from '../../theme-components/Loader';
import { formatSecondsToTime } from '../../utils/helpers';
import { MUTATION_TRANSPORT_STEP_DETAILS } from '../gql-public/getTransportStepDetailsMutation';

export const TransportStepDetails = ({
  currentStep,
  nextStep,
  tripType,
  dayId,
}) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null); // Make sure ref is typed

  const [getTransportStep, { data, loading: loadingTransport, error }] =
    useMutation(MUTATION_TRANSPORT_STEP_DETAILS);

  // Intersection Observer Effect
  useEffect(() => {
    if (currentStep && nextStep) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            setIsVisible(true); // Set visibility state to true
            observer.unobserve(entry.target); // Stop observing once visible
          }
        },
        {
          root: null, // Viewport is root
          rootMargin: '4000px', // Trigger when 300px below the viewport
          threshold: 0.1, // Trigger when 10% of the element is visible
        },
      );

      if (componentRef.current) {
        observer.observe(componentRef.current);
      } else {
      }

      return () => {
        if (componentRef.current) {
          observer.disconnect();
        }
      };
    }
  }, [currentStep, nextStep]); // Dependencies include currentStep and nextStep

  // API call when component becomes visible
  useEffect(() => {
    if (isVisible && currentStep && nextStep) {
      if (
        currentStep.latitude &&
        currentStep.latitude !== 0 &&
        currentStep.longitude &&
        currentStep.longitude !== 0 &&
        nextStep.latitude &&
        nextStep.latitude !== 0 &&
        nextStep.longitude &&
        nextStep.longitude !== 0
      ) {
        getTransportStep({
          variables: {
            start_step_id: currentStep.id,
            end_step_id: nextStep.id,
          },
        });
      }
    }
  }, [isVisible, getTransportStep, currentStep, nextStep]); // Dependencies include isVisible

  // seems like duplicate
  // useEffect(() => {
  //   if (isVisible && currentStep && nextStep) {
  //     getTransportStep({
  //       variables: {
  //         start_step_id: currentStep.id,
  //         end_step_id: nextStep.id,
  //       },
  //     });
  //   }
  // }, [isVisible, getTransportStep, currentStep, nextStep]);

  // Early returns for loading, error, or invalid data
  if (!currentStep || !nextStep) {
    return <Box mt={2} ref={componentRef} />;
  }

  if (tripType && tripType === 'guide') {
    return <Box mt={2} ref={componentRef} />;
  }

  if ((error && !loadingTransport) || (data && data.getDirections === null)) {
    return <Box mt={2} ref={componentRef} />;
  }

  let drivingTime = 0;
  let walkingTime = 0;

  if (data?.getDirections?.[0]?.durationMinutes) {
    if (data.getDirections[0].transportType === 'driving') {
      drivingTime = data.getDirections[0].durationMinutes;
    }
    if (data.getDirections[0].transportType === 'walking') {
      walkingTime = data.getDirections[0].durationMinutes;
    }
  }

  if (data?.getDirections?.[1]?.durationMinutes) {
    if (data.getDirections[1].transportType === 'driving') {
      drivingTime = data.getDirections[1].durationMinutes;
    }
    if (data.getDirections[1].transportType === 'walking') {
      walkingTime = data.getDirections[1].durationMinutes;
    }
  }

  // Handle case where both driving and walking times are 0
  if (drivingTime === 0 && walkingTime === 0) {
    return <Box mt={2} ref={componentRef} />;
  } else {
    const stepId = currentStep.id;
    if (walkingTime > 0 && walkingTime < 1800) {
      const transportTime = walkingTime;
      dispatch(addTransport({ dayId, stepId, transportTime }));
    }
    if (drivingTime > 0 && (walkingTime > 1800 || walkingTime === 0)) {
      const transportTime = drivingTime;
      dispatch(addTransport({ dayId, stepId, transportTime }));
    }
  }

  return (
    <Box
      // display="flex"
      alignItems="center"
      // height="100px"
      ref={componentRef}
      sx={theme => ({
        width: '100%',
        // paddingLeft: 2,
        // paddingRight: 2,
        [theme.breakpoints.down('sm')]: {
          // paddingLeft: 3,
          // paddingRight: 0,
        },
      })}
    >
      <Box>
        {!loadingTransport && walkingTime === 0 && drivingTime === 0 ? (
          <Divider
            orientation="vertical"
            sx={theme => ({
              padding: 0,
              // width: '100%',
              height: '50px',
              borderColor: theme.palette.divider,
              // marginLeft: 2.5,

              '& .MuiDivider-wrapper': {
                padding: 0, // Remove padding from the wrapper
                margin: 0, // Adjust margin as needed
              },
              [theme.breakpoints.down('sm')]: {
                // marginLeft: 0,
              },
            })}
          />
        ) : (
          <Divider
            orientation="vertical"
            sx={theme => ({
              padding: 0,
              // '&.MuiDivider-root': {
              //   width: '4px', // Set the thickness of the vertical divider
              //   backgroundColor: 'blue', // Set the color of the divider
              //   margin: '0 auto', // Center the divider
              // },
              // width: '3px',
              // '&.MuiDivider-root': {
              width: '2px', // Set the thickness of the vertical divider
              backgroundColor: '#f1eaff',

              color: '#f1eaff', // Set the color of the divider
              margin: '0 auto', // Center the divider itself
              position: 'relative', // Ensure the children and divider are properly aligned
              // },
              display: 'flex',
              justifyContent: 'center', // Center the content vertically with the divider
              alignItems: 'center',
              height: '70px',
              border: 'none',
              // borderColor: theme.palette.divider,
              // marginLeft: 2.5,
              '& .MuiDivider-wrapper': {
                padding: 0, // Remove padding from the wrapper
                margin: 0, // Adjust margin as needed
              },
              [theme.breakpoints.down('sm')]: {
                // marginLeft: 0,
              },
            })}
            textAlign="center"
          >
            {loadingTransport && <LoadingDots />}
            {!loadingTransport && (walkingTime > 0 || drivingTime > 0) && (
              <Box
                sx={theme => ({
                  fontWeight: 500,
                  fontSize: 14,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

                  color: '#6E7191',
                  padding: theme.spacing(0.75, 2),
                  backgroundColor: '#faf5ff',
                  // backgroundColor: '#e4d9ff ',
                  borderRadius: theme.shape.borderRadius,
                  border: `2px solid #f1eaff`,
                  display: 'inline-block',
                })}
              >
                {walkingTime > 0 && walkingTime < 1800 && (
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <DirectionsWalkIcon sx={{ width: 22, height: 22 }} />
                    <Typography sx={{ fontWeight: 500 }}>
                      {formatSecondsToTime(walkingTime)}
                    </Typography>
                  </Stack>
                )}
                {drivingTime > 0 &&
                  (walkingTime > 1800 || walkingTime === 0) && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <DirectionsCarIcon sx={{ width: 22, height: 22 }} />
                      <Typography sx={{ fontWeight: 500 }}>
                        {formatSecondsToTime(drivingTime)}
                      </Typography>
                    </Stack>
                  )}
              </Box>
            )}
          </Divider>
        )}
      </Box>
    </Box>
  );
};

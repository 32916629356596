import { Box, Divider } from '@mui/material';
import TextDescription, { TitleH3 } from './Typography';
import Space from './Spacing';
import { ReactNode } from 'react';

interface ModalHeaderProps {
  title?: string;
  description?: string | ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, description }) => {
  return (
    <>
      {title && (
        <>
          <TitleH3>{title}</TitleH3>
          <Space size="sm" />
          <Divider sx={{ width: '80%', mx: 'auto' }} />
        </>
      )}
      {description && (
        <>
          <Space size="sm" />
          <TextDescription size="md" sx={{ textAlign: 'center' }}>
            {description}
          </TextDescription>
        </>
      )}
      <Space size="sm" />
    </>
  );
};

export default ModalHeader;

import { useMutation, useQuery } from '@apollo/client';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import PersonIcon from '@mui/icons-material/PersonOutlineTwoTone';
import { Divider, Typography, Stack, IconButton, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { UserPublicInfo } from '../../generated/public_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { setUser, userSelector } from '../../store/UserSlice';
import { LoaderCentred } from '../../theme-components/Loader';
import { Body1, TitleH2 } from '../../theme-components/Typography';
import { useAuth } from '../auth/firebase';
import SignUpModal from '../auth/SignUpModal';
import { QUERY_GET_SIDEBAR_COUNTRIES } from '../gql-public/sideBarQuery';
import { QUERY_GET_USER_SIDEBAR_COUNTRIES } from '../gql-user/sideBarQuery';
import { MUTATION_FOLLOW_USER } from '../gql-user/user';
import { RenderHtmlFromResponse } from '../RenderHtmlFromResponse';

import CopyTrip from './CopyTrip';
import { TripCountryBox } from './TripCountryBox';
import { TripUser } from './TripItinerary';

type TripDetailsProps = {
  tripUser: TripUser;
};

export function TripDetails({ tripUser }: TripDetailsProps) {
  let isPreview = false;
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);

  const trip = tripUser === 'user' ? userTrip : publicTrip;

  if (window.location.href.indexOf('preview') > -1) {
    isPreview = true;
  }

  const {
    data: countriesData,
    error: countriesError,
    loading: countriesLoading,
  } = useQuery(
    isPreview ? QUERY_GET_USER_SIDEBAR_COUNTRIES : QUERY_GET_SIDEBAR_COUNTRIES,
    {
      variables: { trip_id: trip && trip.id },
    },
  );

  if (!trip) {
    return <></>;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingLeft: 1,
      })}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <TitleH2>{trip.name}</TitleH2>

        {trip && (!trip.tripType || trip.tripType !== 'guide') && (
          <AuthorizedApolloProvider>
            <CopyTrip tripId={trip.id} />
          </AuthorizedApolloProvider>
        )}
      </Stack>
      {trip.userDetails && trip.userDetails.nickName && (
        <AuthorizedApolloProvider>
          <ContainedUserProfile tripUser={trip.userDetails} />
        </AuthorizedApolloProvider>
      )}
      <Stack
        direction="row"
        alignItems="center"
        sx={theme => ({
          width: '100%',
          marginBottom: 1,
        })}
      >
        {trip && (!trip.tripType || trip.tripType !== 'guide') && (
          <>
            <DateRangeTwoToneIcon
              sx={theme => ({
                marginRight: 1,
                width: 25,
                height: 25,
                color: theme.palette.icon.main,
                [theme.breakpoints.down('sm')]: {
                  width: 20,
                  height: 20,
                },
              })}
            />
            <Typography
              sx={theme => ({
                fontWeight: 500,
                fontSize: 18,
                color: theme.palette.secondary.main,
                [theme.breakpoints.down('sm')]: {
                  fontSize: 16,
                },
              })}
            >
              <>
                {trip.length && (
                  <>
                    {trip!.length + ' days itinerary'}
                    {' ('}
                  </>
                )}

                {trip &&
                  trip.itineraryCountries &&
                  trip.itineraryCountries.length > 0 &&
                  trip.itineraryCountries.map((country, index) => (
                    <React.Fragment key={index}>
                      {country && (
                        <>
                          {country.countryName}
                          {trip.itineraryCountries &&
                            index !== trip.itineraryCountries.length - 1 && (
                              <>{', '}</>
                            )}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                {trip.length && <>{')'}</>}
              </>
            </Typography>
          </>
        )}
      </Stack>
      <Box>
        <Divider />
      </Box>
      {trip.description && (
        <Box>
          <Body1>
            <RenderHtmlFromResponse content={trip.description} />
          </Body1>
        </Box>
      )}
      <Box mb={2}>
        <Divider />
      </Box>
      {countriesLoading && <LoaderCentred />}
      {trip && (!trip.tripType || trip.tripType !== 'guide') && (
        <>
          {' '}
          {!countriesLoading && !countriesError && !isPreview && (
            <TripCountryBox
              trip={isPreview ? countriesData.userTrip : countriesData.trip}
            />
          )}
        </>
      )}
    </Box>
  );
}

export function ContainedUserProfile(props: { tripUser: UserPublicInfo }) {
  const { isAuthenticated } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const { myUser } = useSelector(userSelector);

  const [followUser, { data }] = useMutation(MUTATION_FOLLOW_USER, {
    variables: {
      following_user_id: props.tripUser.id,
      follow: !isFollowing,
    },
  });

  useEffect(() => {
    if (myUser) {
      const following =
        myUser.following &&
        myUser.following.some(follower => follower!.id === props.tripUser.id);
      if (following === true || following === false) {
        setIsFollowing(following);
      }
    }
    if (data && data.followUser) {
      dispatch(setUser(data.followUser)); // Dispatch the updated user info to the store
    }
  }, [myUser, data, dispatch]);

  return (
    <>
      {props.tripUser && props.tripUser.nickName && (
        <Stack direction="row" alignItems="center" mb={1}>
          <Avatar
            alt="Profile Picture"
            src={
              props.tripUser.profilePictureUrl
                ? props.tripUser.profilePictureUrl
                : ''
            }
            sx={{
              width: props.tripUser.profilePictureUrl ? 30 : 25,
              height: props.tripUser.profilePictureUrl ? 30 : 25,
            }}
          >
            <PersonIcon />
          </Avatar>
          <Box
            sx={{
              display: 'inline-block', // Ensures the background applies only to the text
              borderRadius: '4px',
              padding: 0.5, // Rounded corners
              '&:hover': {
                backgroundColor: 'rgba(255, 165, 0, 0.2)', // Light background color on hover
              },
            }}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              sx={{
                fontWeight: 600,
                textDecoration: 'none', // No underline for the entire text
                cursor: 'pointer', // Change cursor to pointer on hover
                // Split the "@" sign from the nickname for styling
              }}
              onClick={() => {
                gtag('event', 'app-bar-create-trip');
                navigate('/user-trips/' + props.tripUser.id);
              }}
            >
              <span style={{ textDecoration: 'none' }}>@</span>
              <span style={{ textDecoration: 'underline' }}>
                {props.tripUser.nickName}
              </span>
            </Typography>
          </Box>
          <IconButton
            size="small"
            color="error"
            onClick={async () => {
              if (isAuthenticated) {
                try {
                  gtag('event', 'follow-user');

                  await followUser();
                } catch (e) {
                  // TODO handle error
                }
              } else {
                gtag('event', 'follow-user-login');
                // loginWithRedirect({
                //   appState: { targetUrl: window.location.pathname },
                // });
                handleOpenSignUp();
              }
            }}
          >
            {isFollowing ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Stack>
      )}
      <SignUpModal open={isSignUpModalOpen} onClose={handleCloseSignUp} />
    </>
  );
}

export function FollowUser(props: { publicUser: UserPublicInfo }) {
  const { isAuthenticated } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const { myUser } = useSelector(userSelector);

  const [followUser, { data }] = useMutation(MUTATION_FOLLOW_USER, {
    variables: {
      following_user_id: props.publicUser.id,
      follow: !isFollowing,
    },
  });

  useEffect(() => {
    if (myUser) {
      const following =
        myUser.following &&
        myUser.following.some(follower => follower!.id === props.publicUser.id);
      if (following === true || following === false) {
        setIsFollowing(following);
      }
    }
    if (data && data.followUser) {
      dispatch(setUser(data.followUser)); // Dispatch the updated user info to the store
    }
  }, [myUser, data, dispatch]);

  return (
    <>
      {props.publicUser && props.publicUser.nickName && (
        <Stack direction="row" alignItems="center" mb={1}>
          <IconButton
            size="small"
            color="error"
            onClick={async () => {
              if (isAuthenticated) {
                try {
                  gtag('event', 'follow-user');

                  await followUser();
                } catch (e) {
                  // TODO handle error
                }
              } else {
                gtag('event', 'follow-user-login');
                // loginWithRedirect({
                //   appState: { targetUrl: window.location.pathname },
                // });
                handleOpenSignUp();
              }
            }}
          >
            {isFollowing ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Stack>
      )}
      <SignUpModal open={isSignUpModalOpen} onClose={handleCloseSignUp} />
    </>
  );
}

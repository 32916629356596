import { gql } from 'graphql-tag';

export const MUTATION_UPDATE_SHARED_TRIP_INFO = gql`
  mutation editSharedTripInfo(
    $shared_id: String!
    $trip_id: String!
    $shareWithEmail: String!
    $canEdit: Boolean!
    $canView: Boolean!
  ) {
    editSharedTripInfo(
      shared_id: $shared_id
      trip_id: $trip_id
      shareWithEmail: $shareWithEmail
      canEdit: $canEdit
      canView: $canView
    ) {
      id
      sharedWithEmail
      canEdit
      canView
    }
  }
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Maybe, Place } from '../generated/user_graphql';

export interface PlacesState {
  places: Maybe<Place>[];
  loading: boolean;
  errors: string;
}

const initialState: PlacesState = {
  places: [],
  loading: false,
  errors: '',
};

const likedPlacesSlice = createSlice({
  name: 'likedPlaces',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },

    setLikedPlaces: (state, { payload }: PayloadAction<Maybe<Place>[]>) => {
      state.places = payload;
    },
  },
});

export const { setLoading, setErrors, setLikedPlaces } =
  likedPlacesSlice.actions;

export const likedPlacesSliceReducer = likedPlacesSlice.reducer;

export const likedPlacesSelector = (state: { userLikedPlaces: PlacesState }) =>
  state.userLikedPlaces;

export function LikeSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 646 478"
      {...props}
    >
      <path fill="#f2f2f2" d="M39.87 476.965a37 37 0 0 1 74 0" />
      <path
        fill="#ccc"
        d="M76.87 476.965a1 1 0 0 1-1-1v-19.5a1 1 0 0 1 2 0v19.5a1 1 0 0 1-1 1Z"
      />
      <path
        fill="#ccc"
        d="M76.87 470.264a.995.995 0 0 1-.651-.241l-12-10.3a1 1 0 1 1 1.302-1.517l12 10.3a1 1 0 0 1-.651 1.758Z"
      />
      <path fill="#f2f2f2" d="M153.87 476.965a37 37 0 0 1 74 0" />
      <path
        fill="#ccc"
        d="M190.87 476.965a1 1 0 0 1-1-1v-19.5a1 1 0 0 1 2 0v19.5a1 1 0 0 1-1 1Z"
      />
      <path
        fill="#ccc"
        d="M190.87 470.264a.995.995 0 0 1-.651-.241l-12-10.3a1 1 0 0 1 1.302-1.517l12 10.3a1 1 0 0 1-.651 1.758Z"
      />
      <circle cx={230.87} cy={159.402} r={15} fill="#fff" />
      <path
        fill="#5e90ff"
        d="M230.87 144.402a15 15 0 1 0 15 15 15 15 0 0 0-15-15Zm-3.591 21.55a.423.423 0 0 1-.423.422h-3.38a.423.423 0 0 1-.423-.423v-8.028a.423.423 0 0 1 .423-.423h3.38a.423.423 0 0 1 .423.423Zm11.408-6.339a1.268 1.268 0 0 1-1.268 1.268v.021a1.056 1.056 0 0 1 0 2.07v.444a.845.845 0 0 1 0 1.69h-8.45a.423.423 0 0 1-.423-.423v-6.76c0-2.113 3.38-5.07 3.38-5.07v-1.03a1.279 1.279 0 0 1 2.522-.3l.014.062a6.4 6.4 0 0 1-1.027 4.237h2.601c.039-.004.076-.012.116-.012h1.267a2.834 2.834 0 0 0 1.268 3.803Z"
      />
      <circle cx={252.87} cy={72.402} r={21} fill="#fff" />
      <path
        fill="#5e90ff"
        d="M252.87 51.402a21 21 0 1 0 21 21 21 21 0 0 0-21-21Zm-5.028 30.169a.592.592 0 0 1-.592.591h-4.732a.592.592 0 0 1-.592-.591V70.33a.592.592 0 0 1 .592-.591h4.732a.592.592 0 0 1 .592.591Zm15.972-8.873a1.775 1.775 0 0 1-1.775 1.774v.03a1.479 1.479 0 0 1 0 2.898v.621a1.183 1.183 0 1 1 0 2.367h-11.83a.592.592 0 0 1-.592-.592v-9.465c0-2.957 4.732-7.098 4.732-7.098V61.79a1.79 1.79 0 0 1 3.53-.42l.02.087c.59 2.958-1.438 5.932-1.438 5.932h3.642c.054-.005.106-.016.161-.016h1.775a1.775 1.775 0 0 1 0 3.549 1.775 1.775 0 0 1 1.775 1.775Z"
      />
      <path
        fill="#a0616a"
        d="M299.623 297.6a10.743 10.743 0 0 0 7.444-14.695l39.603-89.472-22.655-5.706-31.804 89.743a10.8 10.8 0 0 0 7.412 20.13Z"
      />
      <path
        fill="#6c63ff"
        d="m335.309 221.435-22.034-10.314a4.817 4.817 0 0 1-1.974-7.021l13.39-20.223a13.377 13.377 0 0 1 24.198 11.413l-6.924 23.163a4.817 4.817 0 0 1-6.656 2.982Z"
      />
      <path
        fill="#3f3d56"
        d="m258.702 209.928 24.292 17.198a1.823 1.823 0 0 0 2.14 0l6.565-4.929a1.8 1.8 0 0 0 .118-2.811l-18.693-16.669a1.847 1.847 0 0 0-1.823-.353l-12.164 4.353a1.835 1.835 0 0 0-.435 3.211Z"
      />
      <path
        fill="#fff"
        d="m260.91 208.601 21.891 15.5a.643.643 0 0 0 .765.006l5.904-4.432a.627.627 0 0 0 .144-.878.637.637 0 0 0-.092-.1l-16.86-15.035a.656.656 0 0 0-.648-.12l-10.956 3.92a.654.654 0 0 0-.148 1.14Z"
      />
      <path
        fill="#a0616a"
        d="m347.236 466.333-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
      />
      <path
        fill="#2f2e41"
        d="m350.363 478.217-39.531-.001v-.5a15.386 15.386 0 0 1 15.387-15.387l24.144.001Z"
      />
      <path
        fill="#a0616a"
        d="m388.236 466.333-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
      />
      <path
        fill="#2f2e41"
        d="m391.363 478.217-39.531-.001v-.5a15.386 15.386 0 0 1 15.387-15.387l24.144.001ZM387.675 453.178q-.166 0-.333-.012l-14.1-1.044a4.527 4.527 0 0 1-4.064-3.53l-24.48-134.372a1.5 1.5 0 0 0-2.96.437l8.692 132.393a4.5 4.5 0 0 1-4.486 4.852h-10.737a4.5 4.5 0 0 1-4.192-2.86c-27-70.706-17.223-209.247-17.145-210.64l1-1 53.778 9.285.114.11c18.374 13.497 26.326 171.55 23.405 201.617a5.387 5.387 0 0 1-1.33 3.468 4.504 4.504 0 0 1-3.162 1.296Z"
      />
      <circle cx={320.074} cy={131.796} r={24.561} fill="#a0616a" />
      <path
        fill="#6c63ff"
        d="M376.404 269.505a3.734 3.734 0 0 1-.745-.075l-63.078-8.094c-2.258-.456-3.87-2.866-3.748-5.608l6.037-33.326c-8-10-.44-35.826 4.448-42.356l-1.89-5.116a6.35 6.35 0 0 1-.085-4.129 5.065 5.065 0 0 1 2.376-2.99l25.6-13.6c2.269-1.207 4.931.028 5.937 2.748l5.02 13.586-.077.04c12.02 6.443 22.671 12.817 14.671 46.817l10.03 46.956a5.82 5.82 0 0 1-1.669 3.949 4.072 4.072 0 0 1-2.827 1.198Z"
      />
      <path
        fill="#a0616a"
        d="M287.403 214.455a10.526 10.526 0 0 1 1.114 1.227l49.476-3.419 5.307-10.815 17.37 5.876-7.535 20.06a8 8 0 0 1-8.094 5.165l-56.26-4.267a10.497 10.497 0 1 1-1.378-13.827Z"
      />
      <path
        fill="#6c63ff"
        d="M337.1 209.879a4.495 4.495 0 0 1 .145-3.745l10.203-20.15a12.497 12.497 0 0 1 23.738 7.826l-3.93 22.316a4.5 4.5 0 0 1-5.853 3.489l-21.564-7.18a4.495 4.495 0 0 1-2.74-2.556Z"
      />
      <path
        fill="#ff6584"
        d="M259.885 193.426a1.097 1.097 0 0 1-1.495 0l-.574-.519c-4.698-4.31-7.41-7.11-7.41-10.602 0-2.848 1.802-5.05 4.628-5.05a5.404 5.404 0 0 1 4.106 1.927 5.396 5.396 0 0 1 4.102-1.926c2.83 0 4.628 2.193 4.628 5.049 0 3.484-2.713 6.284-7.41 10.602ZM225.57 133.698a2.102 2.102 0 0 1-2.865 0l-1.1-.994c-9.002-8.26-14.2-13.626-14.2-20.316 0-5.457 3.454-9.674 8.87-9.674a10.354 10.354 0 0 1 7.866 3.692 10.34 10.34 0 0 1 7.86-3.692c5.422 0 8.87 4.203 8.87 9.674 0 6.676-5.199 12.042-14.2 20.316ZM226.999 40.244a2.73 2.73 0 0 1-3.722 0l-1.428-1.292c-11.692-10.728-18.443-17.697-18.443-26.387C203.406 5.477 207.89 0 214.925 0a13.448 13.448 0 0 1 10.217 4.795A13.43 13.43 0 0 1 235.352 0c7.042 0 11.518 5.46 11.518 12.565 0 8.671-6.751 15.641-18.443 26.387Z"
      />
      <path fill="#ccc" d="M645 478.402H1a1 1 0 0 1 0-2h644a1 1 0 0 1 0 2Z" />
      <circle cx={333.87} cy={92.402} r={20} fill="#2f2e41" />
      <path
        fill="#2f2e41"
        d="M296.823 134.833c-1.954.206-4.031.392-5.79-.486a6.876 6.876 0 0 1-3.437-5.186c-3.726-24.76 17.327-22.723 19.818-23.795 1.674-.72 2.907-2.172 4.403-3.213 3.272-2.277 7.517-2.444 11.503-2.53 4.731-.102 9.634-.172 13.977 1.71a20.937 20.937 0 0 1 9.29 8.563 43.24 43.24 0 0 1 4.608 11.933c1.204 4.787 2.023 9.85.783 14.628a18.09 18.09 0 0 1-16.419 13.159c1.616-5.707-1.91-12.033-7.143-14.826a20.642 20.642 0 0 0-13.617-1.621 59.94 59.94 0 0 1-9.032 1.125c-2.978.111-6.553.286-8.944.538Z"
      />
      <path
        fill="#f2f2f2"
        d="M617.612 211.756c0-48.263-39.152-162.79-87.448-162.79s-87.449 114.527-87.449 162.79a87.448 87.448 0 0 0 174.897 0Z"
      />
      <path
        fill="#ccc"
        d="M530.164 477.402a1 1 0 0 1-1-1v-276.14a1 1 0 1 1 2 0v276.14a1 1 0 0 1-1 1Z"
      />
      <path
        fill="#ccc"
        d="M530.164 238.402a.998.998 0 0 1-.756-.345l-50.294-58a1 1 0 0 1 1.512-1.31l50.293 58a1 1 0 0 1-.755 1.655ZM530.164 271.874a1 1 0 0 1-.707-1.707l28.577-28.578a1 1 0 1 1 1.414 1.414l-28.577 28.579a.996.996 0 0 1-.707.292ZM530.164 381.874a1 1 0 0 1-.707-1.707l28.577-28.578a1 1 0 0 1 1.414 1.414l-28.577 28.579a.996.996 0 0 1-.707.292Z"
      />
    </svg>
  );
}

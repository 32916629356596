import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import LanguageIcon from '@mui/icons-material/Language';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Button, colors } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { Country, Trip } from '../../generated/user_graphql';

import { TripSidebar } from './TripSidebar';

export function TripCountryBox(props: { trip?: Trip; country?: Country }) {
  const navigate = useNavigate();

  function onShowSeasons() {
    gtag('event', 'public-country-seasons');
    navigate('?modal=seasons');
  }

  function onShowTips() {
    gtag('event', 'public-country-tips');
    navigate('?modal=tips');
  }

  function onShowVaccinations() {
    gtag('event', 'public-country-health');
    navigate('?modal=vaccinations');
  }

  function onShowCountriesDetails() {
    gtag('event', 'public-country-details');
    navigate('?modal=countries_details');
  }
  function onShowVisas() {
    gtag('event', 'public-country-visas');
    navigate('?modal=visas');
  }

  let showAboutCountry = false;
  let showCountryVaccination = false;
  let showCountryVisa = false;
  let showCountryTips = false;
  let showCountrySeasons = false;
  let country: Country | null = null;

  if (props.trip && props.trip.countries && props.trip.countries[0]) {
    country = props.trip.countries[0];

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (
          recommendation!.type === 'RECOMMENDATION_VACCINATION' ||
          recommendation!.type === 'RECOMMENDATION_HEALTH'
        ) {
          showCountryVaccination = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (
          recommendation!.type !== 'RECOMMENDATION_VISAS' &&
          recommendation!.type !== 'RECOMMENDATION_VACCINATION' &&
          recommendation!.type !== 'RECOMMENDATION_HEALTH'
        ) {
          showCountryTips = true;
        }
      });
    }
  }

  if (props.country) {
    country = props.country;

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (
          recommendation!.type === 'RECOMMENDATION_VACCINATION' ||
          recommendation!.type === 'RECOMMENDATION_HEALTH'
        ) {
          showCountryVaccination = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (
          recommendation!.type !== 'RECOMMENDATION_VISAS' &&
          recommendation!.type !== 'RECOMMENDATION_VACCINATION' &&
          recommendation!.type !== 'RECOMMENDATION_HEALTH'
        ) {
          showCountryTips = true;
        }
      });
    }
  }

  return (
    <>
      <Box bgcolor="#ffd993" style={{ borderRadius: 10 }}>
        <Box
          border={1}
          borderColor={colors.grey[300]}
          bgcolor="#FFFFFF"
          ml={1}
          pl={2}
          style={{
            borderLeft: 0,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <Box
            sx={theme => ({
              padding: 0.8,
              // width: 500,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                minWidth: 230,
              },
            })}
          >
            <Box>
              {showAboutCountry && (
                <LinkButtonOld onClick={onShowCountriesDetails}>
                  <LanguageIcon
                    sx={theme => ({
                      width: 35,
                      height: 35,
                      color: '#4D4B66',
                      [theme.breakpoints.down('sm')]: {
                        width: 30,
                        height: 30,
                      },
                    })}
                  />
                  <LinkButtonOldText>
                    {'About ' + country!.name}
                  </LinkButtonOldText>
                </LinkButtonOld>
              )}
              {showCountryTips && (
                <LinkButtonOld onClick={onShowTips}>
                  <AssignmentTurnedInIcon
                    sx={theme => ({
                      width: 35,
                      height: 35,
                      color: '#4D4B66',
                      [theme.breakpoints.down('sm')]: {
                        width: 30,
                        height: 30,
                      },
                    })}
                  />
                  <LinkButtonOldText>Tips before you go</LinkButtonOldText>
                </LinkButtonOld>
              )}
              {showCountryVisa && (
                <LinkButtonOld onClick={onShowVisas}>
                  <CardTravelTwoToneIcon
                    sx={theme => ({
                      width: 35,
                      height: 35,
                      color: '#4D4B66',
                      [theme.breakpoints.down('sm')]: {
                        width: 30,
                        height: 30,
                      },
                    })}
                  />
                  <LinkButtonOldText>Visas</LinkButtonOldText>
                </LinkButtonOld>
              )}
            </Box>
            <Box
              sx={theme => ({
                marginRight: '20%',
                [theme.breakpoints.down(1100)]: {
                  marginRight: '10%',
                },
              })}
            >
              {showCountryVaccination && (
                <LinkButtonOld onClick={onShowVaccinations}>
                  <LocalHospitalIcon
                    sx={{ width: 35, height: 35, color: '#4D4B66' }}
                  />
                  <LinkButtonOldText>Health</LinkButtonOldText>
                </LinkButtonOld>
              )}
              {showCountrySeasons && (
                <LinkButtonOld onClick={onShowSeasons}>
                  <WbSunnyIcon
                    sx={theme => ({
                      width: 35,
                      height: 35,
                      color: '#4D4B66',
                      [theme.breakpoints.down('sm')]: {
                        width: 30,
                        height: 30,
                      },
                    })}
                  />
                  <LinkButtonOldText>When to go</LinkButtonOldText>
                </LinkButtonOld>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {country && <TripSidebar countries={[country]} />}
    </>
  );
}

function LinkButtonOld(props: {
  readonly onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  readonly children?: ReactNode;
}) {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      })}
      onClick={props.onClick}
    >
      {props.children}
    </Box>
  );
}

function LinkButtonOldText(props: { children: ReactNode }) {
  return (
    <Button
      sx={theme => ({
        textTransform: 'none',
        marginLeft: 0.5,
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      })}
    >
      {props.children}
    </Button>
  );
}

import { gql } from 'graphql-tag';

export const QUERY_GET_TRIP_SHARED_INFO = gql`
  query userTripSharedWithUsers($trip_id: String!) {
    userTripSharedWithUsers(trip_id: $trip_id) {
      id
      sharedWithEmail
      canEdit
      canView
    }
  }
`;

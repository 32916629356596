import { Day, Maybe, Step } from '../../../generated/user_graphql';

export const reorderDays = (list: Maybe<Day>[], startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderSteps = (
  days: Maybe<Day>[],
  sourceDropableId: string,
  startIndex,
  endIndex,
) => {
  let daySteps: Maybe<Step>[] = [];
  let dayIndex = -1;
  let countIndex = true;

  // Get index of the day where we are moving steps around, this index is
  // then used to only show spinner on this days/step rather then all steps with same step index.
  days.forEach(day => {
    if (countIndex) {
      dayIndex++;
    }

    if (day && day.id === sourceDropableId) {
      daySteps = day.steps!;
      countIndex = false;
    }
  });

  const newSteps = [...daySteps];

  const [removed] = newSteps.splice(startIndex, 1);
  newSteps.splice(endIndex, 0, removed);

  const newDays: Maybe<Day>[] = [];
  days.forEach(day => {
    if (day && day.id === sourceDropableId) {
      day = { id: day.id, dayNumber: day.dayNumber };
      day.steps = newSteps;
    }
    newDays.push(day);
  });

  return { newDays, dayIndex };
};

export const moveStepBetweenDays = (
  days: Maybe<Day>[],
  source,
  destination,
) => {
  // Get steps from source day
  let sourceSteps: Maybe<Step>[] = [];
  days.forEach(day => {
    if (day && day.id === source.droppableId) {
      sourceSteps = day.steps!;
    }
  });

  const newSourceSteps = [...sourceSteps];

  // Get steps from destination day
  let destinationSteps: Maybe<Step>[] = [];
  let destinationDayIndex = -1;
  let countDestinationIndex = true;
  days.forEach(day => {
    if (countDestinationIndex) {
      destinationDayIndex++;
    }
    if (day && day.id === destination.droppableId) {
      destinationSteps = day.steps!;
      countDestinationIndex = false;
    }
  });

  const newDestinationSteps = [...destinationSteps];

  const [removed] = newSourceSteps.splice(source.index, 1);
  newDestinationSteps.splice(destination.index, 0, removed);

  const newDays: Maybe<Day>[] = [];
  days.forEach(day => {
    if (day && day.id === source.droppableId) {
      day = { id: day.id, dayNumber: day.dayNumber };
      day.steps = newSourceSteps;
    }
    if (day && day.id === destination.droppableId) {
      day = { id: day.id, dayNumber: day.dayNumber };
      day.steps = newDestinationSteps;
    }

    newDays.push(day);
  });

  return { newDays, destinationDayIndex };
};

export const copyDay = (
  source: Maybe<Day>[],
  destination: Maybe<Day>[],
  droppableSource,
  droppableDestination,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const day = sourceClone[droppableSource.index];

  // Change all ID's so days can be draggable again
  if (day) {
    const id = day.id + '_temp';
    const dayClone = { ...day };
    //const id = day.id;
    dayClone.id = id;
    const steps: Step[] = [];
    let newStep: Step;
    if (dayClone && dayClone.steps) {
      dayClone.steps.forEach(step => {
        if (step) {
          newStep = step;
          // newStep.id = step.id + '_temp';
        }
        steps.push(newStep);
      });
    }
    dayClone.steps = steps;
    destClone.splice(droppableDestination.index, 0, {
      ...dayClone,
      id,
    });
  }
  return destClone;
};

export const copyStep = (
  existingDays: Day[],
  newTripDays: Maybe<Day>[],
  draggableId: string,
  source,
  destination,
) => {
  const originalDayId = source.droppableId.replace('existingStepDayId_', '');

  // Get step from source day
  let sourceSteps: Maybe<Step>[] = [];
  existingDays!.forEach(day => {
    if (day && day.id === originalDayId) {
      if (day && day.steps && day.steps.length > 0) {
        day.steps.forEach(step => {
          if (step && step.id === draggableId) {
            sourceSteps = [step];
          }
        });
      }
    }
  });

  let destinationSteps: Maybe<Step>[] = [];
  let destinationDayIndex = -1;
  let countDestinationIndex = true;
  newTripDays.forEach(day => {
    if (countDestinationIndex) {
      destinationDayIndex++;
    }
    if (day && day.id === destination.droppableId) {
      destinationSteps = day.steps!;
      countDestinationIndex = false;
    }
  });

  const sourceClone = Array.from(sourceSteps);
  const destClone = Array.from(destinationSteps);
  const item = sourceClone[0];

  if (item && item.id) {
    const id = item.id + '_temp';
    destClone.splice(destination.index, 0, {
      ...item,
      id,
    });
  }

  const newDays: Maybe<Day>[] = [];
  newTripDays.forEach(day => {
    if (day && day.id === destination.droppableId) {
      day = { id: day.id, dayNumber: day.dayNumber };
      day.steps = destClone;
    }

    newDays.push(day);
  });

  return { newDays, destinationDayIndex };
};

import React from 'react';
import Realistic from 'react-canvas-confetti/dist/presets/realistic';

export function RealisticConfetti() {
  return (
    <Realistic
      autorun={{ speed: 0.3, duration: 2, delay: 0.5 }}
      style={{
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)', // Centers it horizontally
        zIndex: 9999, // Ensures it's above all other content
        pointerEvents: 'none', // Makes sure it doesn't interfere with clicking other elements
        width: '100%', // Full width for the confetti effect
        height: '100vh', // Full viewport height for the confetti effect
      }}
    />
  );
}

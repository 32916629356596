import { gql } from 'graphql-tag';

export const MUTATION_SEND_OTP = gql`
  mutation sendOtp($email: String!) {
    sendOtp(email: $email) {
      success
    }
  }
`;

export const MUTATION_VERIFY_OTP = gql`
  mutation validateOtp($email: String!, $code: String!) {
    validateOtp(email: $email, code: $code) {
      success
      token
    }
  }
`;

import { useLazyQuery, useMutation } from '@apollo/client';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../store/ErrorSlice';
import {
  LinkButtonOld,
  PrimaryButton,
  SecondaryButton,
} from '../../theme-components/Buttons';
import { Loader, LoaderFullScreen } from '../../theme-components/Loader';
import { validateEmail } from '../../utils/helpers';
import { StyledFormControl } from '../create-trip/manage-trip-modals/Styles';
import { MUTATION_UPDATE_SHARED_TRIP_INFO } from '../gql-user/editSharedTripInfoMutation';
import { MUTATION_SHARE_TRIP } from '../gql-user/shareTrip';
import { QUERY_GET_TRIP_SHARED_INFO } from '../gql-user/tripSharedInfoQuery';
import { VerticalFlex } from '../helpers/flex';
import {
  CenteredModal,
  ModalPaper,
  ModalDescription,
  ModalTitle,
} from '../styling/modal';

import { TripButtons } from './UserTrips';
import ModalHeader from '../../theme-components/ModalComponents';
import Space from '../../theme-components/Spacing';
import TextDescription from '../../theme-components/Typography';
import { textAlign } from '@mui/system';

export default function ShareTripModal(props: { tripId: string }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [updateEmail, setUpdateEmail] = useState('');
  const [canView, setCanView] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [editId, setEditId] = useState('');

  const [shareTrip, { loading }] = useMutation(MUTATION_SHARE_TRIP, {
    variables: {
      trip_id: props.tripId,
      shareWithEmail: email,
      canEdit: canEdit,
      canView: canView,
    },
  });

  const [updateSharedTripInfo, { loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_SHARED_TRIP_INFO,
    {
      variables: {
        shared_id: editId,
        trip_id: props.tripId,
        shareWithEmail: updateEmail,
        canEdit: canEdit,
        canView: canView,
      },
    },
  );

  const [getSharedInfo, { data: sharedInfoData, loading: sharedDataLoading }] =
    useLazyQuery(QUERY_GET_TRIP_SHARED_INFO, {
      fetchPolicy: 'no-cache',
      variables: {
        trip_id: props.tripId,
      },
    });

  const handleOpen = async () => {
    setOpen(true);
    try {
      await getSharedInfo();
    } catch (error) {
      console.error('Error fetching shared info:', error);
      // Optionally, display an error message to the user here
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      setCanView(true);
      setCanEdit(false);
      setEmail('');
    } else {
      setEditId('');
    }
  };

  if (sharedDataLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <>
      <Button
        onClick={() => {
          handleOpen();
          gtag('event', 'user-trip-share-chip');
        }}
        sx={{
          padding: 0,
          backgroundColor: 'transparent',
          color: theme => theme.palette.icon.main,
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: theme => theme.palette.action.hover,
          },
        }}
      >
        <ShareTwoToneIcon
          sx={{
            width: 20,
            height: 20,
            marginRight: 0.5,
          }}
        />
        <TripButtons>Share</TripButtons>
      </Button>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader title="Share with your friends" />
          <Box width="100%">
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab
                sx={{ width: '50%', fontWeight: 600, fontSize: 18 }}
                label="Add friend"
              />
              <Tab
                sx={{ width: '50%', fontWeight: 600, fontSize: 18 }}
                label="Manage access"
              />
            </Tabs>
          </Box>
          <Space size="md" />
          {value === 0 && (
            <>
              <TextDescription size="md" sx={{ textAlign: 'left' }}>
                Share this trip with your fellow travelers. Just add their email
                and once they login they will be able to see this trip details.
              </TextDescription>
              <StyledFormControl>
                <Box mb={1}>
                  <TextField
                    label="Friend email"
                    name="title"
                    fullWidth={true}
                    value={email}
                    variant="outlined"
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    sx={{ backgroundColor: '#FAFAFA' }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={canView}
                      onChange={() => setCanView(!canView)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Allow to view"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={canEdit}
                      onChange={() => setCanEdit(!canEdit)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Allow to edit"
                />
              </StyledFormControl>
              <Space size="sm" />
              <PrimaryButton
                disabled={!validateEmail(email) || loading}
                onClick={async () => {
                  gtag('event', 'share-trip-share-with-friend');
                  try {
                    await shareTrip();
                    await getSharedInfo();
                    setValue(1);
                    setEditId('');
                  } catch (e) {
                    dispatch(setErrorTitle('Error sharing trip'));
                    dispatch(setErrorModalOpen(true));
                  }
                }}
              >
                {loading ? <Loader /> : 'Share trip'}
              </PrimaryButton>
            </>
          )}
          {value === 1 && (
            <>
              {sharedInfoData &&
              sharedInfoData.userTripSharedWithUsers &&
              sharedInfoData.userTripSharedWithUsers.length > 0 ? (
                <>
                  <TextDescription size="md" sx={{ textAlign: 'left' }}>
                    When people with view access login, they will be able to see
                    this trip details.
                  </TextDescription>
                  <Space size="md" />
                  <Divider />
                  {sharedInfoData.userTripSharedWithUsers.map((user, index) => (
                    <>
                      {user && !editId && (
                        <Fragment key={index}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            width="100%"
                            sx={{
                              backgroundColor: '#FAFAFA',
                            }}
                          >
                            <Box display="flex" flexDirection="column">
                              <Box
                                sx={theme => ({
                                  color: theme.palette.secondary.dark,
                                  fontSize: 18,
                                  fontWeight: 500,
                                  marginBottom: theme.spacing(0.5),
                                  marginTop: theme.spacing(0.5),
                                })}
                              >
                                {user.sharedWithEmail}
                              </Box>
                              {user.canEdit ? (
                                <Box
                                  sx={theme => ({
                                    color: theme.palette.secondary.light,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginBottom: theme.spacing(1),
                                  })}
                                >
                                  Access to view and edit
                                </Box>
                              ) : (
                                <Box>
                                  {user.canView ? (
                                    <Box
                                      sx={theme => ({
                                        color: theme.palette.secondary.light,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        marginBottom: theme.spacing(1),
                                      })}
                                    >
                                      Access to view
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={theme => ({
                                        color: theme.palette.secondary.light,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        marginBottom: theme.spacing(1),
                                      })}
                                    >
                                      No Access
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>

                            <LinkButtonOld
                              onClick={() => {
                                setEditId(user.id);
                                setUpdateEmail(user.sharedWithEmail);
                                setCanView(user.canView);
                                setCanEdit(user.canEdit);
                                gtag('event', 'share-trip-edit-permissions');
                              }}
                            >
                              Edit
                            </LinkButtonOld>
                          </Box>
                          <Divider />
                        </Fragment>
                      )}
                      {user.id === editId && (
                        <>
                          <StyledFormControl>
                            <Box mb={1}>
                              <TextField
                                label="Friend email"
                                name="title"
                                fullWidth={true}
                                value={updateEmail}
                                variant="outlined"
                                onChange={e => {
                                  setUpdateEmail(e.target.value);
                                }}
                                sx={{
                                  backgroundColor: '#FAFAFA',
                                }}
                              />
                            </Box>

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={canView}
                                  onChange={() => setCanView(!canView)}
                                  color="primary"
                                  name="checkedB"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              }
                              label="Allow to view"
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={canEdit}
                                  onChange={() => setCanEdit(!canEdit)}
                                  color="primary"
                                  name="checkedB"
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              }
                              label="Allow to edit"
                            />
                          </StyledFormControl>

                          <PrimaryButton
                            disabled={
                              !validateEmail(updateEmail) || updateLoading
                            }
                            onClick={async () => {
                              gtag('event', 'share-trip-edit-permissions-save');
                              try {
                                await updateSharedTripInfo();
                                setEditId('');
                              } catch (e) {
                                dispatch(setErrorTitle('Error saving changes'));
                                dispatch(setErrorModalOpen(true));
                              }

                              try {
                                await getSharedInfo();
                              } catch (e) {}
                            }}
                          >
                            {updateLoading ? <Loader /> : 'Save'}
                          </PrimaryButton>
                          <Space size="md" />
                          <SecondaryButton
                            onClick={async () => {
                              setEditId('');
                              gtag(
                                'event',
                                'share-trip-edit-permissions-go-back',
                              );
                            }}
                          >
                            Go back
                          </SecondaryButton>
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  <TextDescription size="md" sx={{ textAlign: 'left' }}>
                    This trip is not shared with anyone yet.
                  </TextDescription>
                  <Space size="xl" />
                </>
              )}
            </>
          )}
        </ModalPaper>
      </CenteredModal>
    </>
  );
}
